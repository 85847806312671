import { useCallback } from 'react';

import { mapEcommerceAddPromo } from '@entities/adds/utils/mappers';
import { AdvertisingBanner } from '@shared/api/axios-client';
import { useYandexEcommerce } from '@shared/hooks';

type TArgs = {
    targetAddId: string;
    adds: AdvertisingBanner[];
    userId: string;
    position: 'search' | 'fav' | 'results';
};

export const useAddClickEcommerce = () => {
    const { promoClickAction } = useYandexEcommerce();
    return useCallback(
        ({ targetAddId, adds, userId, position }: TArgs) => {
            if (!Boolean(adds.length)) {
                return;
            }
            const targetAdd = adds.find(item => item.id === targetAddId);
            if (!targetAdd) {
                return;
            }
            promoClickAction(
                mapEcommerceAddPromo([targetAdd], userId, position)[0],
            );
        },
        [promoClickAction],
    );
};
