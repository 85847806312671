import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';

export const EmptyDate = () => {
    return (
        <Tooltip arrow title="Сроки отгрузки и доставки уточняйте у поставщика">
            <Stack
                direction="row"
                gap="6px"
                alignItems="center"
                justifyContent="flex-end">
                <InfoIconOutlined sx={{ fontSize: '15px' }} />
                <Typography variant="body3">Нет данных</Typography>
            </Stack>
        </Tooltip>
    );
};
