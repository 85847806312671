import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggle } from '@features/feature-toggle';
import { UnknownBusinessError, providersApi } from '@shared/api';

import { providersKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetAvailableProviders = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
    isDisabled?: boolean;
};

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const useGetAvailableProviders = (props?: TUseGetAvailableProviders) => {
    return useQuery(
        providersKeys.available.queryKey,
        () => {
            return providersApi.getV1ProviderListAvailable({
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            enabled: !props?.isDisabled && IS_PROVIDERS_ENABLED,
            onSuccess: () => {
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
