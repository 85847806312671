import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    updateSession,
    updateUserInfo,
} from '@entities/auth/model/auth-domain';
import { useSetClid } from '@entities/user/model/hooks';
import { FeatureToggle } from '@features/feature-toggle';
import { TExternalFormError, TFormData } from '@features/form-client-id';
import { useYandexReachGoal, useYandexUserParams } from '@shared/hooks';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { ClientIdPage } from './client-id-page';
import { ClientIdHeader } from './ui/client-id-header';

type TProps = {
    externalRoutes: {
        providerHomePath: string;
    };
    headerPaths: THeaderPaths;
};

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const ClientIdPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const navigate = useNavigate();
    const [newClid, setNewClid] = useState<number>();
    const [externalFormError, setExternalFormError] = useState<
        TExternalFormError | undefined
    >();
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();
    const { setClid, isLoading } = useSetClid({
        onSuccess: data => {
            updateSession(data.data);
            fireProductEvent('registerClientID');
            changeUserParams({ userID: newClid, suppliers: 0 });
            updateUserInfo({ clientId: newClid });
            navigate(
                IS_PROVIDERS_ENABLED
                    ? externalRoutes.providerHomePath
                    : headerPaths.catalogHomePath,
                {
                    state: { fromAuth: true },
                },
            );
        },
        onError: e => {
            if (e.code === 'ClientIdInvalid') {
                fireProductEvent('errorClientID');
                setExternalFormError({
                    name: 'clientId',
                    message: e.message,
                    type: 'api_error',
                });
                return;
            }
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });
    const handleSubmit = useCallback(
        (data: TFormData) => {
            const clid = Number(data.clientId.replaceAll('-', ''));
            setNewClid(clid);
            setClid({ clientId: clid });
        },
        [setClid],
    );
    const handleTooltipMouseEnter = useCallback(
        () => fireProductEvent('checkClientIDInfo'),
        [fireProductEvent],
    );
    const handleLogout = useCallback(
        () => fireProductEvent('logout'),
        [fireProductEvent],
    );
    if (isLoading) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                        onLogout={handleLogout}
                    />
                }
                title="один момент..."
                CenterSlot={
                    <ClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={isLoading}
                    />
                }
            />
        );
    }
    return (
        <PageTemplate
            withHeadSpace
            variant="medium"
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    paths={headerPaths}>
                    <ClientIdHeader
                        onTooltipMouseEnter={handleTooltipMouseEnter}
                    />
                </HeaderWidgetConnector>
            }>
            <ClientIdPage
                onSubmit={handleSubmit}
                isSubmitting={false}
                externalFormError={externalFormError}
            />
        </PageTemplate>
    );
};
