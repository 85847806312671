import { Product } from '@shared/api/axios-client';

import { TFavoriteItem } from '../../catalog-page-connector';

export const mapFavorite = (products?: Product[]): TFavoriteItem[] => {
    return products
        ? products.map(product => ({
              productId: product.id,
              brand: product.brand,
              vendorCode: product.vendorCode,
          }))
        : [];
};
