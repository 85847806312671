/* eslint-disable boundaries/element-types */
//TODO (@OKA) refactor keys
import {
    Autocomplete,
    Box,
    Checkbox,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { TFormProvider } from '@entities/providers/types';
import { TFormData } from '@features/form-filters';
import { selectors } from '@shared/test/selectors';
import { CheckOffIcon, CheckerIcon } from '@shared/ui/icons';

type TProps = {
    fields: ControllerRenderProps<TFormData, 'provider'>;
    options: TFormProvider[];
};

export const ProductProviderFilter = ({ fields, options }: TProps) => {
    const theme = useTheme();
    const [isBlur, setIsBlur] = useState(false);
    return (
        <Box
            position="relative"
            borderRadius="24px"
            minWidth="280px"
            sx={theme => ({
                background: isBlur ? theme.palette.bg.primary : 'transparent',
            })}>
            <Autocomplete
                data-testid={
                    selectors.productsProductProviderFilterAutocomplete
                }
                disablePortal
                noOptionsText="данные не найдены"
                open={isBlur}
                multiple
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={fields.value}
                disableCloseOnSelect
                clearIcon={false}
                options={options}
                disableClearable
                onChange={(_, data) => {
                    fields.onChange(data);
                }}
                sx={theme => ({
                    '.MuiAutocomplete-inputRoot': {
                        padding: '0 !important',
                    },
                    position: 'static',
                    borderRadius: '24px',
                    border: isBlur
                        ? `1px solid ${theme.palette.border.border4}`
                        : '1px solid transparent',
                    padding: '20px',
                })}
                componentsProps={{
                    popper: {
                        sx: theme => ({
                            width: '100% !important',
                            border: `1px solid ${theme.palette.border.border4}`,
                            borderRadius: '0 0 24px 24px',
                            borderWidth: '0 1px 1px 1px',
                        }),
                    },
                    paper: {
                        sx: {
                            borderRadius: '0 0 24px 24px',
                            boxShadow: 'none',
                            '.MuiAutocomplete-listbox': {
                                margin: '0 20px',
                            },
                            '.MuiAutocomplete-option': {
                                padding: '0 !important',
                            },
                        },
                    },
                }}
                renderOption={(props, option, { selected, index }) => (
                    <li {...props} key={index}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            width="100%">
                            <Checkbox
                                sx={{ padding: 0, minWidth: 0 }}
                                checked={selected}
                                checkedIcon={CheckerIcon({ size: 16 })}
                                icon={CheckOffIcon({ size: 16 })}
                                data-testid={selectors.productsProductProviderFilterCheckbox(
                                    index,
                                )}
                            />
                            <Stack
                                height="46px"
                                width="100%"
                                justifyContent="center"
                                borderBottom={theme =>
                                    `0.5px solid ${theme.palette.border.border3}`
                                }>
                                <Typography variant="subtitle1">
                                    {option?.label}
                                </Typography>
                            </Stack>
                        </Stack>
                    </li>
                )}
                renderInput={params => (
                    <TextField
                        data-testid={
                            selectors.productsProductProviderFilterInput
                        }
                        variant="standard"
                        label="Поставщик"
                        placeholder={fields.value?.length ? '' : 'Все'}
                        onFocus={() => setIsBlur(true)}
                        onBlur={() => {
                            fields.onBlur();
                            setIsBlur(false);
                        }}
                        {...params}
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            sx: {
                                marginRight: '20px',
                                '&::placeholder': {
                                    color: theme.palette.typography.tertiary,
                                    opacity: '1 !important',
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            filled: true,
                        }}
                    />
                )}
                renderTags={(value, getTagProps) => (
                    <Typography
                        {...getTagProps}
                        variant="inputValue3"
                        maxWidth="70%"
                        sx={{ textWrap: 'nowrap' }}
                        textOverflow="ellipsis"
                        overflow="hidden">
                        {value.map(item => item?.label).join(', ')}
                    </Typography>
                )}
            />
        </Box>
    );
};
