export const validateDate = (
    dataToValidate: string | Date | number,
    fallbackDate?: Date,
): Date => {
    const date =
        dataToValidate instanceof Date
            ? dataToValidate
            : new Date(dataToValidate);
    if (isNaN(date.getDate())) {
        return fallbackDate || new Date();
    }
    return date;
};
