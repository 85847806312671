import { Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { $userInfo } from '@entities/auth/model/auth-domain';
import { mapUserInfo } from '@entities/auth/utils/mappers';
import { $cartAmount } from '@entities/cart/model/cart-domain';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { AccountPage } from './account-page';
import { accountPaths } from '../paths';

type TProps = {
    headerPaths: THeaderPaths;
};

const YA_CHANGE_INFO_LINK =
    'https://id.yandex.ru/personal?dialog=personal-data';

export const AccountPageConnector = ({ headerPaths }: TProps) => {
    const navigate = useNavigate();
    const userInfoStore = useUnit($userInfo);
    const cartAmount = useUnit($cartAmount);

    const handleChangeClientId = useCallback(() => {
        navigate(accountPaths.clientId.absolute);
    }, [navigate]);

    const userInfo = mapUserInfo(userInfoStore, cartAmount || 0);
    return (
        <PageTemplate
            withHeadSpace
            Header={
                <HeaderWidgetConnector
                    withProfile
                    withLogo
                    variant="primary"
                    paths={headerPaths}>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        личные данные
                    </Typography>
                </HeaderWidgetConnector>
            }
            variant="big">
            <AccountPage
                userInfo={userInfo}
                onChangeClientID={handleChangeClientId}
                changeInfoLink={YA_CHANGE_INFO_LINK}
            />
        </PageTemplate>
    );
};
