import { Stack, Typography, styled } from '@mui/material';
import { ReactNode } from 'react';

import { AuthTemplate } from '@shared/ui/templates/auth-template';

type TProps = {
    OAuthWidget: ReactNode;
};
const RightWrapper = styled(Stack)`
    flex: 1;
    ${props => props.theme.breakpoints.up('xs')} {
        padding: 16px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    ${props => props.theme.breakpoints.up('md')} {
        padding: 0;
        padding-left: 10%;
        margin-top: 48px;
        align-items: start;
        justify-content: start;
        text-align: start;
    }
`;

export const AuthPage = ({ OAuthWidget }: TProps) => {
    return (
        <AuthTemplate
            LeftSlot={
                <Stack
                    justifyContent="center"
                    alignItems={{ xs: 'center', md: 'start' }}
                    gap="80px"
                    marginLeft={{ md: '20%' }}
                    padding="16px">
                    <Stack
                        gap="24px"
                        maxWidth="820px"
                        alignItems={{ xs: 'center', md: 'start' }}
                        textAlign={{ xs: 'center', md: 'start' }}>
                        <Typography variant="h5">
                            Маркетплейс запчастей
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{ wordBreak: 'break-word' }}>
                            Поиск автозапчастей по всей России
                        </Typography>
                    </Stack>
                    {OAuthWidget}
                </Stack>
            }
            RightSlot={
                <RightWrapper>
                    <Typography
                        variant="body2"
                        color={theme => theme.palette.typography.primaryInvert}>
                        Маркетплейс запчастей — сервис
                        <br />
                        поиска запчастей от поставщиков
                        <br /> по всей России
                    </Typography>
                </RightWrapper>
            }
        />
    );
};
