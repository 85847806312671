import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    InfoComponent: ReactNode;
    onAddProvider?: () => void;
    onPropositionProvider: () => void;
    onHelpClick: () => void;
};

export const ProductsEmpty = ({
    InfoComponent,
    onAddProvider,
    onPropositionProvider,
    onHelpClick,
}: TProps) => {
    return (
        <Grid container>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={10}>
                <Stack gap="20px">
                    <Stack width="100%" alignItems="flex-end">
                        <Button
                            data-testid={
                                selectors.productsProductsEmptyHelpButton
                            }
                            variant="contained"
                            color="buttonPrimary"
                            onClick={onHelpClick}>
                            помощь в подборе деталей
                        </Button>
                    </Stack>
                    <Stack gap="80px">
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="column" gap="20px">
                                <Typography variant="h2">
                                    К сожалению, ничего не нашлось
                                </Typography>

                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    gap="8px">
                                    <InfoOutlinedIcon
                                        sx={theme => ({
                                            fontSize: '24px',
                                            color: theme.palette.icon.tertiary,
                                        })}
                                    />
                                    <Typography
                                        variant="body2"
                                        color={theme =>
                                            theme.palette.typography.secondary
                                        }>
                                        Убедитесь, что артикул введён правильно
                                        или предложите поставщика
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" gap="40px">
                            <Conditional condition={Boolean(onAddProvider)}>
                                <Button
                                    data-testid={
                                        selectors.productsProductsEmptyAddProviderButton
                                    }
                                    sx={{ width: '274px' }}
                                    variant="contained"
                                    color="buttonPrimary"
                                    onClick={onAddProvider}>
                                    добавить поставщика
                                </Button>
                            </Conditional>
                            <Button
                                data-testid={
                                    selectors.productsProductsEmptyPropositionProviderButton
                                }
                                sx={{ width: '274px' }}
                                variant="contained"
                                color="buttonSecondaryEnabled"
                                onClick={onPropositionProvider}>
                                предложить поставщика
                            </Button>
                        </Stack>
                    </Stack>
                    {InfoComponent}
                </Stack>
            </Grid>
        </Grid>
    );
};
