import { Box, Button, Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { $login } from '@entities/auth/model/auth-domain';
import { useLogout } from '@entities/user/model';
import { selectors } from '@shared/test/selectors';
//TODO (@OKA) придумать как сделать правильно
// eslint-disable-next-line boundaries/element-types
import { TopbarMenuWidgetConnector } from '@widgets/topbar-menu';

import { HeaderWidget, THeaderVariants } from './header-widget';
import { THeaderPaths } from './types';

type TProps = {
    paths: THeaderPaths;
    children?: ReactNode;
    withLogo: boolean;
    backgroundColor?: string;
    withProfile?: boolean;
    variant: THeaderVariants;
    onLogout?: () => void;
};

export const HeaderWidgetConnector = ({
    children,
    withLogo,
    withProfile,
    variant = 'primary',
    backgroundColor,
    paths,
    onLogout,
}: TProps) => {
    const navigate = useNavigate();
    const isLogin = useUnit($login);
    const onLogin = useCallback(
        () => navigate(paths.authHomePath),
        [navigate, paths.authHomePath],
    );
    const onLogoClick = useCallback(
        () => navigate(paths.catalogHomePath),
        [navigate, paths.catalogHomePath],
    );
    const logout = useLogout();
    const handleLogout = useCallback(() => {
        logout();
        onLogout?.();
    }, [logout, onLogout]);
    const profile = withProfile ? (
        <TopbarMenuWidgetConnector paths={paths} />
    ) : (
        <Box display="flex" justifyContent="flex-end">
            <Button
                data-testid={selectors.headerWidgetLogoutButton}
                color="buttonPrimary"
                variant="text"
                onClick={handleLogout}>
                <Typography variant="subtitle1">Выход</Typography>
            </Button>
        </Box>
    );
    return (
        <HeaderWidget
            withLogo={withLogo}
            onLogoClick={withLogo ? onLogoClick : undefined}
            backgroundColor={backgroundColor}
            RightSlot={
                isLogin ? (
                    profile
                ) : (
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            data-testid={selectors.headerWidgetLoginButton}
                            color="buttonPrimary"
                            variant="text"
                            onClick={onLogin}>
                            <Typography variant="subtitle1">Войти</Typography>
                        </Button>
                    </Box>
                )
            }
            children={children}
            variant={variant}
        />
    );
};
