import { AttributeSort, ProductInfo } from '@shared/api/axios-client';
import { arrayUtils, dateUtils } from '@shared/lib/utils';

export const productSort = (
    products: ProductInfo[],
    attribute: AttributeSort['sortBy'],
    order: AttributeSort['sortOperator'],
) => {
    return products.sort((a, b) => {
        switch (attribute) {
            case 'Price':
                return order === 'Desc'
                    ? a.warehouse.price - b.warehouse.price
                    : b.warehouse.price - a.warehouse.price;
            case 'DeliveryDate':
                const dataA = dateUtils.validateDate(
                    a.warehouse.shipmentDate || '',
                );
                const dataB = dateUtils.validateDate(
                    b.warehouse.shipmentDate || '',
                );
                return order === 'Desc'
                    ? dataA.getTime() - dataB.getTime()
                    : dataB.getTime() - dataA.getTime();
            case 'Alphabet':
                if (order === 'Desc') {
                    return arrayUtils.alphabeticalSort(
                        a.product.name,
                        b.product.name,
                    );
                }
                return arrayUtils.alphabeticalSort(
                    b.product.name,
                    a.product.name,
                );
            default:
                return 0;
        }
    });
};
