import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormSearchHeader, TFormData } from './form-search-header';

type TProps = {
    options: string[];
    initSearch?: string;
    onSubmit: (data: TFormData) => void;
};

const schema = z.object({
    search: z
        .string()
        .transform(string => string.trim())
        .pipe(z.string().nonempty('Обязательное поле')),
});

export const FormSearchHeaderProvider = ({
    options,
    initSearch = '',
    onSubmit,
}: TProps) => {
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            search: initSearch,
        },
    });

    const handleSubmit = useCallback(
        (data: TFormData) => {
            if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
            }
            onSubmit(data);
        },
        [onSubmit],
    );

    const handleAutocomplete = useCallback(() => {
        methods.handleSubmit(handleSubmit)();
    }, [handleSubmit, methods]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <FormSearchHeader
                    options={options}
                    placeholder="Поиск"
                    onAutocomplete={handleAutocomplete}
                />
            </form>
        </FormProvider>
    );
};
