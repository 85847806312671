export const getInfoData = () => {
    return {
        ok: {
            title: 'Готово! Все прошло успешно',
            helpedText: '',
        },
        loading: {
            title: 'один момент, все проверяем...',
            helpedText: '',
        },
    };
};
