import { Box, Button } from '@mui/material';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import {
    $deviceInfo,
    updateDeviceInfo,
} from '@entities/auth/model/auth-domain';
import { FeatureToggle } from '@features/feature-toggle';
import { selectors } from '@shared/test/selectors';

import { YandexIcon } from './ui/icon';

export type TYandexAuthResponse = Awaited<
    ReturnType<Awaited<ReturnType<typeof window.YaAuthSuggest.init>>['handler']>
>;

type TProps = {
    redirectUrl: string;
    clientId: string;
    origin: string;
    onSuccess: (data: TYandexAuthResponse) => void;
    onError: () => void;
};

const isLoginWithNews = FeatureToggle.hasFeatureFlag('newses');

export const OAuthYandex = ({
    redirectUrl,
    clientId,
    origin,
    onSuccess,
    onError,
}: TProps) => {
    const [isYaLoad, setIsYaLoad] = useState(false);
    const [isYaInit, setIsYaInit] = useState(false);
    const [isYaVisible, setIsYaVisible] = useState(false);
    const { hash } = useLocation();
    const deviceInfo = useUnit($deviceInfo);
    useEffect(() => {
        if (!deviceInfo) {
            const uuid4 = v4();
            updateDeviceInfo({
                deviceId: uuid4,
                deviceName: uuid4,
            });
        }
    }, [deviceInfo]);
    useEffect(() => {
        const intervalId = window.setInterval(() => {
            if (window.YaAuthSuggest) {
                setIsYaLoad(true);
                clearInterval(intervalId);
            }
        }, 20);
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        if (isYaLoad && deviceInfo && !isYaInit) {
            setIsYaInit(true);
            window.YaAuthSuggest.init(
                {
                    client_id: clientId,
                    response_type: 'token',
                    redirect_uri: redirectUrl,
                    device_id: deviceInfo?.deviceId || '',
                    device_name: deviceInfo?.deviceName || '',
                },
                origin,
                {
                    view: 'button',
                    parentId: 'yandex-oauth-container',
                    buttonView: 'main',
                    buttonTheme: 'light',
                    buttonSize: 'm',
                    buttonBorderRadius: 22,
                    buttonIcon: 'ya',
                },
            )
                .then(({ handler }) => handler())
                .then(onSuccess)
                .catch((error: any) => {
                    onError();
                    console.log('Ya error:', error);
                });
        }
    }, [
        clientId,
        deviceInfo,
        isYaInit,
        isYaLoad,
        onError,
        onSuccess,
        origin,
        redirectUrl,
    ]);
    useEffect(() => {
        if (isYaLoad && hash.includes('access_token')) {
            window.YaSendSuggestToken(origin);
        }
    }, [hash, isYaLoad, origin]);
    return (
        <Box width="320px" zIndex={0}>
            {isLoginWithNews ? (
                <>
                    <Button
                        data-testid={selectors.oauthYandexWidgetPrimaryButton}
                        variant="contained"
                        color="buttonPrimary"
                        sx={{
                            opacity: isYaVisible ? 0 : 1,
                            position: 'absolute',
                            width: '320px',
                            height: '44px',
                            transition: 'opacity .5s',
                            paddingTop: '8px',
                            justifyContent: 'space-between',
                        }}
                        onMouseEnter={() => setIsYaVisible(true)}
                        startIcon={
                            <Box marginTop="1px" height="24px" width="24x">
                                <YandexIcon />
                            </Box>
                        }
                        endIcon={
                            <Box
                                height="24px"
                                width="24x"
                                marginTop="1px"
                                borderRadius="12px"
                                sx={theme => ({
                                    background: theme.palette.grey[600],
                                })}>
                                <Box
                                    sx={{
                                        height: '24px',
                                        width: '24px',
                                        background:
                                            'url("https://avatars.mds.yandex.net/get-yapic/0/0-0/islands-50")',
                                        backgroundSize: 'contain',
                                    }}
                                />
                            </Box>
                        }>
                        Маркетплейс запчастей
                    </Button>
                    <Box
                        data-testid={selectors.oauthYandexWidgetButtonContainer}
                        onMouseLeave={() => setIsYaVisible(false)}
                        id="yandex-oauth-container"
                        sx={{
                            visibility: isYaVisible ? 'visible' : 'hidden',
                            opacity: isYaVisible ? 1 : 0,
                            transition: 'opacity .5s, visibility .5s',
                        }}
                        maxWidth="320px"
                        width="100%"
                    />
                </>
            ) : (
                <Box
                    data-testid={selectors.oauthYandexWidgetButtonContainer}
                    id="yandex-oauth-container"
                    maxWidth="320px"
                    width="100%"
                />
            )}
        </Box>
    );
};
