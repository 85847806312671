export const catalogPaths = {
    home: {
        absolute: '/',
        relative: '',
    },
    brand: {
        absolute: '/brand',
        relative: 'brand',
    },
    catalog: {
        absolute: '/catalog',
        relative: 'catalog',
    },
} as const;
