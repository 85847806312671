import { Outlet, RouteObject } from 'react-router-dom';

import { accountPaths, accountRoutes } from '@pages/account';
import { authPaths, authRoutes } from '@pages/auth';
import { catalogPaths, catalogRoutes } from '@pages/catalog';
import { clientIdPaths, clientIdRoutes } from '@pages/client-id';
import { errorPaths, errorRoutes } from '@pages/error';
import { favoritePaths, favoriteRoutes } from '@pages/favorite';
import {
    ClientIdGuard,
    Protected,
    UserInfoGuard,
    UserSearchHistoryGuard,
} from '@pages/guards';
import { logoutPaths, logoutRoutes } from '@pages/logout';
import { providerPaths, providerRoutes } from '@pages/provider';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header';

const headerPaths: THeaderPaths = {
    favoriteHomePath: favoritePaths.home.absolute,
    accountHomePath: accountPaths.home.absolute,
    accountProviderPath: accountPaths.providers.absolute,
    accountHelpPath: accountPaths.help.absolute,
    authHomePath: authPaths.home.absolute,
    catalogHomePath: catalogPaths.home.absolute,
};

const protectedRoutes = [
    ...accountRoutes({
        catalogHomePath: catalogPaths.home.absolute,
        providerHomePath: providerPaths.home.absolute,
        headerPaths,
    }),
    ...catalogRoutes({
        providerHomePath: providerPaths.home.absolute,
        accountProviderPath: accountPaths.providers.absolute,
        headerPaths,
    }),
    ...providerRoutes({
        catalogHomePath: catalogPaths.home.absolute,
        accountProviderPath: accountPaths.providers.absolute,
        headerPaths,
    }),
    ...favoriteRoutes({
        errorHomePath: errorPaths.home.absolute,
        catalogPaths: (vendorCode, brand) =>
            `${catalogPaths.catalog.absolute}?search=${vendorCode}&brand=${brand}`,
        headerPaths,
    }),
];

const generalRoutes = [
    ...authRoutes({
        clientIdHomePath: clientIdPaths.home.absolute,
        providerHomePath: providerPaths.home.absolute,
        errorHomePath: errorPaths.home.absolute,
    }),
    ...logoutRoutes({ authHomePath: authPaths.home.absolute, headerPaths }),
    ...errorRoutes({
        catalogHomePath: catalogPaths.home.absolute,
        headerPaths,
    }),
];

export const routes: RouteObject[] = [
    {
        children: [
            {
                element: (
                    <Protected fallbackRoute={authPaths.home.absolute}>
                        <UserInfoGuard
                            fallbackRoute={logoutPaths.home.absolute}>
                            <ClientIdGuard
                                fallbackRoute={clientIdPaths.home.absolute}>
                                <UserSearchHistoryGuard>
                                    <>
                                        <MetaTitle>
                                            Маркетплейс запчастей
                                        </MetaTitle>
                                        <Outlet />
                                    </>
                                </UserSearchHistoryGuard>
                            </ClientIdGuard>
                        </UserInfoGuard>
                    </Protected>
                ),
                children: protectedRoutes,
            },
            {
                element: (
                    <Protected fallbackRoute={authPaths.home.absolute}>
                        <>
                            <MetaTitle>Маркетплейс запчастей</MetaTitle>
                            <Outlet />
                        </>
                    </Protected>
                ),
                children: clientIdRoutes({
                    providerHomePath: providerPaths.home.absolute,
                    headerPaths,
                }),
            },
            ...generalRoutes,
        ],
    },
];
