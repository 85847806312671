import { useUnit } from 'effector-react';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { $userInfo, updateUserInfo } from '@entities/auth/model/auth-domain';
import { updateCartAmount } from '@entities/cart/model/cart-domain';
import { useGetCartCount } from '@entities/cart/model/hooks';
import { updateFavoriteAmount } from '@entities/favorite/favorite-domain';
import { useGetFavoriteCount } from '@entities/favorite/model';
import { useGetProfile } from '@entities/user/model';

type TProps = {
    children: ReactElement | null;
    fallbackRoute: string;
};

export const UserInfoGuard = ({ children, fallbackRoute }: TProps) => {
    const userInfo = useUnit($userInfo);
    useGetFavoriteCount({
        onSuccess: data => updateFavoriteAmount(data.data.totalQuantity),
    });
    useGetCartCount({
        onSuccess: data => updateCartAmount(data.data.cartAmountResponse),
    });
    useGetProfile({
        body: { xAuthToken: userInfo?.yaToken || '' },
        onSuccess: data => updateUserInfo(data.data),
    });

    if (!userInfo) {
        return <Navigate to={fallbackRoute} />;
    }

    return children;
};
