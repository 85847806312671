import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { $userInfo, updateUserInfo } from '@entities/auth/model/auth-domain';
import { useChangeClid } from '@entities/user/model/hooks';
import { TExternalFormError, TFormData } from '@features/form-client-id';
import { useYandexReachGoal, useYandexUserParams } from '@shared/hooks';
import { Dialog } from '@shared/ui/organisms/dialog';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';
import { THeaderPaths } from '@widgets/header/types';

import { ChangeClientIdPage } from './change-client-id-page';
import { ChangeClientIdHeader } from './ui/change-client-id-header';
import { accountPaths } from '../paths';

type TProps = {
    headerPaths: THeaderPaths;
};

export const ChangeClientIdPageConnector = ({ headerPaths }: TProps) => {
    const navigate = useNavigate();
    const [externalFormError, setExternalFormError] = useState<
        TExternalFormError | undefined
    >();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();
    const userInfo = useUnit($userInfo);
    const { enqueueSnackbar } = useSnackbar();
    const [newClid, setNewClid] = useState<number>();
    const { setClid, isLoading, error, isSuccess, isError, reset } =
        useChangeClid({
            onSuccess: () => {
                fireProductEvent('changeClientID', {
                    previousClientID: userInfo?.clientId,
                });
                changeUserParams({ userID: newClid });
                updateUserInfo({ clientId: newClid });
                setTimeout(() => navigate(accountPaths.home.absolute), 1500);
            },
            onError: e => {
                if (e.code === 'ClientIdInvalid') {
                    setExternalFormError({
                        name: 'clientId',
                        message: e.message,
                        type: 'api_error',
                    });
                    return e;
                }
                enqueueSnackbar('что-то пошло не так', {
                    variant: 'error',
                    description: 'попробуйте позже',
                });
            },
        });
    const handleSubmit = useCallback(
        (data: TFormData) => {
            const clid = Number(data.clientId.replaceAll('-', ''));
            setNewClid(clid);
            setClid({ clientId: clid });
        },
        [setClid],
    );
    const handleSkipError = useCallback(() => {
        navigate(accountPaths.home.absolute);
    }, [navigate]);
    const handleRetryError = useCallback(async () => {
        if (newClid) {
            setClid({ clientId: newClid });
            return;
        }
        reset();
    }, [newClid, reset, setClid]);

    if (isLoading) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="один момент..."
                CenterSlot={
                    <ChangeClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={true}
                        isSuccess={false}
                    />
                }
            />
        );
    }
    if (isSuccess) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="client ID успешно изменен"
                CenterSlot={
                    <ChangeClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={false}
                        isSuccess={true}
                    />
                }
            />
        );
    }
    if (isError && error.request.status !== 404) {
        return (
            <Dialog
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="не удалось изменить client ID"
                helpedText="попробуйте позже"
                variant="error"
                primaryButtonText="еще раз"
                secondaryButtonText="пропустить"
                onPrimaryButtonClick={handleRetryError}
                onSecondaryButtonClick={handleSkipError}
            />
        );
    }
    return (
        <PageTemplate
            variant="small"
            withHeadSpace
            Header={
                <HeaderWidgetConnector
                    withLogo
                    withProfile
                    variant="primary"
                    paths={headerPaths}>
                    <ChangeClientIdHeader />
                </HeaderWidgetConnector>
            }>
            <ChangeClientIdPage
                onSubmit={handleSubmit}
                isSubmitting={false}
                isSuccess={false}
                defaultValue={userInfo?.clientId}
                externalFormError={externalFormError}
            />
        </PageTemplate>
    );
};
