import { InfoModal } from '@shared/ui/organisms/info-modal';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    onRemove: () => void;
};

export const RemoveModal = ({ isOpen, onClose, onRemove }: TProps) => {
    return (
        <InfoModal
            open={isOpen}
            onClose={onClose}
            variant="question"
            withHeader
            title="Вы хотите очистить всю корзину?"
            primaryButtonText="да, очистить"
            secondaryButtonText="нет, отменить"
            onPrimaryButtonClick={onRemove}
            onSecondaryButtonClick={onClose}
        />
    );
};
