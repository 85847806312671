import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { objectUtils } from '@shared/lib/utils';

type TSringObject = { [key: string]: string | undefined };

export const useQueryParams = () => {
    const [params, setParams] = useSearchParams();

    const setQueryParam = useCallback(
        (value: TSringObject | TSringObject[]) => {
            const currentParams = Object.fromEntries(params.entries());
            if (Array.isArray(value)) {
                const newParams = value.reduce(
                    (acc, item) => ({ ...acc, ...item }),
                    {},
                );
                const definedEntries =
                    objectUtils.removeUndefinedValue(newParams);
                setParams({
                    ...currentParams,
                    ...definedEntries,
                });
                return;
            }
            const definedEntries = objectUtils.removeUndefinedValue(value);
            setParams({
                ...currentParams,
                ...definedEntries,
            });
        },
        [params, setParams],
    );
    const removeParams = useCallback(
        (paramsToRemove: string[]) => {
            const currentParams = Object.fromEntries(params.entries());
            paramsToRemove.forEach(key => {
                delete currentParams[key];
            });
            setParams({
                ...currentParams,
            });
        },
        [params, setParams],
    );
    return {
        params,
        setParams,
        setParam: setQueryParam,
        removeParams,
    };
};
