import { Stack, Typography } from '@mui/material';

export const ProviderHeader = () => {
    return (
        <Stack
            direction="column"
            gap="15px"
            alignItems={{ xs: 'center', md: 'start' }}
            textAlign={{ xs: 'center', md: 'start' }}>
            <Typography variant="h2">добавить поставщиков</Typography>
            <Typography
                variant="body2"
                color={theme => theme.palette.typography.secondary}>
                Чтобы добавлять поставщиков в профиль, зарегистрируйтесь в
                системе у каждого из них и получите персональные данные для
                доступа. Эти данные по api вы можете найти в вашем личном
                кабинете у поставщика
            </Typography>
        </Stack>
    );
};
