import { useUnit } from 'effector-react';
import { Navigate } from 'react-router-dom';

import { $login, $userInfo } from '@entities/auth/model/auth-domain';

type TProps = {
    children: JSX.Element;
    fallbackRoutes: {
        clientIdHomePath: string;
        providerHomePath: string;
    };
};

export const Public = ({ children, fallbackRoutes }: TProps) => {
    const isLogin = useUnit($login);
    const userInfo = useUnit($userInfo);
    if (!isLogin) {
        return children;
    }
    if (!userInfo?.clientId) {
        return <Navigate to={fallbackRoutes.clientIdHomePath} />;
    }
    return (
        <Navigate
            to={fallbackRoutes.providerHomePath}
            state={{ fromAuth: true }}
        />
    );
};
