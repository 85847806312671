import EastIcon from '@mui/icons-material/East';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { TAdd } from '@entities/adds/types';
import { AddBlock } from '@entities/adds/ui/add-block';
import { TProductTable } from '@entities/products/types';
import { ProductsEmpty } from '@entities/products/ui/products-empty';
import { ProductTable } from '@entities/products/ui/products-table';
import { ProviderErrorAlert } from '@entities/products/ui/provider-error-alert';
import { FeatureToggle } from '@features/feature-toggle';
import { useStateModal } from '@shared/hooks';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

import { tableHeader } from './utils/data';
type TProps = {
    adds: TAdd[];
    products: TProductTable[];
    providersError: string[];
    onHelpClick: () => void;
    onSelect: (brand: string) => void;
    onAddClick: (id: string) => void;
    onChangeProvider?: () => void;
    onAddProvider?: () => void;
    onPropositionProvider: () => void;
};

const isAddEnabled = FeatureToggle.hasFeatureFlag('adds');

export const BrandPage = ({
    adds,
    products,
    providersError,
    onHelpClick,
    onAddClick,
    onSelect,
    onChangeProvider,
    onAddProvider,
    onPropositionProvider,
}: TProps) => {
    const { isOpen, close } = useStateModal(Boolean(providersError.length));
    const Adds = useMemo(
        () =>
            isAddEnabled
                ? adds.map(item => (
                      <Box paddingY={{ xs: '2px', md: '40px' }}>
                          <AddBlock
                              border="square"
                              {...item}
                              onClick={() => onAddClick(item.id)}
                          />
                      </Box>
                  ))
                : [],
        [adds, onAddClick],
    );
    if (products.length === 0) {
        return (
            <Stack paddingX="40px" gap="40px">
                <ProductsEmpty
                    onAddProvider={onAddProvider}
                    onPropositionProvider={onPropositionProvider}
                    onHelpClick={onHelpClick}
                    InfoComponent={
                        isOpen && onChangeProvider ? (
                            <ProviderErrorAlert
                                onClose={close}
                                onChangeProvider={onChangeProvider}
                                providersError={providersError}
                            />
                        ) : null
                    }
                />
                <AddBlock
                    {...adds[0]}
                    onClick={() => onAddClick(adds[0]?.id)}
                />
            </Stack>
        );
    }
    return (
        <Stack direction="column" gap="40px" padding="40px">
            <Stack paddingBottom="40px" gap="30px">
                <Stack
                    direction="row"
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    flexWrap="wrap"
                    gap="8px">
                    <Stack direction="column" gap="20px">
                        <Stack direction="row" gap="8px" alignItems="center">
                            <Typography variant="h2">
                                Найденые товары
                            </Typography>
                            <Typography
                                variant="h4"
                                marginBottom="20px"
                                color={theme =>
                                    theme.palette.typography.tertiary
                                }>
                                {products.length}
                            </Typography>
                        </Stack>
                        <Typography
                            textAlign={{ xs: 'center', md: 'start' }}
                            variant="body2"
                            color={theme => theme.palette.typography.secondary}>
                            Важно: у нескольких производителей есть разные
                            товары с этим артикулом.
                            <br /> Чтобы не ошибиться, лучше уточнить запрос и
                            выбрать тип детали, который вам нужен
                        </Typography>
                    </Stack>
                    <Box>
                        <Button
                            data-testid={selectors.catalogBrandHelpButton}
                            variant="contained"
                            color="buttonPrimary"
                            onClick={onHelpClick}>
                            помощь в подборе деталей
                        </Button>
                    </Box>
                </Stack>
                <Conditional condition={isOpen && Boolean(onChangeProvider)}>
                    {onChangeProvider && (
                        <ProviderErrorAlert
                            onClose={close}
                            onChangeProvider={onChangeProvider}
                            providersError={providersError}
                        />
                    )}
                </Conditional>
            </Stack>
            <ProductTable
                adds={Adds}
                tableHeader={tableHeader}
                products={products}
                priorProducts={[]}
                onItemClick={brand => onSelect(brand)}
                renderControl={({ brand }) => (
                    <Box maxWidth="117px" height="42px" width="100%">
                        <Button
                            data-testid={selectors.catalogBrandSelectBrandButton(
                                brand || '',
                            )}
                            fullWidth
                            variant="contained"
                            color="buttonSecondaryEnabled"
                            onClick={() => brand && onSelect(brand)}>
                            <EastIcon />
                        </Button>
                    </Box>
                )}
            />
        </Stack>
    );
};
