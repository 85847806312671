import { createDomain, restore } from 'effector';

export const featureToggleDomain = createDomain('featureToggleDomain');
export const resetFeatureToggleDomainStores = featureToggleDomain.createEvent();

featureToggleDomain.onCreateStore(store => {
    store.reset(resetFeatureToggleDomainStores);
});

export const updateModalState = featureToggleDomain.createEvent<boolean>();
export const $modalState = restore(updateModalState, false);
