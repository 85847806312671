import { createDomain, restore } from 'effector';
import { persist as persistSession } from 'effector-storage/session';

export const cartDomain = createDomain('cart');
export const resetCartDomainStores = cartDomain.createEvent();

cartDomain.onCreateStore(store => {
    store.reset(resetCartDomainStores);
});

export const updateCartAmount = cartDomain.createEvent<number | null>();
export const $cartAmount = restore(updateCartAmount, null);

persistSession({
    store: $cartAmount,
    key: 'cart-amount',
});
