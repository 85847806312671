import { Components, Theme } from '@mui/material';

export const MuiDialogContent: Components<
    Omit<Theme, 'components'>
>['MuiDialogContent'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
            letterSpacing: '-0.18px',
            color: theme.palette.text.secondary,
            textAlign: 'center',
        }),
    },
};
