import { Stack, Typography } from '@mui/material';

import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    count: number;
};

export const Header = ({ count }: TProps) => {
    return (
        <Stack direction="row">
            <Typography variant="h2">Избранные товары</Typography>
            <Conditional condition={Boolean(count)}>
                <Typography
                    variant="h4"
                    color={theme => theme.palette.typography.tertiary}>
                    {count}
                </Typography>
            </Conditional>
        </Stack>
    );
};
