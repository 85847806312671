import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAddClickEcommerce, useGetAdvert } from '@entities/adds/model';
import { mapAdds, mapEcommerceAddPromo } from '@entities/adds/utils/mappers';
import { $userInfo } from '@entities/auth/model/auth-domain';
import {
    $favoriteAmount,
    updateFavoriteAmount,
} from '@entities/favorite/favorite-domain';
import { useDeleteFavorite, useGetFavorite } from '@entities/favorite/model';
import { useYandexEcommerce } from '@shared/hooks';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { FavoritePage } from './favorite-page';
import { mapFavorite } from './mappers';
import { Header } from './ui/header';

type TProps = {
    externalRoutes: {
        catalogPaths: (vendorCode: string, brand: string) => string;
    };
    headerPaths: THeaderPaths;
};

const TIME_TO_CANCEL = 5000;

const snackOptions = {
    preventDuplicate: false,
    autoHideDuration: TIME_TO_CANCEL,
    variant: 'info' as const,
    withIcon: false,
    closable: false,
    actionContent: 'ОТМЕНИТЬ',
};

export const FavoritePageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const [favoriteToDelete, setFavoriteToDelete] = useState<string[]>([]);
    const favoriteCount = useUnit($favoriteAmount);
    const userInfo = useUnit($userInfo);
    const { enqueueSnackbar } = useSnackbar();
    const { promoViewAction } = useYandexEcommerce();
    const onAddClick = useAddClickEcommerce();
    const navigate = useNavigate();

    const { data, isLoading } = useGetFavorite();
    const { deleteFavorite } = useDeleteFavorite();
    const { data: addsData } = useGetAdvert({
        body: { pageType: 'favorite' },
    });
    const adds = useMemo(
        () => mapAdds(addsData?.data.advertising_banners),
        [addsData],
    );
    useEffect(() => {
        if (addsData) {
            promoViewAction(
                mapEcommerceAddPromo(
                    addsData.data.advertising_banners,
                    String(userInfo?.clientId) || '',
                    'fav',
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addsData, userInfo?.clientId]);
    const handleNavigate = useCallback(
        (vendorCode: string, brand: string) =>
            navigate(externalRoutes.catalogPaths(vendorCode, brand)),
        [externalRoutes, navigate],
    );

    const handleRemove = useCallback(
        (id: string) => {
            setFavoriteToDelete(prev => [...prev, id]);
            updateFavoriteAmount((favoriteCount || 1) - 1);
            const timer = setTimeout(() => {
                deleteFavorite(id);
            }, TIME_TO_CANCEL);
            enqueueSnackbar('Удален из избранного', {
                ...snackOptions,
                onAction: () => {
                    clearTimeout(timer);
                    setFavoriteToDelete(prev =>
                        prev.filter(item => item !== id),
                    );
                    updateFavoriteAmount(($favoriteAmount.getState() || 0) + 1);
                },
            });
        },
        [deleteFavorite, enqueueSnackbar, favoriteCount],
    );

    const handleAddClick = useCallback(
        (id: string) => {
            if (!addsData) {
                return;
            }
            onAddClick({
                targetAddId: id,
                adds: addsData.data.advertising_banners,
                userId: String(userInfo?.clientId) || '',
                position: 'fav',
            });
        },
        [addsData, onAddClick, userInfo?.clientId],
    );

    const favorites = useMemo(
        () =>
            mapFavorite(data?.data.productsFavorites).filter(
                favorite => !favoriteToDelete.includes(favorite.id),
            ),
        [data?.data.productsFavorites, favoriteToDelete],
    );

    return (
        <PageTemplate
            withHeadSpace
            variant="medium"
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    withProfile
                    paths={headerPaths}>
                    <Header count={favorites.length} />
                </HeaderWidgetConnector>
            }>
            <FavoritePage
                adds={adds}
                count={favorites.length || 0}
                items={favorites}
                isLoading={isLoading}
                onNavigate={handleNavigate}
                onRemove={handleRemove}
                onAddClick={handleAddClick}
            />
        </PageTemplate>
    );
};
