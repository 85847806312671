import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';

type TProps = {
    onTooltipMouseEnter: () => void;
};

export const ClientIdHeader = ({ onTooltipMouseEnter }: TProps) => {
    return (
        <Stack direction="row" gap="24px" alignItems="center">
            <Typography variant="h2">добавить clientID</Typography>
            <Tooltip
                onMouseEnter={onTooltipMouseEnter}
                arrow
                title="Его можно найти в Диспетчерской → Статистика → Сводка → верхний левый угол чуть ниже названия вашего таксопарка">
                <InfoOutlinedIcon
                    sx={theme => ({
                        color: theme.palette.buttonPrimary.main,
                    })}
                />
            </Tooltip>
        </Stack>
    );
};
