import { TProductTableField } from '../types';

export const makeField = (
    label?: string,
    alight?: 'left' | 'right',
    size?: number,
    isLabelBold?: boolean,
    helpedText?: string,
    helpedTextBg?: string,
): TProductTableField => ({
    label: label || '',
    alight,
    isLabelBold,
    helpedText,
    helpedTextBg,
    size,
});
