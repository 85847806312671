import { createDomain, restore } from 'effector';
import { persist as persistSession } from 'effector-storage/session';

export const favoriteDomain = createDomain('favorite');
export const resetFavoriteDomainStores = favoriteDomain.createEvent();

favoriteDomain.onCreateStore(store => {
    store.reset(resetFavoriteDomainStores);
});

export const updateFavoriteAmount = favoriteDomain.createEvent<number | null>();
export const $favoriteAmount = restore(updateFavoriteAmount, null);

persistSession({
    store: $favoriteAmount,
    key: 'favorite-amount',
});
