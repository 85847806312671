import { Modal, Slide, Stack } from '@mui/material';
import { useUnit } from 'effector-react';
import { useCallback, useState } from 'react';

import { FeatureToggle } from '@features/feature-toggle';
import {
    $modalState,
    updateModalState,
} from '@features/feature-toggle/feature-toggle-domain';

import { mapFeatureToggleItems, mapTogglesToFlags } from './mappers';
import { TActiveItem } from './types';
import { SettingsModal } from './ui/organisms';

export const FeatureToggleSettings = () => {
    const { getFeatureFlags, setFeatureFlags } = FeatureToggle;
    const isOpen = useUnit($modalState);
    const close = useCallback(() => updateModalState(false), []);
    const flags = getFeatureFlags();

    const mappedFlags = mapFeatureToggleItems(flags);

    const [featureTogglesItems, setFeatureTogglesItems] = useState(mappedFlags);

    const handleCloseModal = () => {
        setFeatureTogglesItems(mappedFlags);
    };

    const onItemClick = ({ name, value }: TActiveItem) =>
        setFeatureTogglesItems(prev =>
            prev.map(item => ({
                ...item,
                isActive: item.value === name ? value : item.isActive,
            })),
        );

    const handleApplyFlags = () => {
        const mappedFlags = mapTogglesToFlags(featureTogglesItems);

        setFeatureFlags(mappedFlags);
        window.location.reload();
    };

    return (
        <Modal open={isOpen} onClose={close}>
            <Slide
                direction="down"
                in={isOpen}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                <Stack
                    minHeight="50dvh"
                    height={{ xs: '100dvh', md: 'auto' }}
                    maxHeight={{ xs: '100dvh', md: '80dvh' }}
                    overflow="auto"
                    gap="80px"
                    width="100vw"
                    direction="column"
                    position="relative"
                    padding="30px 40px"
                    alignItems="center"
                    sx={theme => ({
                        background: theme.palette.bg.primary,
                        outlineColor: 'transparent',
                    })}>
                    <SettingsModal
                        items={featureTogglesItems}
                        onItemClick={onItemClick}
                        onClose={handleCloseModal}
                        onApplyFlags={handleApplyFlags}
                    />
                </Stack>
            </Slide>
        </Modal>
    );
};
