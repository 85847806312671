import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header/types';

import { ClientIdPageConnector } from './client-id-page-connector';
import { clientIdPaths } from './paths';

type TExternalRoutes = {
    providerHomePath: string;
    headerPaths: THeaderPaths;
};

export const clientIdRoutes = ({
    providerHomePath,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: clientIdPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Изменение client id</MetaTitle>
                <ClientIdPageConnector
                    externalRoutes={{ providerHomePath }}
                    headerPaths={headerPaths}
                />
            </>
        ),
    },
];
