import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, authApi } from '@shared/api';
import {
    ErrorModel,
    PostV1ClIdRequest,
    PostV1ClIdResponse,
} from '@shared/api/axios-client';

import { userKeys } from '../query-keys';

type TBusinessError =
    | UnknownBusinessError
    | ErrorModel
    | {
          code: 'ClientIdInvalid';
          message: string;
      };

type TUseSetClid = {
    onSuccess?: (data: AxiosResponse<PostV1ClIdResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useSetClid = (props?: TUseSetClid) => {
    const { mutateAsync, ...rest } = useMutation(
        userKeys.clid.queryKey,
        ({ clientId }: PostV1ClIdRequest) => {
            return authApi.postV1Clid(
                {
                    postV1ClIdRequest: { clientId },
                },
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: data => {
                props?.onSuccess && props.onSuccess(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.status === 404) {
                    props.onError({
                        code: 'ClientIdInvalid',
                        message: 'проверьте правильность введенного clientID',
                    });
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                    return;
                }
                props.onError({
                    code: 'unknown_code',
                    message: 'что-то пошло не так',
                    response,
                });
            },
        },
    );

    const setClid = useCallback(
        (postV1ClIdRequest: PostV1ClIdRequest) => {
            return mutateAsync(postV1ClIdRequest);
        },
        [mutateAsync],
    );

    return { ...rest, setClid };
};
