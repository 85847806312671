export const YandexIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill="#FC3F1D"
            />
            <path
                d="M16.3421 19.5331H13.7079V6.50431H12.5344C10.3833 6.50431 9.25658 7.57983 9.25658 9.18533C9.25658 11.0068 10.0337 11.8508 11.6414 12.9263L12.9664 13.8192L9.15861 19.5308H6.32617L9.75315 14.4316C7.78247 13.0243 6.67355 11.6503 6.67355 9.33229C6.67355 6.43528 8.69322 4.4646 12.5166 4.4646H16.3243V19.5286H16.3421V19.5331Z"
                fill="white"
            />
        </svg>
    );
};
