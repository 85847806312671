import { Components, Theme } from '@mui/material';

export const MuiMenuItem: Components<Omit<Theme, 'components'>>['MuiMenuItem'] =
    {
        styleOverrides: {
            root: ({ theme }) => ({
                ':hover': {
                    backgroundColor: 'transparent',
                },
                fontFamily: theme.typography.subtitle1.fontFamily,
                fontSize: theme.typography.subtitle1.fontSize,
                fontWeight: theme.typography.subtitle1.fontWeight,
                lineHeight: theme.typography.subtitle1.lineHeight,
                padding: 0,
                '&.Mui-selected': {
                    backgroundColor: 'transparent',
                },
                '&.Mui-selected:hover': {
                    backgroundColor: 'transparent',
                },
            }),
        },
    };
