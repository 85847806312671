import { Section } from '@shared/api/axios-client';
import { TEcommercePromotion } from '@shared/hooks/use-yandex-ecommerce';

export const mapEcommerceBannerPromo = (
    sections: Section[],
    position: 'park' | 'driver',
): TEcommercePromotion[] => {
    return sections.reduce<TEcommercePromotion[]>(
        (acc, section) => [
            ...acc,
            ...section.banners.map(banner => ({
                id: `news${section.id}`,
                name: section.sectionName,
                creative: banner.id,
                position: position,
            })),
        ],
        [],
    );
};
