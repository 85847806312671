import CheckIcon from '@mui/icons-material/Check';
import { Button, Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    id: string;
    title: string;
    isDirty: boolean;
    withIcon?: boolean;
    isEditable?: boolean;
    isError?: boolean;
    onClick: () => void;
};

export const ProviderInfoCard = ({
    id,
    title,
    isDirty,
    isError,
    isEditable = true,
    withIcon = true,
    onClick,
}: TProps) => {
    const getDescription = () => {
        if (isError) {
            return 'Нет доступа';
        }
        if (isDirty) {
            return 'Данные добавлены';
        }
        return 'Данные не добавлены';
    };
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            data-testid={selectors.providerInfoCard(id)}
            sx={theme => ({
                background: theme.palette.surface.primary,
                padding: '20px',
                borderRadius: '5px',
            })}>
            <Stack maxWidth="45%">
                <Stack direction="row" alignItems="center" gap="10px">
                    <Typography
                        variant="h4"
                        maxWidth="100%"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        color={theme =>
                            isError
                                ? theme.palette.typography.negative
                                : theme.palette.typography.primary
                        }>
                        {title}
                    </Typography>
                    <Conditional condition={isDirty && withIcon}>
                        <CheckIcon
                            sx={theme => ({
                                color: theme.palette.icon.primary,
                            })}
                            fontWeight="700"
                        />
                    </Conditional>
                </Stack>
                <Typography
                    variant="caption1"
                    color={theme =>
                        isError
                            ? theme.palette.typography.negative
                            : theme.palette.typography.secondary
                    }>
                    {getDescription()}
                </Typography>
            </Stack>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                width="160px"
                justifyContent="end">
                {isEditable && (
                    <Button
                        data-testid={
                            isDirty
                                ? selectors.providersProviderInfoCardChangeButton
                                : selectors.providersProviderInfoCardAddButton
                        }
                        variant={isDirty ? 'text' : 'contained'}
                        color="buttonPrimary"
                        onClick={onClick}
                        fullWidth>
                        {isDirty ? 'изменить' : 'внести данные'}
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
