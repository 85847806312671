import { Components, Theme } from '@mui/material';

export const MuiTooltip: Components<Omit<Theme, 'components'>>['MuiTooltip'] = {
    styleOverrides: {
        popper: ({ theme }) => ({
            fontFamily: theme.typography.caption2.fontFamily,
            fontSize: theme.typography.caption2.fontSize,
            fontWeight: theme.typography.caption2.fontWeight,
            lineHeight: theme.typography.caption2.lineHeight,
            '.MuiTooltip-tooltip': {
                background: theme.palette.other.tooltip,
            },
            '.MuiTooltip-arrow': {
                color: theme.palette.other.tooltip,
            },
        }),
    },
};
