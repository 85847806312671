import { ThemeOptions } from '@mui/material';

export const typography: ThemeOptions['typography'] = palette => ({
    allVariants: {
        fontFamily: 'Inter',
        lineHeight: 1.2,
        color: palette.typography.primary,
    },
    h1: {
        fontWeight: 600,
        fontSize: 100,
        lineHeight: 1.2,
    },
    h2: {
        fontWeight: 600,
        fontSize: 52,
        lineHeight: 1.05,
    },
    h3: {
        fontWeight: 600,
        fontSize: 35,
        lineHeight: 1.15,
    },
    h4: {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 1.25,
    },
    h5: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: 1.33,
    },
    subtitle1: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 1.33,
    },
    subtitle2: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.25,
        letterSpacing: '-0.08px',
    },
    body1: {
        fontWeight: 400,
        fontSize: 35,
        lineHeight: 1.2,
    },
    body2: {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.33,
    },
    body3: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.5,
    },
    caption1: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: '-0.24px',
    },
    caption2: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: '-0.24px',
    },
    button: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.5,
    },
    inputValue1: {
        fontWeight: 600,
        fontSize: 35,
        lineHeight: 1.15,
        letterSpacing: '-1.05px',
    },
    inputValue2: {
        fontWeight: 600,
        fontSize: 52,
        lineHeight: 1.15,
        letterSpacing: '-1.05px',
    },
    inputValue3: {
        fontWeight: 600,
        fontSize: 12,
        lineHeight: 1.15,
        letterSpacing: '-2%',
    },
});
