import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TPriceLimit,
} from '@entities/products/types';
import { TFormProvider } from '@entities/providers/types';
import { FormFiltersProvider } from '@features/form-filters';
import { TFormData } from '@features/form-filters/form-filters';

type TProps = {
    formProviders: TFormProvider[];
    formDelivery: TFormDelivery[];
    formDeliveryType: TFormDeliveryType[];
    formState: TFormState[];
    initValues?: TFormData;
    priceLimit: TPriceLimit;
    onFilterChange: (data: TFormData) => void;
};

export const CatalogFiltersWidget = ({
    formProviders,
    formDelivery,
    formDeliveryType,
    formState,
    initValues,
    priceLimit,
    onFilterChange,
}: TProps) => {
    return (
        <FormFiltersProvider
            providers={formProviders}
            deliveryDateOptions={formDelivery}
            deliveryTypeOptions={formDeliveryType}
            stateOptions={formState}
            initValues={initValues}
            onSubmit={onFilterChange}
            priceLimit={priceLimit}
        />
    );
};
