import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Slide, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { TProvider } from '@entities/providers/types';
import { selectors } from '@shared/test/selectors';
import { Logo } from '@shared/ui/atoms/logo';

import { ProviderAddCredential } from '../provider-add-credential';

type TProps = {
    open: boolean;
    provider: TProvider;
    FormComponent: ReactNode;
    onClose: () => void;
};

export const ProviderCredentialModal = ({
    provider,
    open,
    FormComponent,
    onClose,
}: TProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Slide
                direction="down"
                in={open}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                <Box
                    data-testid={selectors.providersProviderCredentialModal}
                    height={{ xs: '100dvh', md: '90dvh' }}
                    width="100vw"
                    overflow="auto"
                    paddingBottom="20px"
                    sx={theme => ({
                        background: theme.palette.bg.primary,
                    })}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={{ xs: '10px', md: '30px 40px' }}>
                        <Logo />
                        <IconButton
                            data-testid={
                                selectors.providersProviderCredentialModalCloseButton
                            }
                            onClick={onClose}
                            color="buttonPrimary">
                            <CloseIcon sx={{ fontSize: '42px' }} />
                        </IconButton>
                    </Stack>
                    <ProviderAddCredential
                        title={provider?.title}
                        fields={provider.credentialFields}
                        instantOrderAvailable={provider?.instantOrderAvailable}
                        FormComponent={FormComponent}
                    />
                </Box>
            </Slide>
        </Modal>
    );
};
