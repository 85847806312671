import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, MenuItem, Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';

type TProps = {
    label: string;
    path: string;
};

export const SideMenuItem = ({ label, path }: TProps) => {
    return (
        <MenuItem
            sx={{
                '&:hover': {
                    background: 'none',
                },
            }}>
            <Link
                data-testid={selectors.topbarMenuWidgetMenuItemLink}
                href={path}>
                <Stack
                    direction="row"
                    gap="30px"
                    alignItems="center"
                    sx={theme => ({
                        color: theme.palette.buttonPrimaryInvert.main,
                        '&:hover': {
                            color: theme.palette.buttonPrimaryInvert.dark,
                        },
                        '&:hover > svg': {
                            transform: 'translate(10px, 0)',
                        },
                    })}>
                    <Typography
                        variant="h3"
                        color="inherit"
                        sx={{
                            transition: 'color 0.2s',
                        }}>
                        {label}
                    </Typography>
                    <ArrowForwardIcon
                        color="inherit"
                        fontSize="large"
                        sx={{
                            transition: 'transform 0.5s, color 0.5s',
                            display: {
                                xs: 'none',
                                md: 'inline-block',
                            },
                        }}
                    />
                </Stack>
            </Link>
        </MenuItem>
    );
};
