import { GlobalStyles as MUIGlobalStyle } from '@mui/material';

export const GlobalStyles = () => (
    <MUIGlobalStyle
        styles={theme => ({
            '*': {
                boxSizing: 'border-box',
                'scrollbar-color': `${theme.palette.grey[500]} transparent`,
                'scrollbar-width': 'thin',
            },
            body: {
                margin: '0!important',
                padding: '0!important',
                fontFamily: `'Noto Sans', sans-serif`,
                'overflow-x': 'hidden',
            },
            'h1, h2, h3, h4, h5, h6': {
                marginBlock: 0,
            },
            a: {
                textDecoration: 'none',
            },
            [`input[type="search"]::-webkit-search-decoration,
            input[type="search"]::-webkit-search-cancel-button,
            input[type="search"]::-webkit-search-results-button,
            input[type="search"]::-webkit-search-results-decoration`]: {
                '-webkit-appearance': 'none',
            },
        })}
    />
);
