export type TEcommerceProduct = {
    id: string;
    name: string;
    brand?: string;
    category?: string;
    coupon?: string;
    discount?: number;
    list?: string;
    position?: number;
    price?: number;
    quantity?: number;
    variant?: string;
};

export type TEcommercePromotion = {
    id: 'adv' | string;
    creative: string;
    position: 'search' | 'fav' | 'results' | 'park' | 'driver';
    creative_slot?: string;
};

export const useYandexEcommerce = () => {
    return {
        impressionAction: (products: TEcommerceProduct[]) =>
            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    impressions: products,
                },
            }),
        addAction: (product: TEcommerceProduct) =>
            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    add: {
                        products: [product],
                    },
                },
            }),
        removeAction: (product: TEcommerceProduct) =>
            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    remove: {
                        products: [product],
                    },
                },
            }),
        purchaseAction: (products: TEcommerceProduct[], orderId: string) =>
            window.dataLayer.push({
                ecommerce: {
                    currencyCode: 'RUB',
                    purchase: {
                        actionField: {
                            id: orderId,
                        },
                        products: products,
                    },
                },
            }),
        promoViewAction: (promotions: TEcommercePromotion[]) =>
            window.dataLayer.push({
                ecommerce: {
                    promoView: {
                        promotions: promotions,
                    },
                },
            }),
        promoClickAction: (promotion: TEcommercePromotion) =>
            window.dataLayer.push({
                ecommerce: {
                    promoView: {
                        promotions: [promotion],
                    },
                },
            }),
    };
};
