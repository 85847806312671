import React, { ErrorInfo, ReactNode } from 'react';

type TProps = {
    children: ReactNode;
};

type TState = {
    hasError: boolean;
};

export class ErrorBoundaryProvider extends React.Component<TProps, TState> {
    constructor(props: TProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo.componentStack);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <h1>Что-то пошло не так, попробуйте перезагрузить страницу</h1>
            );
        }

        return this.props.children;
    }
}
