import {
    CssBaseline,
    ThemeProvider as MaterialThemeProvider,
    StyledEngineProvider,
} from '@mui/material';
import { ReactNode } from 'react';

import { lightTheme } from './light-theme';

type TProps = { children: ReactNode };

export const ThemeProvider = ({ children }: TProps) => {
    return (
        <StyledEngineProvider injectFirst>
            <MaterialThemeProvider theme={lightTheme}>
                <CssBaseline />
                {children}
            </MaterialThemeProvider>
        </StyledEngineProvider>
    );
};
