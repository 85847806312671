import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TPriceLimit,
} from '@entities/products/types';
import { TFormProvider } from '@entities/providers/types';

import { FormFilters, TFormData } from './form-filters';

type TProps = {
    providers: TFormProvider[];
    initValues?: TFormData;
    deliveryDateOptions: TFormDelivery[];
    deliveryTypeOptions: TFormDeliveryType[];
    stateOptions: TFormState[];
    priceLimit: TPriceLimit;
    onSubmit: (data: TFormData) => void;
};

export const FormFiltersProvider = ({
    providers,
    deliveryDateOptions,
    initValues,
    deliveryTypeOptions,
    stateOptions,
    priceLimit,
    onSubmit,
}: TProps) => {
    const schema = z.object({
        minPrice: z.coerce
            .number({
                invalid_type_error: 'Сумма должна быть числом',
            })
            .int()
            .positive()
            .min(0, { message: 'Сумма должна быть больше 0' })
            .optional(),
        maxPrice: z.coerce
            .number({
                invalid_type_error: 'Сумма должна быть числом',
            })
            .int()
            .positive()
            .max(1000000, { message: 'Сумма должна быть меньше 1000000' }) //TODO @OKA (SA) уточнить
            .optional(),
    });

    const methods = useForm<TFormData>({
        mode: 'onSubmit',
        resolver: zodResolver(schema),
        defaultValues: initValues,
    });

    useEffect(() => {
        const subscription = methods.watch(() => {
            onSubmit(methods.getValues());
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider {...methods}>
            <FormFilters
                providers={providers}
                deliveryDateOptions={deliveryDateOptions}
                deliveryTypeOptions={deliveryTypeOptions}
                stateOptions={stateOptions}
                priceLimit={priceLimit}
            />
        </FormProvider>
    );
};
