import { useUnit } from 'effector-react';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { $login } from '@entities/auth/model/auth-domain';

type TProps = {
    children: ReactElement | null;
    fallbackRoute: string;
};

export const Protected = ({ children, fallbackRoute }: TProps) => {
    const isLogin = useUnit($login);
    if (!isLogin) {
        return <Navigate to={fallbackRoute} />;
    }
    return children;
};
