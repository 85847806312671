import { TProductTableHeaderField } from '@entities/products/types';

export const tableHeader: TProductTableHeaderField[] = [
    {
        label: '№',
        alight: undefined,
        size: 1,
        attribute: '1',
    },
    {
        label: 'Название товара',
        alight: undefined,
        size: 5,
        attribute: '2',
    },
    {
        label: 'Производитель',
        alight: undefined,
        size: 2,
        attribute: '3',
    },
    {
        label: 'Артикул',
        alight: undefined,
        size: 3,
        attribute: '4',
    },
];
