import { Button, Stack } from '@mui/material';

import { FeatureToggle } from '@features/feature-toggle';
import { selectors } from '@shared/test/selectors';

type TProps = {
    onPrimaryButton: () => void;
    onSecondaryButton: () => void;
    OAuthComponent: JSX.Element;
};

const isLoginWithNews = FeatureToggle.hasFeatureFlag('newses');

export const AuthControls = ({
    onPrimaryButton,
    onSecondaryButton,
    OAuthComponent,
}: TProps) => {
    return isLoginWithNews ? (
        <Stack direction="row" gap="16px" flexWrap="wrap">
            <Button
                data-testid={selectors.authPrimaryButton}
                variant="contained"
                color="buttonPrimary"
                sx={{ width: '320px' }}
                onClick={onPrimaryButton}>
                Вход для таксопарков
            </Button>
            <Button
                data-testid={selectors.authSecondaryButton}
                variant="contained"
                color="buttonSecondaryEnabled"
                sx={{ width: '320px' }}
                onClick={onSecondaryButton}>
                Вход для водителей
            </Button>
        </Stack>
    ) : (
        OAuthComponent
    );
};
