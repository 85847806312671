import { Components, Theme } from '@mui/material';

export const MuiAccordion: Components<
    Omit<Theme, 'components'>
>['MuiAccordion'] = {
    styleOverrides: {
        root: {
            background: 'transparent',
            boxShadow: 'none',
            '&.Mui-expanded': {
                margin: '0 !important',
            },
        },
    },
};
