import { InfoModal } from '@shared/ui/organisms/info-modal';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const InstantInfoModal = ({ isOpen, onClose }: TProps) => {
    return (
        <InfoModal
            open={isOpen}
            onClose={onClose}
            variant="error"
            withHeader
            title="Мгновенное оформление заказа"
            description="Обратите внимание, в вашем личном кабинете у поставщика должны быть указаны корректные данные по оплате и получению заказа, т.к. оформленный заказ будет передан из корзины сервиса Витрины напрямую поставщику."
            primaryButtonText="хорошо"
            onPrimaryButtonClick={onClose}
        />
    );
};
