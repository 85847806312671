import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

//TODO (@OKA) придумать как избежать
// eslint-disable-next-line boundaries/element-types
import { FeatureToggle } from '@features/feature-toggle';
import { UnknownBusinessError, addsApi } from '@shared/api';
import {
    Advertising,
    AdvertisingApiGetV1AdvertisingRequest,
} from '@shared/api/axios-client';

import { addsKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetFavorite = {
    body: AdvertisingApiGetV1AdvertisingRequest;
    onSuccess?: (data: AxiosResponse<Advertising, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

const isAdds = FeatureToggle.hasFeatureFlag('adds');

export const useGetAdvert = (props: TUseGetFavorite) => {
    return useQuery(
        addsKeys.getAdds(props.body.pageType).queryKey,
        () =>
            addsApi.getV1Advertising(props.body, {
                headers: {
                    Authorization: 'Bearer',
                },
            }),
        {
            enabled: isAdds,
            onSuccess: props?.onSuccess,
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
