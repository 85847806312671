import { TNews } from '@entities/news/types';
import { News } from '@shared/api/axios-client';

export const mapNews = (newses?: News): TNews[] => {
    return (
        newses?.sections.map(news => ({
            id: news.id,
            title: news.sectionName,
            banners: news.banners.map(banner => ({
                id: banner.id,
                title: banner.title,
                pictureUrl: banner.pictureUrl,
                shortText: banner.shortText,
                buttonText: banner.buttonName,
                fullText: banner.fullText,
                url: banner.redirectUrl,
            })),
        })) || []
    );
};
