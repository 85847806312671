import { useUnit } from 'effector-react';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { $userInfo } from '@entities/auth/model/auth-domain';

type TProps = {
    children: ReactElement | null;
    fallbackRoute: string;
};

export const ClientIdGuard = ({ children, fallbackRoute }: TProps) => {
    const userInfo = useUnit($userInfo);

    if (!userInfo?.clientId) {
        return <Navigate to={fallbackRoute} />;
    }

    return children;
};
