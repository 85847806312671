import { Components, Theme } from '@mui/material';

export const MuiDialogActions: Components<
    Omit<Theme, 'components'>
>['MuiDialogActions'] = {
    styleOverrides: {
        root: {
            padding: 0,
            marginTop: 4,
            justifyContent: 'center',
        },
    },
};
