import { createDomain, createEvent, restore } from 'effector';
import { persist as persistLocal } from 'effector-storage/local';

import { TSession, TUserInfo } from '../types';

type TDeviceInfo = {
    deviceId: string;
    deviceName: string;
};

export const authDomain = createDomain('auth');
export const resetAthDomainStores = authDomain.createEvent();

authDomain.onCreateStore(store => {
    store.reset(resetAthDomainStores);
});

export const updateDeviceInfo = createEvent<TDeviceInfo>();
export const $deviceInfo = restore(updateDeviceInfo, null);

persistLocal({
    store: $deviceInfo,
    key: 'device-info',
});

export const updateSession = authDomain.createEvent<TSession>();
export const $session = restore(updateSession, null);

persistLocal({
    store: $session,
    key: 'session',
});

export const updateLogin = authDomain.createEvent<boolean>();
export const $login = restore(updateLogin, false);

persistLocal({
    store: $login,
    key: 'login',
});

export const setUserInfo = authDomain.createEvent<TUserInfo>();
export const updateUserInfo = authDomain.createEvent<TUserInfo>();
export const $userInfo = restore(setUserInfo, null);

$userInfo.on(updateUserInfo, (state, payload) => ({ ...state, ...payload }));

persistLocal({
    store: $userInfo,
    key: 'user-info',
});
