import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { TCartValue } from '@entities/cart/types';
import { CartProductGroup } from '@shared/api/axios-client';
import { useYandexReachGoal } from '@shared/hooks';

import { useAddToCart } from './use-add-to-cart';
import { useCartEcommerce } from './use-cart-ecommerce';
import { useDeleteCartItem } from './use-delete-cart-item';

export const useChangeCartAmount = () => {
    const [lastItem, setLastItem] = useState<TCartValue>();
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const { changeCartActions } = useCartEcommerce();
    const onUnknownPostError = () =>
        enqueueSnackbar('что-то пошло не так', {
            variant: 'error',
            description: 'попробуйте повторить запрос',
        });
    const { addToCart, isLoading } = useAddToCart({
        onError: onUnknownPostError,
    });
    const { deleteCartItem } = useDeleteCartItem({
        onError: onUnknownPostError,
    });
    const handelChangeQuantity = useCallback(
        (value: TCartValue, cart?: CartProductGroup[]) => {
            if (JSON.stringify(value) === JSON.stringify(lastItem)) {
                return;
            }
            setLastItem(value);
            const itemToChange = {
                productId: value.productId,
                warehouseId: value.warehouseId,
                providerId: value.providerId,
            };
            const cartGroup = cart?.find(
                item => item.providerId === value.providerId,
            );
            changeCartActions(
                {
                    ...itemToChange,
                    quantity: value.quantity,
                    position: value.position,
                    brand: value.brand,
                },
                cartGroup?.products,
            );
            if (value.quantity === 0) {
                deleteCartItem(itemToChange);
                return;
            }
            if (!cartGroup) {
                fireProductEvent('addToCart');
            }
            addToCart({ ...itemToChange, quantity: value.quantity });
        },
        [
            addToCart,
            changeCartActions,
            deleteCartItem,
            fireProductEvent,
            lastItem,
        ],
    );
    return { handelChangeQuantity, isLoading };
};
