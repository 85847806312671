import { Components, Theme } from '@mui/material';

export const MuiDialogTitle: Components<
    Omit<Theme, 'components'>
>['MuiDialogTitle'] = {
    styleOverrides: {
        root: {
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '30px',
            letterSpacing: '-0.48px',
            textAlign: 'center',
            marginBottom: '8px',
            padding: 0,
        },
    },
};
