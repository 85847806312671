import { TAdd } from '@entities/adds/types';
import { AdvertisingBanner } from '@shared/api/axios-client';

const addSizes = {
    rectangularSmall: {
        width: '274px',
        aspectRatio: '0.85 / 1',
    },
    rectangularBig: {
        width: '100%',
        aspectRatio: '3.8 / 1',
    },
    square: {
        width: '274px',
        aspectRatio: '1 / 1',
    },
    rectangularLargeLow: {
        width: '100%',
        aspectRatio: '18 / 1',
    },
};

export const mapAdds = (data?: AdvertisingBanner[]): TAdd[] => {
    return (
        data?.map(item => ({
            id: item.id,
            width: addSizes[item.variant].width,
            imageUrl: item.picture_url,
            href: item.redirect_url,
            aspectRatio: addSizes[item.variant].aspectRatio,
        })) || []
    );
};
