import { Components, Theme } from '@mui/material';

export const MuiBadge: Components<Omit<Theme, 'components'>>['MuiBadge'] = {
    variants: [
        {
            props: {
                color: 'error',
            },
            style: ({ theme }) => ({
                '.MuiBadge-badge': {
                    color: theme.palette.typography.onlyWhite,
                },
            }),
        },
        {
            props: {
                color: 'primary',
            },
            style: ({ theme }) => ({
                '.MuiBadge-badge': {
                    color: theme.palette.typography.onlyDark,
                    backgroundColor: theme.palette.typography.onlyWhite,
                },
            }),
        },
    ],
    defaultProps: {
        color: 'error',
    },
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiBadge-badge': {
                borderRadius: '50%',
                border: `solid 1px ${theme.palette.icon.primaryInvert}`,
                fontFamily: theme.typography.caption2.fontFamily,
                fontSize: theme.typography.caption2.fontSize,
                fontStyle: theme.typography.caption2.fontStyle,
                fontWeight: theme.typography.caption2.fontWeight,
                lineHeight: theme.typography.caption2.lineHeight,
                letterSpacing: theme.typography.caption2.letterSpacing,
            },
        }),
    },
};
