import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggle } from '@features/feature-toggle';
import { UnknownBusinessError, providersApi } from '@shared/api';
import { ProvidersApiGetV1ProviderCredentialRequest } from '@shared/api/axios-client';

import { providersKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetProvidersCredential = {
    provider: ProvidersApiGetV1ProviderCredentialRequest;
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const useGetProvidersCredential = ({
    provider,
    onSuccess,
    onError,
}: TUseGetProvidersCredential) => {
    return useQuery(
        providersKeys.credential(provider.providerId).queryKey,
        () => {
            return providersApi.getV1ProviderCredential(provider, {
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            enabled: Boolean(provider.providerId) && IS_PROVIDERS_ENABLED,
            onSuccess: () => {
                onSuccess && onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    onError(response.data);
                } else {
                    onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
