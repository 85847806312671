import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';
import { InfoOutlinedIcon } from '@shared/ui/icons';

type TProps = {
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
};

export const EmptyProviders = ({
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: TProps) => {
    return (
        <Grid container direction="row" height="calc(90% - 95px)">
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={9}>
                <Stack
                    height="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Stack direction="column" gap="80px">
                        <Stack direction="column" gap="20px">
                            <Typography variant="h2">
                                Пока вы не добавили ни одного поставщика
                            </Typography>
                            <Typography
                                variant="body2"
                                color={theme =>
                                    theme.palette.typography.secondary
                                }>
                                Чтобы увидеть результаты поиска, нужно добавить
                                минимум одного
                            </Typography>
                        </Stack>
                        <Stack direction="row" gap="20px">
                            <Button
                                data-testid={
                                    selectors.accountChangeProvidersEmptyPrimaryButton
                                }
                                variant="contained"
                                color="buttonPrimary"
                                sx={{ minWidth: '150px' }}
                                onClick={onPrimaryButtonClick}>
                                добавить
                            </Button>
                            <Button
                                data-testid={
                                    selectors.accountChangeProvidersEmptySecondaryButton
                                }
                                variant="contained"
                                color="buttonSecondaryEnabled"
                                sx={{ minWidth: '150px' }}
                                onClick={onSecondaryButtonClick}>
                                пропустить
                            </Button>
                        </Stack>
                    </Stack>
                    <Box
                        height="270px"
                        width="270px"
                        fontSize="15px"
                        color={theme =>
                            theme.palette.action.disabledBackground
                        }>
                        <InfoOutlinedIcon />
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
};
