import { TCart, TCartChangeItem, TCartRemoveItem } from '@entities/cart/types';
import { TCartAccordionData } from '@entities/cart/ui/cart-accordion/cart-accordion';
import { V1CartResponse } from '@shared/api/axios-client';
import { dateUtils } from '@shared/lib/utils';
import { EDateFormat } from '@shared/lib/utils/date';

export const mapCart = (
    onRemoveGroup: (providerId: string) => void,
    onChangeCart: (cartItem: TCartChangeItem) => void,
    onRemove: (cartItem: TCartRemoveItem) => void,
    onExportGroup: (cart: TCart) => void,
    cartProducts?: V1CartResponse,
): TCartAccordionData[] => {
    if (!cartProducts) {
        return [];
    }

    return cartProducts.cart.map(cart => {
        const cartItem: TCart = {
            id: `${cartProducts.id}${cart.providerId}`,
            totalAmount: cart.totalQuantity,
            totalPrice: cart.totalPrice,
            instantOrderAvailable: cart.instantOrderAvailable,
            minOrderPrice: cart.minOrderPrice || 0,
            providerName: cart.providerName,
            providerId: cart.providerId,
            items: cart.products.map(product => ({
                id: product.product.id,
                providerId: product.provider.id,
                warehouseId: product.warehouse.id,
                price: product.warehouse.price,
                title: product.product.name,
                article: product.product.vendorCode,
                deliveryDate:
                    product.warehouse.shipmentDate &&
                    dateUtils.formatDate(
                        product.warehouse.shipmentDate,
                        EDateFormat.ddMMyyyy,
                    ),
                amount: product.warehouse.quantity,
                stock: product.warehouse.stock,
            })),
        };
        return {
            cart: cartItem,
            title: cart.providerName,
            isError: cart.totalPrice <= cartItem.minOrderPrice,
            onRemoveGroup: () => onRemoveGroup(cart.providerId),
            onExportGroup: () => onExportGroup(cartItem),
            onChangeCart,
            onRemove,
        };
    });
};
