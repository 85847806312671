export const authPaths = {
    home: {
        absolute: '/auth',
        relative: 'auth',
    },
    company: {
        absolute: '/company-auth',
        relative: 'company-auth',
    },
    driver: {
        absolute: '/driver',
        relative: 'driver',
    },
} as const;
