import { Box, CircularProgress, Stack } from '@mui/material';
import { useState } from 'react';

import {
    FormClientIdProvider,
    TExternalFormError,
    TFormData,
} from '@features/form-client-id';
import { useStateModal } from '@shared/hooks';
import { DoneIcon } from '@shared/ui/icons';
import { InfoModal } from '@shared/ui/organisms/info-modal';

type TProps = {
    defaultValue?: number;
    isSuccess: boolean;
    isSubmitting: boolean;
    externalFormError?: TExternalFormError;
    onSubmit: (data: TFormData) => void;
};

export const ChangeClientIdPage = ({
    defaultValue,
    isSuccess,
    isSubmitting,
    externalFormError,
    onSubmit,
}: TProps) => {
    const [formData, setFormData] = useState<TFormData>();
    const { open, isOpen, close } = useStateModal();

    const handleOpenModal = (data: TFormData) => {
        setFormData(data);
        open();
    };

    const handleSubmit = () => {
        if (formData) {
            onSubmit(formData);
        }
        close();
    };

    if (isSubmitting) {
        return (
            <Box color={theme => theme.palette.common.white}>
                <CircularProgress color="inherit" size="80px" />
            </Box>
        );
    }
    if (isSuccess) {
        return (
            <Box color={theme => theme.palette.common.white}>
                <DoneIcon />
            </Box>
        );
    }
    return (
        <Stack
            height="100%"
            justifyContent={{ xs: 'center', md: 'start' }}
            paddingX={{ xs: '8px', md: 0 }}>
            <FormClientIdProvider
                onSubmit={handleOpenModal}
                buttonText="изменить"
                defaultValue={defaultValue}
                externalFormError={externalFormError}
            />
            <InfoModal
                withHeader
                withCloseIcon
                open={isOpen}
                onClose={close}
                variant="question"
                title="Вы уверены, что хотите изменить clientID?"
                description="Данные по сохраненным ранее поставщикам будут удалены"
                primaryButtonText="да, изменить"
                onPrimaryButtonClick={handleSubmit}
                secondaryButtonText="нет, отменить"
                onSecondaryButtonClick={close}
            />
        </Stack>
    );
};
