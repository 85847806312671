import { Typography } from '@mui/material';
import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAddClickEcommerce, useGetAdvert } from '@entities/adds/model';
import { mapAdds, mapEcommerceAddPromo } from '@entities/adds/utils/mappers';
import { $userInfo } from '@entities/auth/model/auth-domain';
import { useGetUserProviders } from '@entities/user/model';
import {
    $searchHistory,
    addToSearchHistory,
} from '@entities/user/model/user-domain';
import { FeatureToggle } from '@features/feature-toggle';
import { useStateModal, useYandexEcommerce } from '@shared/hooks';
import { Conditional } from '@shared/ui/atoms/conditional';
import { InfoModal } from '@shared/ui/organisms/info-modal';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { SearchPage } from './search-page';
import { catalogPaths } from '../paths';

type TProps = {
    externalRoutes: {
        providerHomePath: string;
    };
    headerPaths: THeaderPaths;
};

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const SearchPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const { open, isOpen, close } = useStateModal();
    const userInfo = useUnit($userInfo);
    const [params] = useSearchParams();
    const { promoViewAction } = useYandexEcommerce();
    const onAddClick = useAddClickEcommerce();
    const navigate = useNavigate();
    const initSearch = params.get('search') || '';
    const { data } = useGetUserProviders({
        onError: () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'попробуйте позже',
            }),
    });
    const { data: addsData } = useGetAdvert({
        body: { pageType: 'search' },
    });
    const adds = useMemo(
        () => mapAdds(addsData?.data.advertising_banners),
        [addsData],
    );
    useEffect(() => {
        if (addsData) {
            promoViewAction(
                mapEcommerceAddPromo(
                    addsData.data.advertising_banners,
                    String(userInfo?.clientId) || '',
                    'search',
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addsData, userInfo?.clientId]);
    const searchHistory = useUnit($searchHistory);
    const { enqueueSnackbar } = useSnackbar();
    const handleAddProvider = useCallback(() => {
        navigate(externalRoutes.providerHomePath);
    }, [externalRoutes.providerHomePath, navigate]);

    const handleSubmit = useCallback(
        (search: string) => {
            addToSearchHistory(search);
            if (!Boolean(data?.data.provider?.length) && IS_PROVIDERS_ENABLED) {
                open();
                return;
            }
            navigate(`${catalogPaths.brand.absolute}?search=${search}`);
        },
        [data?.data.provider?.length, navigate, open],
    );

    const handleAddClick = useCallback(
        (id: string) => {
            if (!addsData) {
                return;
            }
            onAddClick({
                targetAddId: id,
                adds: addsData.data.advertising_banners,
                userId: String(userInfo?.clientId) || '',
                position: 'search',
            });
        },
        [addsData, onAddClick, userInfo?.clientId],
    );

    return (
        <PageTemplate
            withHeadSpace
            variant="medium"
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    withProfile
                    paths={headerPaths}>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        поиск автозапчастей <br /> по всей России
                    </Typography>
                </HeaderWidgetConnector>
            }>
            <SearchPage
                suggests={searchHistory}
                initSearch={initSearch}
                adds={adds}
                onSubmit={handleSubmit}
                onAddClick={handleAddClick}
            />
            <Conditional condition={IS_PROVIDERS_ENABLED}>
                <InfoModal
                    open={isOpen}
                    withHeader
                    variant="error"
                    title="Пока вы не добавили ни одного поставщика"
                    description="Чтобы увидеть результаты поиска, нужно добавить минимум одного"
                    primaryButtonText="добавить"
                    secondaryButtonText="пропустить"
                    onPrimaryButtonClick={handleAddProvider}
                    onSecondaryButtonClick={close}
                    onClose={close}
                />
            </Conditional>
        </PageTemplate>
    );
};
