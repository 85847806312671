import { FeatureToggle } from '@features/feature-toggle';
import { ENV } from '@shared/config/constants';

const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const helpData = {
    vitrina: [
        {
            question: 'Как тут всё работает?',
            answer: 'Маркетплейс запчастей — это онлайн-сервис по заказу автозапчастей для таксопарков у разных поставщиков. Вам не придется искать по сайтам множества ваших поставщиков — можно по одному запросу увидеть все детали, которые есть в наличии, сравнить цены и решить, что и у кого купите. Учитываются ваши персональные скидки у поставщиков.',
            isVisible: true,
        },
        {
            question: 'Что такое clientID?',
            answer: 'Это уникальный идентификатор, который присваивается таксопарку при подключении к системе Яндекс Такси. Найти свой clientID можно в Диспетчерской: Статистика → Сводка → верхний левый угол чуть ниже названия вашего таксопарка.',
            isVisible: true,
        },
        {
            question: 'Здесь нет ответа на мой вопрос.',
            answer: 'Пожалуйста, обратитесь в техподдержку с помощью кнопки «Написать в поддержку» в правом верхнем углу экрана — специалисты постараются ответить вам в телеграм и помочь в течение одного рабочего дня.',
            isVisible: true,
        },
    ],
    providers: [
        {
            question: 'С какими поставщиками вы работаете?',
            answer: 'Мы собираем на сервисе самые крупные и популярные у таксопарков компании с разумными ценами и большими запасами на складах. Весь список доступных поставщиков вы можете посмотреть в своем личном кабинете — нажмите на кнопку «Добавить поставщика».',
            isVisible: true,
        },
        {
            question: 'Где взять данные, чтобы добавить поставщика?',
            answer: 'Обратитесь к нужному поставщику автозапчастей через поддержку на его официальном сайте, чтобы получить доступы в виде логина, пароля или персонального api ключа. Чаще всего эти данные есть в вашем личном кабинете на сайте компании.',
            isVisible: IS_PROVIDERS_ENABLED,
        },
    ],
    search: [
        {
            question: 'Как найти запчасть, которая мне нужна? ',
            answer: 'Введите артикул запчасти в поле «Поиск автозапчастей», нажмите enter и подождите, когда появятся результаты.',
            isVisible: true,
        },
        {
            question: 'Как сделать заказ?',
            answer: 'Положите нужные товары в корзину, определитесь с их количеством и поставщиком, затем нажмите «Оформить заказ» у нужного поставщика. Произойдет переход на сайт поставщика, где вы сможете уточнить данные для доставки и завершить оформление заказа.',
            isVisible: true,
        },
        {
            question: 'Почему ничего не нашлось? ',
            answer: 'Проверьте, что артикул запчасти введён правильно, а также, что ваши доступы у поставщика актуальны. Также возможно, что у поставщика сейчас проблемы с доступом и он не появляется в поиске. Если всё верно и все поставщики подключены, значит, запчасти нет в наличии. Вы можете добавить больше поставщиков или воспользоваться помощью в подборе деталей.',
            isVisible: true,
        },
        {
            question: 'Мне нужна помощь в подборе запчасти.',
            answer: 'Обратитесь к нашему эксперту через кнопку «Помощь в подборе» в правом верхнем углу экрана. Это бесплатно.',
            isVisible: true,
        },
    ],
    other: [
        {
            question: 'Что такое api ключ и где его взять?',
            answer: 'Api — ваш личный уникальный ключ в системе конкретного поставщика. Чтобы его получить, зарегистрируйтесь на сайте поставщика, после чего сможете найти api в вашем личном кабинете на сайте или запросить у техподдержки.',
            isVisible: true,
        },
        {
            question: 'Где взять clid?',
            answer: 'ClientID можно найти в Диспетчерской → Статистика → Сводка → верхний левый угол чуть ниже названия вашего таксопарка.',
            isVisible: true,
        },
        {
            question: 'Моего поставщика нет в вашем списке.',
            answer: `Вы можете <a href="${ENV.yandexPropositionForm}" target="_blank">оставить заявку</a>, чтобы вашего поставщика добавили в базу сервиса.`,
            isVisible: true,
        },
        {
            question: 'Ошибка при добавлении поставщика.',
            answer: `Пожалуйста, обратитесь в <a href="${ENV.yandexSupportLink}" target="_blank">техподдержку</a>`,
            isVisible: IS_PROVIDERS_ENABLED,
        },
    ],
};
