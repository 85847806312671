import {
    Autocomplete,
    Box,
    Radio,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { TFormDelivery } from '@entities/products/types';
import { selectors } from '@shared/test/selectors';

type TFormScheme = {
    deliveryDate?: TFormDelivery;
};

type TProps = {
    fields: ControllerRenderProps<TFormScheme, 'deliveryDate'>;
    defaultValue?: Partial<TFormDelivery>;
    options: TFormDelivery[];
};

export const ProductDeliveryFilter = ({
    fields,
    defaultValue,
    options,
}: TProps) => {
    const theme = useTheme();
    const [isBlur, setIsBlur] = useState(false);
    return (
        <Box
            position="relative"
            borderRadius="24px"
            minWidth="280px"
            sx={theme => ({
                background: isBlur ? theme.palette.bg.primary : 'transparent',
            })}>
            <Autocomplete
                data-testid={
                    selectors.productsProductDeliveryFilterAutocomplete
                }
                disablePortal
                open={isBlur}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                defaultValue={defaultValue}
                clearIcon={false}
                options={options}
                disableClearable
                value={fields.value}
                onChange={(_, data) => {
                    fields.onChange(data);
                }}
                sx={theme => ({
                    '.MuiAutocomplete-inputRoot': {
                        padding: '0 !important',
                    },
                    position: 'static',
                    borderRadius: '24px',
                    border: isBlur
                        ? `1px solid ${theme.palette.border.border4}`
                        : '1px solid transparent',
                    padding: '20px',
                })}
                componentsProps={{
                    popper: {
                        sx: theme => ({
                            width: '100% !important',
                            border: `1px solid ${theme.palette.border.border4}`,
                            borderRadius: '0 0 24px 24px',
                            borderWidth: '0 1px 1px 1px',
                        }),
                    },
                    paper: {
                        sx: {
                            borderRadius: '0 0 24px 24px',
                            boxShadow: 'none',
                            '.MuiAutocomplete-listbox': {
                                margin: '0 20px',
                            },
                            '.MuiAutocomplete-option': {
                                padding: '0 !important',
                            },
                        },
                    },
                }}
                renderOption={(props, option, { selected, index }) => (
                    <li
                        {...props}
                        key={index}
                        data-testid={selectors.productsProductDeliveryFilterOption(
                            String(index),
                        )}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap="10px"
                            width="100%">
                            <Radio
                                checked={selected}
                                data-testid={selectors.productsProductDeliveryFilterOptionRadio(
                                    String(index),
                                )}
                            />
                            <Stack
                                height="46px"
                                width="100%"
                                justifyContent="center"
                                borderBottom={theme =>
                                    `0.5px solid ${theme.palette.border.border3}`
                                }>
                                <Typography variant="subtitle1">
                                    {option?.label}
                                </Typography>
                            </Stack>
                        </Stack>
                    </li>
                )}
                renderInput={params => (
                    <TextField
                        data-testid={
                            selectors.productsProductDeliveryFilterTextField
                        }
                        variant="standard"
                        label="Сроки"
                        placeholder={fields.value ? '' : 'Любой'}
                        onFocus={() => setIsBlur(true)}
                        onBlur={() => {
                            fields.onBlur();
                            setIsBlur(false);
                        }}
                        {...params}
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            sx: {
                                color:
                                    fields.value?.id === defaultValue?.id
                                        ? theme.palette.typography.tertiary
                                        : 'inherit',
                                marginRight: '20px',
                                '&::placeholder': {
                                    color: theme.palette.typography.tertiary,
                                    opacity: '1 !important',
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            filled: true,
                        }}
                    />
                )}
            />
        </Box>
    );
};
