import { TFormProvider } from '@entities/providers/types';
import { Provider } from '@shared/api/axios-client';

export const mapProviders = (providers?: Array<Provider>): TFormProvider[] => {
    if (!providers) {
        return [];
    }
    return providers.map(provider => ({
        id: provider.id,
        label: provider.name,
    }));
};
