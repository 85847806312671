/**
 *
 * Formats a number with spaces in European style.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number with spaces.
 *
 * @example
 * const number = 1000000;
 * const formattedNumber = formatSpaces(number); // "1 000 000"
 */

export const formatSpaces = (number: number, fixed?: number): string => {
    const numString = String(fixed ? number.toFixed(fixed) : number);
    const [integerPart, decimalPart] = numString.split('.');

    const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ' ',
    );
    if (decimalPart !== undefined) {
        return `${formattedIntegerPart}.${decimalPart}`;
    }

    return formattedIntegerPart;
};
