import { Button, Grid, Modal, Slide, Stack, Typography } from '@mui/material';

import { TCart } from '@entities/cart/types';
import { numberUtils } from '@shared/lib/utils';
import { selectors } from '@shared/test/selectors';
import { Logo } from '@shared/ui/atoms/logo';

type TProps = {
    cartItem?: TCart;
    isOpen: boolean;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
};

export const CartDialog = ({
    cartItem,
    isOpen,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: TProps) => {
    return (
        <Modal open={isOpen}>
            <Slide
                direction="down"
                in={isOpen}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                <Stack
                    height={{ xs: '100dvh', md: 'auto' }}
                    maxHeight="100dvh"
                    overflow="auto"
                    gap="80px"
                    width="100vw"
                    direction="column"
                    position="relative"
                    padding="30px 40px"
                    sx={theme => ({
                        background: theme.palette.bg.primary,
                        outlineColor: 'transparent',
                    })}>
                    <Grid container direction="row">
                        <Grid item xs={2}>
                            <Logo />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h2">
                                Как все прошло?
                            </Typography>
                        </Grid>
                    </Grid>
                    {cartItem && (
                        <Grid flex={1} container direction="row" spacing="40px">
                            <Grid item xs={0} md={2} />
                            <Grid item xs={12} md={6}>
                                <Stack direction="column" gap="60px">
                                    <Stack direction="column" gap="20px">
                                        <Stack direction="column" gap="5px">
                                            <Typography variant="h3">
                                                {cartItem.providerName}
                                            </Typography>
                                            <Typography
                                                variant="caption2"
                                                color={theme =>
                                                    theme.palette.typography
                                                        .secondary
                                                }>
                                                заказ на сумму -{' '}
                                                {numberUtils.formatCurrency(
                                                    cartItem.totalPrice,
                                                )}
                                            </Typography>
                                        </Stack>
                                        <Stack minWidth="500px" overflow="auto">
                                            <Grid
                                                direction="row"
                                                container
                                                paddingY="10px"
                                                borderBottom={theme =>
                                                    `solid 1px ${theme.palette.border.border4}`
                                                }>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        variant="caption1"
                                                        color={theme =>
                                                            theme.palette
                                                                .typography
                                                                .secondary
                                                        }>
                                                        №
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography
                                                        variant="caption1"
                                                        color={theme =>
                                                            theme.palette
                                                                .typography
                                                                .secondary
                                                        }>
                                                        Артикул и название
                                                        товара
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="flex-end">
                                                    <Typography
                                                        variant="caption1"
                                                        color={theme =>
                                                            theme.palette
                                                                .typography
                                                                .secondary
                                                        }>
                                                        Кол-во шт
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="flex-end">
                                                    <Typography
                                                        variant="caption1"
                                                        color={theme =>
                                                            theme.palette
                                                                .typography
                                                                .secondary
                                                        }>
                                                        Стоимость
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {cartItem.items.map(
                                                (item, index) => {
                                                    const currentNumber =
                                                        index + 1;
                                                    const stringNumber =
                                                        currentNumber >= 10
                                                            ? currentNumber
                                                            : `${0}${currentNumber}`;
                                                    return (
                                                        <Grid
                                                            key={index}
                                                            direction="row"
                                                            container
                                                            paddingY="10px"
                                                            borderBottom={theme =>
                                                                `solid 1px ${theme.palette.border.border4}`
                                                            }>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                display="flex"
                                                                alignItems="center">
                                                                <Typography variant="subtitle2">
                                                                    {
                                                                        stringNumber
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={5}
                                                                display="flex"
                                                                alignItems="center">
                                                                <Stack>
                                                                    <Typography variant="subtitle2">
                                                                        {
                                                                            item.article
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="caption1"
                                                                        color={theme =>
                                                                            theme
                                                                                .palette
                                                                                .typography
                                                                                .secondary
                                                                        }>
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </Typography>
                                                                </Stack>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="flex-end">
                                                                <Typography variant="subtitle2">
                                                                    {
                                                                        item.amount
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={3}
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="flex-end">
                                                                <Typography variant="subtitle2">
                                                                    {numberUtils.formatSpaces(
                                                                        item.price *
                                                                            item.amount,
                                                                        2,
                                                                    )}{' '}
                                                                    ₽
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                },
                                            )}
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" gap="20px">
                                        <Button
                                            variant="contained"
                                            color="buttonPrimary"
                                            onClick={onPrimaryButtonClick}
                                            data-testid={
                                                selectors.cartDialogPrimaryButton
                                            }>
                                            заказ оформлен на сайте
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="buttonSecondaryEnabled"
                                            onClick={onSecondaryButtonClick}
                                            data-testid={
                                                selectors.cartDialogSecondaryButton
                                            }>
                                            не удалось оформить
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                </Stack>
            </Slide>
        </Modal>
    );
};
