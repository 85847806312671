import { useCallback } from 'react';

import { mapEcommerceBannerPromo } from '@entities/news/utils/mappers/ecommerce-mappers';
import { Section } from '@shared/api/axios-client';
import { useYandexEcommerce } from '@shared/hooks';

type TArgs = {
    targetBannerId: string;
    targetSectionId: string;
    sections: Section[];
    positions: 'park' | 'driver';
};

export const useNewsClickEcommerce = () => {
    const { promoClickAction } = useYandexEcommerce();
    return useCallback(
        ({ targetBannerId, sections, targetSectionId, positions }: TArgs) => {
            if (!Boolean(sections.length)) {
                return;
            }
            const targetSection = sections.find(
                section => section.id === targetSectionId,
            );
            if (!targetSection) {
                return;
            }
            const targetBanner = targetSection.banners.find(
                banner => banner.id === targetBannerId,
            );
            if (!targetBanner) {
                return;
            }
            targetSection.banners = [targetBanner];
            promoClickAction(
                mapEcommerceBannerPromo([targetSection], positions)[0],
            );
        },
        [promoClickAction],
    );
};
