import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { UnknownBusinessError, cartApi, queryClient } from '@shared/api';

import { cartKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseDeleteCart = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useDeleteCart = (props?: TUseDeleteCart) => {
    const { mutateAsync, ...rest } = useMutation(
        cartKeys.deleteCart.queryKey,
        () =>
            cartApi.deleteV1Cart({
                headers: {
                    Authorization: 'Bearer',
                },
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(cartKeys.getCart.queryKey);
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    return { ...rest, deleteCart: mutateAsync };
};
