import { Components, Theme } from '@mui/material';

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiMenu-paper': {
                boxShadow: 'none',
                backgroundColor: theme.palette.bg.primary,
            },
            '.MuiMenu-paper:hover': {
                backgroundColor: theme.palette.bg.primary,
            },
        }),
    },
};
