type TProps = {
    size?: number;
};

export const CheckerIcon = ({ size = 24 }: TProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.46447 1.46447C0 2.92893 0 5.28595 0 10C0 14.714 0 17.0711 1.46447 18.5355C2.92893 20 5.28595 20 10 20C14.714 20 17.0711 20 18.5355 18.5355C20 17.0711 20 14.714 20 10C20 5.28595 20 2.92893 18.5355 1.46447C17.0711 0 14.714 0 10 0C5.28595 0 2.92893 0 1.46447 1.46447ZM8.08035 15L17 6.53658L15.3262 5L8.08035 11.8752L4.67384 8.64292L3 10.1795L8.08035 15Z"
                fill="currentColor"
            />
        </svg>
    );
};
