import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { ENV } from '@shared/config/constants';
import { useNavigateExternal } from '@shared/hooks';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';
import { THeaderPaths } from '@widgets/header/types';

import { Help } from './help';

type TProps = {
    headerPaths: THeaderPaths;
};

export const HelpConnector = ({ headerPaths }: TProps) => {
    const navigateExt = useNavigateExternal();
    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);

    return (
        <PageTemplate
            variant="big"
            withHeadSpace
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    paths={headerPaths}
                    withProfile>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        Вопросы и ответы
                    </Typography>
                </HeaderWidgetConnector>
            }>
            <Help onSupportClick={handleSupportClick} />
        </PageTemplate>
    );
};
