import { Box, styled } from '@mui/material';

import { TAdd } from '@entities/adds/types';
import { selectors } from '@shared/test/selectors';

type TProps = {
    border?: 'square' | 'rounded';
    onClick: () => void;
} & TAdd;

const Link = styled('a')``;

export const AddBlock = ({
    id,
    width,
    imageUrl,
    href,
    aspectRatio,
    border = 'rounded',
    onClick,
}: TProps) => {
    return (
        <Link
            href={href}
            target="_blank"
            rel="noreferrer"
            data-testid={selectors.addCard(id)}
            sx={{ width: width }}
            onClick={onClick}>
            <Box
                component="img"
                src={imageUrl}
                width="100%"
                borderRadius={border === 'rounded' ? '20px' : 0}
                sx={{ aspectRatio: aspectRatio }}
            />
        </Link>
    );
};
