import { TFormProvider } from '@entities/providers/types';
import { ProviderItem } from '@shared/api/axios-client';

export const mapProvider = (providers?: ProviderItem[]): TFormProvider[] => {
    if (!providers) {
        return [];
    }
    return providers.map(provider => ({
        label: provider.name,
        id: provider.id,
    }));
};
