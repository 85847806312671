import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    useChangeProviderCredential,
    useGetAvailableProviders,
} from '@entities/providers/model';
import { TProvider } from '@entities/providers/types';
import { useGetUserProviders } from '@entities/user/model';
import { useDeleteUserProvider } from '@entities/user/model/hooks/use-delete-user-provider';
import { TFormData } from '@features/form-provider-change';
import { ENV } from '@shared/config/constants';
import {
    useNavigateExternal,
    useStateModal,
    useYandexReachGoal,
    useYandexUserParams,
} from '@shared/hooks';
import { DoneIcon } from '@shared/ui/icons';
import { Dialog } from '@shared/ui/organisms/dialog';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { ChangeProvidersPage } from './change-providers-page';
import { mapDefaultValues, mapProvider } from './mappers';
import { ChangeProvidersPageHeader } from './ui/change-providers-page-header';
import { EmptyProviders } from './ui/empty-providers';
import { RemoveModal } from './ui/remove-modal';

type TProps = {
    externalRoutes: {
        catalogHomePath: string;
        providerHomePath: string;
    };
    headerPaths: THeaderPaths;
};

export const ChangeProvidersPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const navigate = useNavigate();
    const navigateExt = useNavigateExternal();
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();

    const [changeableProvider, setChangeableProvider] = useState<TProvider>();
    const [lastCredential, setLastCredential] = useState<TFormData>();
    const { open: openNoProviderDialog, isOpen: isNoProviderDialog } =
        useStateModal();
    const { open, isOpen, close } = useStateModal();
    const enqueueErrorSnackbar = useCallback(
        () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
        [enqueueSnackbar],
    );
    const { data: userProvidersData, isLoading: isProvidersLoading } =
        useGetUserProviders({
            onSuccess: data => {
                changeUserParams({
                    suppliers: data.data.provider?.length,
                });
            },
            onError: enqueueErrorSnackbar,
        });
    const { data: availableProvidersData } = useGetAvailableProviders({
        onError: enqueueErrorSnackbar,
    });
    const {
        changeProviderCredential,
        isLoading: isChangeLoading,
        isError: isChangeError,
        isSuccess: isChangeSuccess,
        reset: resetChangeProvider,
    } = useChangeProviderCredential({
        onSuccess: () =>
            setTimeout(() => {
                resetChangeProvider();
                setChangeableProvider(undefined);
            }, 2000),
        onError: enqueueErrorSnackbar,
    });
    const {
        deleteUserProvider,
        isSuccess: isDeleteProviderSuccess,
        reset: resetDeleteUserProvider,
    } = useDeleteUserProvider({
        onSuccess: () => {
            close();
            setTimeout(() => {
                setChangeableProvider(undefined);
                resetDeleteUserProvider();
            }, 2000);
        },
        onError: () => {
            close();
            setChangeableProvider(undefined);
            resetDeleteUserProvider();
            enqueueErrorSnackbar();
        },
    });
    const defaultValues =
        changeableProvider &&
        mapDefaultValues(
            userProvidersData?.data.provider?.find(
                provider => provider.id === changeableProvider.id,
            ),
        );
    const handleSubmitChange = useCallback(
        (data: TFormData) => {
            if (JSON.stringify(data) === JSON.stringify(defaultValues)) {
                enqueueSnackbar('Вы не внесли изменения', {
                    variant: 'info',
                });
                return;
            }
            setLastCredential(data);
            if (!changeableProvider) {
                return;
            }
            changeProviderCredential({
                providerId: changeableProvider?.id,
                connectAttribute: changeableProvider?.credentialFields.map(
                    credField => ({
                        attribute: credField.code,
                        value: data[credField.code],
                        label: '',
                    }),
                ),
            });
        },
        [
            changeProviderCredential,
            changeableProvider,
            defaultValues,
            enqueueSnackbar,
        ],
    );
    const handleAddProvider = useCallback(() => {
        fireProductEvent('addSupplierInProfile');
        if (availableProvidersData?.data.providers.length === 0) {
            fireProductEvent('notFoundNewSuppliers');
            openNoProviderDialog();
            return;
        }
        navigate(externalRoutes.providerHomePath);
    }, [
        availableProvidersData?.data.providers.length,
        externalRoutes.providerHomePath,
        fireProductEvent,
        navigate,
        openNoProviderDialog,
    ]);
    const handleRemoveProvider = useCallback(() => {
        fireProductEvent('deleteSupplier');
        deleteUserProvider({ providerId: changeableProvider?.id || '' });
    }, [changeableProvider?.id, deleteUserProvider, fireProductEvent]);

    const handleSkipError = useCallback(() => {
        close();
        setChangeableProvider(undefined);
        resetChangeProvider();
    }, [close, resetChangeProvider]);
    const handleRetryError = useCallback(() => {
        if (!changeableProvider || !lastCredential) {
            handleSkipError();
            return;
        }
        changeProviderCredential({
            providerId: changeableProvider?.id,
            connectAttribute: changeableProvider?.credentialFields.map(
                credField => ({
                    attribute: credField.code,
                    value: lastCredential[credField.code],
                    label: '',
                }),
            ),
        });
    }, [
        changeProviderCredential,
        changeableProvider,
        handleSkipError,
        lastCredential,
    ]);
    const handleProposition = useCallback(() => {
        navigateExt(ENV.yandexPropositionForm);
    }, [navigateExt]);
    const handleNext = useCallback(async () => {
        navigate(externalRoutes.providerHomePath);
    }, [externalRoutes.providerHomePath, navigate]);
    const handleBack = useCallback(() => {
        setChangeableProvider(undefined);
    }, []);

    if (isNoProviderDialog) {
        return (
            <Dialog
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="К сожалению, это пока всё — у нас закончились поставщики"
                helpedText="Но скоро добавим новых"
                variant="info"
                primaryButtonText="Хорошо"
                secondaryButtonText="предложить поставщиков"
                onPrimaryButtonClick={handleNext}
                onSecondaryButtonClick={handleProposition}
            />
        );
    }

    if (isChangeError) {
        return (
            <Dialog
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="не удалось изменить поставщика"
                helpedText="попробуйте позже"
                variant="error"
                primaryButtonText="еще раз"
                secondaryButtonText="пропустить"
                onPrimaryButtonClick={handleRetryError}
                onSecondaryButtonClick={handleSkipError}
            />
        );
    }

    if (isChangeLoading) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="один момент..."
                CenterSlot={
                    <Box color={theme => theme.palette.common.white}>
                        <CircularProgress color="inherit" size="80px" />
                    </Box>
                }
            />
        );
    }

    if (isChangeSuccess) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title={`поставщик успешно изменен`}
                CenterSlot={
                    <Box color={theme => theme.palette.common.white}>
                        <DoneIcon />
                    </Box>
                }
            />
        );
    }

    if (isDeleteProviderSuccess) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title={`поставщик ${changeableProvider?.title} был удален`}
                CenterSlot={
                    <Box color={theme => theme.palette.common.white}>
                        <DoneIcon />
                    </Box>
                }
            />
        );
    }
    const providers = mapProvider(userProvidersData?.data.provider || []);
    return (
        <PageTemplate
            withHeadSpace
            variant="big"
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    withProfile
                    paths={headerPaths}>
                    <ChangeProvidersPageHeader
                        title={
                            changeableProvider
                                ? changeableProvider.title
                                : 'Поставщики'
                        }
                        withDescription={Boolean(changeableProvider)}
                        counter={userProvidersData?.data.provider?.length}
                        description="для изменения номера поставщика, нужно быть предварительно зарегистрированным в системе поставщика и иметь ключ доступа"
                    />
                </HeaderWidgetConnector>
            }>
            {providers.length > 0 || isProvidersLoading ? (
                <ChangeProvidersPage
                    providers={providers}
                    changeableProvider={changeableProvider}
                    defaultValues={defaultValues}
                    instantOrderAvailable={
                        changeableProvider?.instantOrderAvailable
                    }
                    onChange={setChangeableProvider}
                    onAddProvider={handleAddProvider}
                    onRemoveProvider={open}
                    onSubmitChange={handleSubmitChange}
                    onBack={handleBack}
                />
            ) : (
                <EmptyProviders
                    onPrimaryButtonClick={handleAddProvider}
                    onSecondaryButtonClick={() =>
                        navigate(externalRoutes.catalogHomePath)
                    }
                />
            )}
            <RemoveModal
                isOpen={isOpen}
                providerTitle={changeableProvider?.title}
                onClose={close}
                onRemove={handleRemoveProvider}
            />
        </PageTemplate>
    );
};
