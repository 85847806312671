import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useChangeProvidersCredential } from '@entities/providers/model';
import { useGetAvailableProviders } from '@entities/providers/model/hooks';
import { TFormProvider, TProvider } from '@entities/providers/types';
import { ProviderCredentialModal } from '@entities/providers/ui/provider-credential-modal';
import { useGetUserProviders } from '@entities/user/model';
import {
    FormProviderCredentialProvider,
    TFormData,
} from '@features/form-provider-credential';
import { TFormData as TFormDataProviderSelect } from '@features/form-provider-select';
import { ENV } from '@shared/config/constants';
import {
    useNavigateExternal,
    useQueryParams,
    useStateModal,
    useYandexReachGoal,
    useYandexUserParams,
} from '@shared/hooks';
import { InfoModal } from '@shared/ui/organisms/info-modal';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { ProviderPage } from './provider-page';
import { ProviderHeader } from './ui/provider-header';
import { getInfoData } from './utils/lib';
import {
    mapInfoCenter,
    mapProvider,
    mapProviderCredential,
} from './utils/mappers';

type TProps = {
    externalRoutes: {
        catalogHomePath: string;
        accountProviderPath: string;
    };
    headerPaths: THeaderPaths;
};

export type TProvidersData = {
    [key: string]: TFormData;
};

export const ProviderPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const navigate = useNavigate();
    const navigateExt = useNavigateExternal();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();
    const { state } = useLocation();
    const { params } = useQueryParams();
    const isFromAuth = state?.fromAuth || params.get('fromAuth');
    const { enqueueSnackbar } = useSnackbar();
    const [modalProvider, setModalProvider] = useState<TProvider | undefined>();
    const [selectedProviders, setSelectedProviders] = useState<TFormProvider[]>(
        [],
    );
    const [providersData, setProvidersData] = useState<TProvidersData>({});
    const {
        isOpen: isInfoModal,
        close: closeInfoModal,
        open: openInfoModal,
    } = useStateModal();

    const { data: userProvidersData, isLoading: isUserProvidersLoading } =
        useGetUserProviders({
            onSuccess: () => {
                changeUserParams({
                    suppliers: userProvidersData?.data.provider?.length,
                });
            },
            onError: () => {
                enqueueSnackbar('что-то пошло не так', {
                    variant: 'error',
                    description: 'обновите страницу или попробуйте позже',
                });
            },
        });
    const isUserProviders = Boolean(userProvidersData?.data.provider?.length);
    const isRedirectToHome = isFromAuth && isUserProviders;

    const { data: availableProvidersData, isLoading: isProvidersLoading } =
        useGetAvailableProviders({
            isDisabled: isRedirectToHome || isUserProvidersLoading,
            onError: () => {
                enqueueSnackbar('что-то пошло не так', {
                    variant: 'error',
                    description: 'обновите страницу или попробуйте позже',
                });
            },
        });
    const { changeProvidersCredential, isLoading, isSuccess } =
        useChangeProvidersCredential({
            onSuccess: async () => {
                changeUserParams({
                    suppliers:
                        (userProvidersData?.data.provider?.length || 0) +
                        Object.keys(providersData).length,
                });
            },
            onError: () => {
                enqueueSnackbar('что-то пошло не так', {
                    variant: 'error',
                    description: 'попробуйте повторить запрос',
                });
            },
        });

    const mappedProviders = useMemo(
        () => mapProvider(availableProvidersData?.data.providers),
        [availableProvidersData?.data.providers],
    );
    const handleSubmit = useCallback(() => {
        const selectedIdsLength = selectedProviders.map(item => item.id).length;
        fireProductEvent('addSuppliers', {
            total: selectedIdsLength,
        });

        const providerList = providersData ? Object.keys(providersData) : [];
        if (selectedIdsLength === 0 || (isInfoModal && !providerList.length)) {
            navigate(externalRoutes.catalogHomePath);
            return;
        }
        if (!isInfoModal && selectedIdsLength !== providerList.length) {
            fireProductEvent('pushAddDataSuppliers');
            openInfoModal();
            return;
        }
        changeProvidersCredential({
            providersCretential: mapProviderCredential(providersData),
        });
    }, [
        changeProvidersCredential,
        externalRoutes.catalogHomePath,
        fireProductEvent,
        isInfoModal,
        navigate,
        openInfoModal,
        providersData,
        selectedProviders,
    ]);

    const handleChange = useCallback(
        (data: TFormDataProviderSelect) => {
            setSelectedProviders(
                mappedProviders.filter(item =>
                    data.providers.includes(item.id),
                ),
            );
        },
        [mappedProviders],
    );

    const handleCardProviderClick = useCallback(
        (id: string) => {
            setModalProvider(() => {
                const provider = availableProvidersData?.data.providers?.find(
                    provider => provider.id === id,
                );
                return {
                    id: id,
                    title: provider?.name || '',
                    instantOrderAvailable: provider?.instantOrderAvailable,
                    credentialFields:
                        provider?.connectAttribute?.map(item => ({
                            label: item.label,
                            code: item.attribute,
                            isRequired: true,
                        })) || [],
                };
            });
        },
        [availableProvidersData?.data.providers],
    );

    const handlePropositionClick = useCallback(() => {
        navigateExt(ENV.yandexPropositionForm);
    }, [navigateExt]);

    const handleSkipClick = useCallback(() => {
        navigate(externalRoutes.catalogHomePath);
    }, [externalRoutes.catalogHomePath, navigate]);

    const handleSaveCredential = useCallback(
        (data: TFormData, id: string) => {
            fireProductEvent('addSupplierCredentials', { supplier: id });
            setProvidersData(prev => {
                return { ...prev, [id]: data };
            });
            setModalProvider(undefined);
        },
        [fireProductEvent],
    );

    const handleCloseModal = useCallback(() => {
        setModalProvider(undefined);
    }, []);

    const handleRemoveProvider = useCallback((id: string) => {
        setSelectedProviders(prev => prev?.filter(item => item.id !== id));
        setProvidersData(prev => {
            const providers = Object.keys(prev);
            return providers.reduce<TProvidersData>((acc, provider) => {
                if (provider === id) {
                    return acc;
                } else {
                    return { ...acc, [provider]: prev[provider] };
                }
            }, {});
        });
        setModalProvider(undefined);
    }, []);

    if (isRedirectToHome && !isUserProvidersLoading) {
        return <Navigate to={externalRoutes.catalogHomePath} replace />;
    }

    if (isProvidersLoading || isUserProvidersLoading) {
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title="один момент..."
                CenterSlot={
                    <CircularProgress
                        sx={theme => ({ color: theme.palette.common.white })}
                        size="80px"
                    />
                }
            />
        );
    }

    if (isLoading || isSuccess) {
        const infoData = getInfoData();
        setTimeout(() => {
            navigate(externalRoutes.catalogHomePath);
        }, 2000);
        const status = isSuccess ? 'ok' : 'loading';
        return (
            <InfoTemplate
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="secondary"
                        paths={headerPaths}
                    />
                }
                title={infoData[status].title}
                helpedText={infoData[status].helpedText}
                CenterSlot={mapInfoCenter(status)}
            />
        );
    }

    if (mappedProviders.length === 0) {
        return <Navigate to={externalRoutes.accountProviderPath} replace />;
    }

    return (
        <>
            <PageTemplate
                withHeadSpace
                variant="big"
                Header={
                    <HeaderWidgetConnector
                        withLogo
                        variant="primary"
                        paths={headerPaths}
                        withProfile={!isFromAuth}>
                        <ProviderHeader />
                    </HeaderWidgetConnector>
                }>
                <ProviderPage
                    dirtyProviders={Object.keys(providersData || {})}
                    providers={mappedProviders}
                    selectedProviders={selectedProviders}
                    setSelectedValues={setSelectedProviders}
                    isAddProvider={!isFromAuth}
                    onCardClick={handleCardProviderClick}
                    onChange={handleChange}
                    onSkip={handleSkipClick}
                    onSubmit={handleSubmit}
                    onProposition={handlePropositionClick}
                />
            </PageTemplate>
            {modalProvider && (
                <ProviderCredentialModal
                    open={Boolean(modalProvider)}
                    onClose={handleCloseModal}
                    provider={modalProvider}
                    FormComponent={
                        <FormProviderCredentialProvider
                            fields={modalProvider.credentialFields}
                            defaultValues={providersData[modalProvider.id]}
                            onSubmit={(data: TFormData) =>
                                handleSaveCredential(data, modalProvider.id)
                            }
                            onProviderRemove={() =>
                                handleRemoveProvider(modalProvider.id)
                            }
                        />
                    }
                />
            )}
            <InfoModal
                withHeader
                withCloseIcon={false}
                variant="question"
                title="Данные были внесены не по всем поставщикам. Продолжить? "
                description="Вы сможете добавить их позже в своем профиле"
                primaryButtonText="продолжить"
                secondaryButtonText="вернуться"
                open={isInfoModal}
                onClose={closeInfoModal}
                onPrimaryButtonClick={handleSubmit}
                onSecondaryButtonClick={closeInfoModal}
            />
        </>
    );
};
