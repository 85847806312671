import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { TCartItem } from '@entities/cart/types';
import { formatCurrency } from '@shared/lib/utils/number';
import { selectors } from '@shared/test/selectors';

type TProps = {
    onRemove: () => void;
    FormComponent: ReactNode;
} & TCartItem;

export const CartItemCard = ({
    article,
    title,
    deliveryDate,
    price,
    amount,
    FormComponent,
    onRemove,
}: TProps) => {
    const isOneItem = amount === 1;
    return (
        <Stack direction="column" padding="15px" gap="15px">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" gap="5px">
                    <Typography
                        variant="h5"
                        color={theme => theme.palette.typography.primaryInvert}
                        sx={{ wordBreak: 'break-word' }}>
                        арт. {article}
                    </Typography>
                    <Typography
                        variant="caption1"
                        color={theme =>
                            theme.palette.typography.secondaryInvert
                        }
                        sx={{ wordBreak: 'break-word' }}>
                        {title}
                    </Typography>
                    <Typography
                        display="flex"
                        gap="4px"
                        variant="caption1"
                        color={theme => theme.palette.typography.primaryInvert}>
                        Срок:{' '}
                        {deliveryDate ? (
                            deliveryDate
                        ) : (
                            <>
                                {'Нет данных'}
                                <Tooltip
                                    arrow
                                    title="Сроки отгрузки и доставки уточняйте у поставщика"
                                    data-testid={selectors.cartItemCardTooltip}>
                                    <InfoOutlinedIcon
                                        sx={theme => ({
                                            fontSize: '16px',
                                            color: theme.palette.icon
                                                .secondaryInvert,
                                        })}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </Typography>
                </Stack>
                <Stack minWidth="140px" alignItems="flex-end">
                    <Typography
                        variant="h5"
                        color={theme => theme.palette.typography.primaryInvert}>
                        {formatCurrency(isOneItem ? price : price * amount)}
                    </Typography>
                    {!isOneItem && (
                        <Typography
                            variant="caption1"
                            color={theme =>
                                theme.palette.typography.secondaryInvert
                            }>
                            {formatCurrency(price)} / шт
                        </Typography>
                    )}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Box>
                    <Button
                        variant="text"
                        size="small"
                        color="buttonPrimaryInvert"
                        onClick={onRemove}
                        startIcon={<ClearIcon />}
                        data-testid={selectors.cartItemCardRemoveButton}>
                        удалить
                    </Button>
                </Box>
                <Box>{FormComponent}</Box>
            </Stack>
        </Stack>
    );
};
