import { Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { TProductTableField } from '@entities/products/types';
import { ProductItemField } from '@entities/products/ui/product-item-field';
import { selectors } from '@shared/test/selectors';

type TProps = {
    fields: TProductTableField[];
    Control?: ReactNode;
    isHighlighted?: boolean;
    onClick?: () => void;
};

export const ProductItem = ({
    fields,
    Control,
    isHighlighted = false,
    onClick,
}: TProps) => {
    return (
        <Grid
            container
            width="100%"
            minWidth="1300px"
            direction="row"
            alignItems="center"
            borderBottom={theme => `1px solid ${theme.palette.border.border4}`}
            data-testid={selectors.productsProductItem}
            onClick={onClick}
            sx={theme => ({
                cursor: onClick ? 'pointer' : 'default',
                backgroundColor: isHighlighted ? '#FCE00080' : 'inherit',
                borderLeft: isHighlighted
                    ? `2px solid ${theme.palette.border.border1}`
                    : 'inherit',
                borderRight: isHighlighted
                    ? `2px solid ${theme.palette.border.border1}`
                    : 'inherit',
            })}>
            {fields.map((field, index) => (
                <ProductItemField {...field} key={index} />
            ))}
            <Grid item xs={1}>
                <Stack alignItems="flex-end">{Control}</Stack>
            </Grid>
        </Grid>
    );
};
