import {
    ConnectAttributeRequest,
    V1ProvidersCredentialRequest,
} from '@shared/api/axios-client';

import { TProvidersData } from '../../provider-page-connector';

export const mapProviderCredential = (
    providersData: TProvidersData,
): V1ProvidersCredentialRequest['providersCretential'] => {
    return Object.keys(providersData).map(key => {
        const connectAttributes: ConnectAttributeRequest[] = Object.keys(
            providersData[key],
        ).map(attributeKey => ({
            attribute: attributeKey,
            value: providersData[key][attributeKey],
            label: '',
        }));
        return {
            providerId: key,
            connectAttribute: connectAttributes,
        };
    });
};
