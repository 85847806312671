import { TFavoriteItem } from '@entities/favorite/types';
import { Product } from '@shared/api/axios-client';

export const mapFavorite = (products?: Product[]): TFavoriteItem[] => {
    return products
        ? products.map(product => ({
              id: product.id,
              brand: product.brand,
              vendorCode: product.vendorCode,
              productName: product.name,
          }))
        : [];
};
