import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, cartApi, queryClient } from '@shared/api';
import { CartApiDeleteV1CartProductGroupRequest } from '@shared/api/axios-client';

import { cartKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseDeleteCartGroup = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useDeleteCartGroup = (props?: TUseDeleteCartGroup) => {
    const { mutateAsync, ...rest } = useMutation(
        cartKeys.deleteCartGroup.queryKey,
        (
            cartApiDeleteV1CartProductGroupRequest: CartApiDeleteV1CartProductGroupRequest,
        ) => {
            return cartApi.deleteV1CartProductGroup(
                cartApiDeleteV1CartProductGroupRequest,
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(cartKeys.getCart.queryKey);
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    const deleteCartGroup = useCallback(
        (
            cartApiDeleteV1CartProductGroupRequest: CartApiDeleteV1CartProductGroupRequest,
        ) => {
            return mutateAsync(cartApiDeleteV1CartProductGroupRequest);
        },
        [mutateAsync],
    );

    return { ...rest, deleteCartGroup };
};
