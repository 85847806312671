import { RouteObject } from 'react-router-dom';

import { THeaderPaths } from '@widgets/header';

import { LogoutPageConnector } from './logout-page-connector';
import { logoutPaths } from './paths';

type TExternalRoutes = {
    authHomePath: string;
    headerPaths: THeaderPaths;
};

export const logoutRoutes = ({
    authHomePath,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: logoutPaths.home.absolute,
        element: (
            <LogoutPageConnector
                externalRoutes={{ authHomePath }}
                headerPaths={headerPaths}
            />
        ),
    },
];
