import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    Box,
    Stack,
    TextField,
    Typography,
    createFilterOptions,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { selectors } from '@shared/test/selectors';

type TProps = {
    options: string[];
    placeholder: string;
    onAutocomplete: () => void;
};

export type TFormData = {
    search: string;
};

export const FormSearchHeader = ({
    options,
    placeholder,
    onAutocomplete,
}: TProps) => {
    const [isBlur, setIsBlur] = useState(false);
    const {
        control,
        formState: { isSubmitSuccessful },
    } = useFormContext<TFormData>();
    const filterOptions = createFilterOptions<string>({
        matchFrom: 'start',
        limit: 5,
    });
    useEffect(() => {
        if (isSubmitSuccessful) {
            setIsBlur(false);
        }
    }, [isSubmitSuccessful]);
    return (
        <>
            <Box
                zIndex="100"
                position="relative"
                borderRadius="24px"
                sx={theme => ({
                    background: isBlur
                        ? theme.palette.bg.primary
                        : 'transparent',
                })}>
                <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            data-testid={selectors.formSearchHeaderAutocomplete}
                            freeSolo
                            disablePortal
                            filterSelectedOptions
                            clearIcon={false}
                            open={isBlur}
                            options={options}
                            filterOptions={filterOptions}
                            {...field}
                            onFocus={() => setIsBlur(true)}
                            onBlur={() => {
                                field.onBlur();
                                setIsBlur(false);
                            }}
                            onChange={(_, data) => {
                                field.onChange(data);
                                onAutocomplete();
                            }}
                            sx={{
                                '.MuiAutocomplete-inputRoot': {
                                    padding: '0 !important',
                                },
                                position: 'static',
                                borderRadius: '24px',
                            }}
                            componentsProps={{
                                popper: {
                                    sx: {
                                        transform:
                                            'translate3d(0px, 56px, 0px) !important',
                                        width: '100% !important',
                                    },
                                },
                                paper: {
                                    sx: {
                                        borderRadius: '0 0 24px 24px',
                                        boxShadow: 'none',
                                        '.MuiAutocomplete-listbox': {
                                            margin: '0 20px',
                                        },
                                        '.MuiAutocomplete-option': {
                                            padding: '0 !important',
                                        },
                                    },
                                },
                            }}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    data-testid={selectors.formSearchHeaderAutocompleteOption(
                                        option,
                                    )}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="20px"
                                        width="100%"
                                        color={theme =>
                                            theme.palette.icon.secondary
                                        }
                                        sx={theme => ({
                                            '&:hover > svg': {
                                                color: theme.palette.border
                                                    .border1,
                                            },
                                        })}>
                                        <HistoryIcon fontSize="large" />
                                        <Box width="100%" paddingY="20px">
                                            <Typography variant="subtitle1">
                                                {option}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </li>
                            )}
                            renderInput={params => (
                                <TextField
                                    data-testid={
                                        selectors.formSearchHeaderAutocompleteInput
                                    }
                                    {...params}
                                    placeholder={placeholder}
                                    onChange={data => {
                                        field.onChange(data);
                                    }}
                                    autoComplete="off"
                                    variant="standard"
                                    helperText={' '}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        autoComplete: 'off',
                                        sx: {
                                            borderBottom: theme =>
                                                `1px solid ${theme.palette.border.border5}`,
                                        },
                                        fullWidth: true,
                                        startAdornment: (
                                            <SearchIcon
                                                fontSize="large"
                                                sx={{ paddingTop: '5px' }}
                                            />
                                        ),
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </Box>
            <Stack
                position="absolute"
                top={0}
                left={0}
                width="100dvw"
                height="100dvh"
                sx={theme => ({ background: theme.palette.other.backdrop })}
                visibility={isBlur ? 'visible' : 'hidden'}
                zIndex={2}
            />
        </>
    );
};
