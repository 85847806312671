import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { TCredentialField } from '@entities/providers/types';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    fields: TCredentialField[];
    onSecondaryButtonClick: () => void;
};

export type TFormData = {
    [key: string]: string;
};

export const FormProviderChange = ({
    fields,
    onSecondaryButtonClick,
}: TProps) => {
    const { formState, control, setValue } = useFormContext<TFormData>();
    const theme = useTheme();
    return (
        <Stack direction="column" gap="40px">
            {fields.map(item => (
                <Controller
                    control={control}
                    key={item.code}
                    name={item.code}
                    render={({ field }) => (
                        <TextField
                            data-testid={selectors.formProviderChangeInput}
                            label={item.label}
                            key={item.code}
                            variant="standard"
                            InputLabelProps={{
                                style: {
                                    color: formState.errors[item.code]
                                        ? theme.palette.typography.negative
                                        : theme.palette.typography.secondary,
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <Conditional
                                            condition={Boolean(
                                                formState.errors[item.code],
                                            )}>
                                            <ErrorOutlineIcon fontSize="large" />
                                        </Conditional>
                                        <Conditional
                                            condition={Boolean(field.value)}>
                                            <IconButton
                                                data-testid={
                                                    selectors.formProviderChangeClearButton
                                                }
                                                onClick={() =>
                                                    setValue(item.code, '')
                                                }
                                                color="buttonSecondaryText">
                                                <CancelIcon fontSize="large" />
                                            </IconButton>
                                        </Conditional>
                                    </>
                                ),
                            }}
                            error={Boolean(formState.errors[item.code])}
                            helperText={
                                formState.errors[item.code]?.message || ' '
                            }
                            {...field}
                        />
                    )}
                />
            ))}
            <Stack direction={{ xs: 'column', md: 'row' }} gap="16px">
                <Button
                    data-testid={selectors.formProviderChangeSubmitButton}
                    variant="contained"
                    color="buttonPrimary"
                    type="submit">
                    изменить
                </Button>
                <Button
                    data-testid={selectors.formProviderChangeCancelButton}
                    variant="contained"
                    color="buttonSecondaryEnabled"
                    onClick={onSecondaryButtonClick}>
                    отменить
                </Button>
            </Stack>
        </Stack>
    );
};
