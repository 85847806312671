import { Box, Modal } from '@mui/material';

import { selectors } from '@shared/test/selectors';

import { NewsCardDetails } from '../news-card-details';

type TProps = {
    open: boolean;
    title: string;
    imgUrl: string;
    buttonText: string;
    text: string;
    onClick: () => void;
    onClose: () => void;
};

export const NewsModal = ({
    open,
    title,
    imgUrl,
    buttonText,
    text,
    onClick,
    onClose,
}: TProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                data-testid={selectors.authCompanyAuthModal}
                position="absolute"
                top="50%"
                left="50%"
                sx={{ transform: 'translate(-50%, -50%)' }}
                display="flex"
                justifyContent="center"
                width={{ xs: '100%', md: 'auto' }}>
                <NewsCardDetails
                    title={title}
                    imgUrl={imgUrl}
                    text={text}
                    buttonText={buttonText}
                    onClick={onClick}
                    onClose={onClose}
                />
            </Box>
        </Modal>
    );
};
