import { Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TFormProvider } from '@entities/providers/types';
import { CheckboxAutocomplete } from '@shared/ui/molecules/checkbox-autocomplete';

export type TFormData = {
    providers: string[];
};

type TProps = {
    providers: TFormProvider[];
    selectedValues: TFormProvider[];
    setSelectedValues: Dispatch<SetStateAction<TFormProvider[]>>;
};

export const FormProviderSelect = ({
    providers,
    selectedValues,
    setSelectedValues,
}: TProps) => {
    const { control } = useFormContext<TFormData>();
    return (
        <Stack direction="column" gap="40px">
            <Controller
                name="providers"
                control={control}
                render={({ fieldState }) => (
                    <CheckboxAutocomplete
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                        placeholder="выберите поставщиков"
                        values={providers}
                        allLabel="Выбрать всех"
                        error={fieldState.error}
                    />
                )}
            />
        </Stack>
    );
};
