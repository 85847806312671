import { Button, Stack, Typography, styled } from '@mui/material';

import type { TActiveItem, TFeatureItem } from '../../../types';
import { FeatureItem } from '../../atoms';

const Grid = styled('div')`
    display: grid;
    gap: 16px;
`;

type Props = {
    items: TFeatureItem[];
    onItemClick: ({ name, value }: TActiveItem) => void;
    onClose: () => void;
    onApplyFlags: () => void;
};

export const SettingsModal = ({
    items,
    onItemClick,
    onClose,
    onApplyFlags,
}: Props) => {
    return (
        <Stack
            width={{ xs: '100%', md: '620px' }}
            maxWidth="620px"
            height="100%">
            <Typography variant="h2" marginBottom="16px">
                Список фичефлагов{' '}
            </Typography>
            <Stack overflow="scroll" height="100%">
                <Grid>
                    {items.map(item => (
                        <FeatureItem
                            {...item}
                            onPress={() =>
                                onItemClick({
                                    name: item.value,
                                    value: !item.isActive,
                                })
                            }
                            key={item.value}
                        />
                    ))}
                </Grid>
            </Stack>

            <Stack
                direction="row"
                justifyContent="flex-end"
                padding="24px 16px 24px 24px">
                <Button
                    variant="contained"
                    color="buttonPrimary"
                    id="confirm_feature_toggles_button"
                    onClick={onApplyFlags}>
                    Применить флаги
                </Button>
            </Stack>
        </Stack>
    );
};
