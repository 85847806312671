import { Stack, Switch, Typography } from '@mui/material';

type Props = {
    title: string;
    value: string;
    isActive: boolean;
    onPress: () => void;
};

export const FeatureItem = ({ title, value, isActive, onPress }: Props) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                mr="16px"
                alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Typography
                    mr={{ xs: 0, md: '8px' }}
                    mb={{ xs: '4px', md: 0 }}
                    variant="body2"
                    sx={{ '&:first-letter': { textTransform: 'uppercase' } }}>
                    {title}
                </Typography>
                <Typography variant="caption">({value})</Typography>
            </Stack>

            <Switch
                id={value + '_button'}
                checked={isActive}
                onChange={onPress}
            />
        </Stack>
    );
};
