import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header/types';

import { BrandPageConnector } from './brand';
import { CatalogPageConnector } from './catalog';
import { catalogPaths } from './paths';
import { SearchPageConnector } from './search';

type TExternalRoutes = {
    providerHomePath: string;
    accountProviderPath: string;
    headerPaths: THeaderPaths;
};

export const catalogRoutes = ({
    providerHomePath,
    accountProviderPath,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: catalogPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Поиск</MetaTitle>
                <SearchPageConnector
                    externalRoutes={{
                        providerHomePath,
                    }}
                    headerPaths={headerPaths}
                />
            </>
        ),
    },
    {
        path: catalogPaths.catalog.absolute,
        element: (
            <>
                <MetaTitle>Каталог</MetaTitle>
                <CatalogPageConnector
                    externalRoutes={{ providerHomePath, accountProviderPath }}
                    headerPaths={headerPaths}
                />
            </>
        ),
    },
    {
        path: catalogPaths.brand.absolute,
        element: (
            <>
                <MetaTitle>Бренды</MetaTitle>
                <BrandPageConnector
                    externalRoutes={{ providerHomePath, accountProviderPath }}
                    headerPaths={headerPaths}
                />
            </>
        ),
    },
];
