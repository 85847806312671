import { TFormData } from '@features/form-provider-change/form-provider-change';
import { Provider } from '@shared/api/axios-client';

export const mapDefaultValues = (provider?: Provider): TFormData => {
    if (!provider) {
        return {};
    }
    return (
        provider.connectAttribute?.reduce(
            (acc, attribute) => ({
                ...acc,
                [attribute.attribute]: attribute.value,
            }),
            {},
        ) || []
    );
};
