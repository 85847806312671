import { AdvertisingBanner } from '@shared/api/axios-client';
import { TEcommercePromotion } from '@shared/hooks/use-yandex-ecommerce';

export const mapEcommerceAddPromo = (
    add: AdvertisingBanner[],
    userId: string,
    position: 'search' | 'fav' | 'results',
): TEcommercePromotion[] => {
    return add.map(item => ({
        id: 'adv' as const,
        creative: item.id,
        position: position,
        creative_slot: userId,
    }));
};
