import { Box, CircularProgress } from '@mui/material';

import { DoneIcon } from '@shared/ui/icons';

export const mapInfoCenter = (status: 'ok' | 'loading') => {
    switch (status) {
        case 'ok':
            return (
                <Box color={theme => theme.palette.common.white}>
                    <DoneIcon />
                </Box>
            );
        case 'loading':
            return (
                <Box color={theme => theme.palette.common.white}>
                    <CircularProgress color="inherit" size="80px" />
                </Box>
            );
        default:
            break;
    }
};
