import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useUnit } from 'effector-react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { $userInfo } from '@entities/auth/model/auth-domain';
import { mapUserInfo } from '@entities/auth/utils/mappers';
import { $cartAmount } from '@entities/cart/model/cart-domain';
import { $favoriteAmount } from '@entities/favorite/favorite-domain';
import { useGetPriceList } from '@entities/products/model';
import { useLogout } from '@entities/user/model';
import { FeatureToggle } from '@features/feature-toggle';
import { updateModalState } from '@features/feature-toggle/feature-toggle-domain';
import { useStateModal, useYandexReachGoal } from '@shared/hooks';
import { InfoModal } from '@shared/ui/organisms/info-modal';
//TODO (@OKA) подумать как не делать так
// eslint-disable-next-line boundaries/element-types
import { CartWidgetConnector } from '@widgets/cart';

import { TTopBarComponent, TopbarMenuWidget } from './topbar-menu-widget';
import { TMenuInfo, TMenuPaths, TProfilePaths } from './types';
import { TopbarMenuItem } from './ui/topbar-menu-item';

const IS_FAVORITE_ENABLED = FeatureToggle.hasFeatureFlag('favorite');
const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');
const IS_PRICE_LIST_ENABLED = FeatureToggle.hasFeatureFlag('priceList');

type TMenuArg = TMenuPaths & {
    priceUrl?: string;
};

const getMenus = ({
    accountHelpPath,
    accountHomePath,
    accountProviderPath,
    priceUrl,
}: TMenuArg): TMenuInfo[] => [
    {
        label: 'Личные данные',
        path: accountHomePath,
        isVisible: true,
    },
    {
        label: 'Поставщики',
        path: accountProviderPath,
        isVisible: IS_PROVIDERS_ENABLED,
    },
    {
        label: 'Вопросы и ответы',
        path: accountHelpPath,
        isVisible: true,
    },
    {
        label: 'Скачать прайс',
        path: priceUrl || '',
        isVisible: Boolean(priceUrl) && IS_PRICE_LIST_ENABLED,
        isExternal: true,
    },
];

type TProps = {
    paths: TProfilePaths;
};

export const TopbarMenuWidgetConnector = ({ paths }: TProps) => {
    const favoriteCount = useUnit($favoriteAmount);
    const logout = useLogout();
    const navigate = useNavigate();
    const fireProductEvent = useYandexReachGoal();
    const { data } = useGetPriceList();
    const {
        isOpen: isInfoOpen,
        open: openInfo,
        close: closeInfo,
    } = useStateModal();
    const {
        isOpen: isCartOpen,
        open: openCart,
        close: closeCart,
    } = useStateModal();
    const userInfoStore = useUnit($userInfo);
    const cartAmount = useUnit($cartAmount);
    const userInfo = useMemo(
        () => mapUserInfo(userInfoStore, cartAmount || 0),
        [cartAmount, userInfoStore],
    );

    const handleOpenCart = useCallback(() => {
        fireProductEvent('openCart');
        openCart();
    }, [fireProductEvent, openCart]);

    const handleOpenFavorite = useCallback(() => {
        navigate(paths.favoriteHomePath);
    }, [navigate, paths.favoriteHomePath]);

    const handleOpenFeatureToggle = useCallback(
        () => updateModalState(true),
        [],
    );

    const topBarComponents: TTopBarComponent[] = [
        {
            component: (
                <TopbarMenuItem
                    title="фиче-тогл"
                    icon={<AccessibleForwardIcon />}
                    onClick={handleOpenFeatureToggle}
                />
            ),
            isVisible: false,
        },
        {
            component: (
                <TopbarMenuItem
                    title="избранное"
                    icon={<FavoriteBorderOutlinedIcon />}
                    badgeContent={favoriteCount || undefined}
                    badgeColor="primary"
                    onClick={handleOpenFavorite}
                />
            ),
            isVisible: IS_FAVORITE_ENABLED,
        },
        {
            component: (
                <TopbarMenuItem
                    title="корзина"
                    icon={<ShoppingCartOutlinedIcon />}
                    badgeContent={userInfo.cartAmount}
                    onClick={handleOpenCart}
                    badgeColor="error"
                />
            ),
            isVisible: true,
        },
    ];

    const menus = useMemo(
        () =>
            getMenus({
                ...paths,
                priceUrl: data?.data.priceListUrl,
            }),
        [data?.data.priceListUrl, paths],
    );

    return (
        <>
            <TopbarMenuWidget
                profileInfo={userInfo}
                menusInfo={menus}
                topbarComponents={topBarComponents}
                onCartClick={handleOpenCart}
                onLogout={openInfo}
            />
            <InfoModal
                withHeader
                title="Вы действительно хотите выйти?"
                variant="question"
                primaryButtonText="да, выйти"
                secondaryButtonText="нет, отменить"
                onClose={closeInfo}
                open={isInfoOpen}
                onSecondaryButtonClick={closeInfo}
                onPrimaryButtonClick={logout}
            />
            {isCartOpen && (
                <CartWidgetConnector isOpen={isCartOpen} onClose={closeCart} />
            )}
        </>
    );
};
