import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, authApi } from '@shared/api';
import { CreateLogoutRequest, ErrorModel } from '@shared/api/axios-client';

import { authKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError | ErrorModel;

type TUseLogout = {
    onSuccess?: () => void;
    onSettled?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useLogout = (props?: TUseLogout) => {
    const { mutateAsync, ...rest } = useMutation(
        authKeys.logout.queryKey,
        (tokens: CreateLogoutRequest) => {
            return authApi.postV1Logout({ createLogoutRequest: tokens });
        },
        {
            onSettled: props?.onSettled,
            onSuccess: props?.onSuccess,
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code) {
                    props.onError(response.data);
                    return;
                }
                props.onError({
                    code: 'unknown_code',
                    message: 'что-то пошло не так',
                    response,
                });
            },
        },
    );

    const logout = useCallback(
        (tokens: CreateLogoutRequest) => {
            return mutateAsync(tokens);
        },
        [mutateAsync],
    );

    return { ...rest, logout };
};
