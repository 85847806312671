import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';

export const ProviderInstantOrderInfo = () => {
    return (
        <Stack
            padding="12px 16px"
            borderRadius="8px"
            direction="row"
            gap="10px"
            sx={theme => ({
                backgroundColor: theme.palette.surface.secondary,
            })}>
            <ErrorOutlineIcon
                sx={theme => ({ color: theme.palette.icon.primary })}
            />
            <Typography
                variant="body2"
                color={theme => theme.palette.typography.primary}>
                Обратите внимание, в вашем личном кабинете у поставщика должны
                быть указаны корректные данные по оплате и получению заказа,
                т.к. оформленный заказ будет передан из корзины сервиса Витрины
                напрямую поставщику.
            </Typography>
        </Stack>
    );
};
