import { RouteObject } from 'react-router-dom';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggleGuard } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header/types';

import { ChangeClientIdPageConnector } from './change-client-id';
import { ChangeProvidersPageConnector } from './change-providers';
import { HelpConnector } from './help';
import { AccountPageConnector } from './home';
import { accountPaths } from './paths';

type TExternalRoutes = {
    catalogHomePath: string;
    providerHomePath: string;
    headerPaths: THeaderPaths;
};

export const accountRoutes = ({
    catalogHomePath,
    providerHomePath,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: accountPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Личные данные</MetaTitle>
                <AccountPageConnector headerPaths={headerPaths} />
            </>
        ),
    },
    {
        path: accountPaths.providers.absolute,
        element: (
            <FeatureToggleGuard
                featureFlag="providers"
                fallbackRoute={catalogHomePath}>
                <>
                    <MetaTitle>Изменение поставщика</MetaTitle>
                    <ChangeProvidersPageConnector
                        externalRoutes={{ catalogHomePath, providerHomePath }}
                        headerPaths={headerPaths}
                    />
                </>
            </FeatureToggleGuard>
        ),
    },
    {
        path: accountPaths.help.absolute,
        element: (
            <>
                <MetaTitle>Вопросы и ответы</MetaTitle>
                <HelpConnector headerPaths={headerPaths} />
            </>
        ),
    },
    {
        path: accountPaths.clientId.absolute,
        element: (
            <>
                <MetaTitle>Изменение client id</MetaTitle>
                <ChangeClientIdPageConnector headerPaths={headerPaths} />
            </>
        ),
    },
];
