import { useCallback, useState } from 'react';

export const useStateModal = (initState: boolean = false) => {
    const [isOpen, setIsOpen] = useState<boolean>(initState);
    const open = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);
    const toggle = useCallback(() => setIsOpen(prev => !prev), []);
    return {
        isOpen,
        open,
        close,
        toggle,
    };
};
