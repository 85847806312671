import { Grid, Typography } from '@mui/material';

import { Logo } from '@shared/ui/atoms/logo';

type TProps = {
    onLogoClick: () => void;
};

export const Header = ({ onLogoClick }: TProps) => {
    return (
        <Grid container gap={{ xs: '10px', md: 0 }}>
            <Grid xs={12} md={2} item>
                <Logo
                    color={theme => theme.palette.typography.primary}
                    sx={{ paddingTop: '5px', paddingRight: '10px' }}
                    onClick={onLogoClick}
                />
            </Grid>
            <Grid xs={12} md={10} item>
                <Typography
                    variant="h2"
                    // textAlign={{ xs: 'center', md: 'start' }}
                >
                    Новости и предложения
                </Typography>
            </Grid>
        </Grid>
    );
};
