import { Stack } from '@mui/material';

import { TAdd } from '@entities/adds/types';
import { AddBlock } from '@entities/adds/ui/add-block';

type TProps = {
    adds: TAdd[];
    onAddClick: (id: string) => void;
};

export const SearchAdds = ({ adds, onAddClick }: TProps) => {
    return (
        <Stack
            direction="row"
            flexWrap="wrap"
            gap="10px"
            justifyContent="space-between">
            {adds.map((add, index) => (
                <AddBlock
                    {...add}
                    key={index}
                    onClick={() => onAddClick(add.id)}
                />
            ))}
        </Stack>
    );
};
