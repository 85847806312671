import { Box, Button, Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';

type TProps = {
    title: string;
    description: string;
    imgUrl: string;
    onClick: () => void;
};

export const NewsCard = ({ title, description, imgUrl, onClick }: TProps) => {
    return (
        <Stack
            onClick={onClick}
            direction="column"
            border={theme => `1px solid ${theme.palette.border.border4}`}
            borderRadius="15px"
            paddingBottom="20px"
            maxWidth="270px"
            width="100%"
            gap="20px"
            data-testid={selectors.newsCard}
            sx={{ cursor: 'pointer' }}>
            <Box
                component="img"
                alt="Изображение банера"
                src={imgUrl}
                sx={{
                    borderRadius: '15px 15px 0 0',
                    height: '165px',
                    objectFit: 'cover',
                }}
            />
            <Stack gap="30px" flex={1} justifyContent="space-between">
                <Stack gap="5px" padding="0px 15px">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Typography
                        variant="body3"
                        sx={{ wordBreak: 'break-word' }}>
                        {description}
                    </Typography>
                </Stack>
                <Box display="flex" justifyContent="flex-end" paddingX="10px">
                    <Button
                        variant="contained"
                        color="buttonSecondaryEnabled"
                        data-testid={selectors.newsCardButton}>
                        подробнее
                    </Button>
                </Box>
            </Stack>
        </Stack>
    );
};
