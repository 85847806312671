import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Stack, Typography } from '@mui/material';

import { formatCurrency, formatSpaces } from '@shared/lib/utils/number';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    totalPrice: number;
    minOrderPrice: number;
    isError: boolean;
    isInfo: boolean;
    onSubmit: () => void;
    onInfoClick: () => void;
};

export const CartTotal = ({
    totalPrice,
    minOrderPrice,
    isError,
    isInfo,
    onSubmit,
    onInfoClick,
}: TProps) => {
    return (
        <Stack
            gap="30px"
            padding="15px"
            sx={theme => ({
                background: theme.palette.surface.primaryInvert,
            })}>
            <Conditional condition={isInfo}>
                <Stack
                    direction="row"
                    sx={theme => ({
                        background: theme.palette.bg.primaryInvert,
                    })}
                    padding="12px 16px"
                    borderRadius="5px"
                    justifyContent="space-between">
                    <Stack direction="row" gap="10px">
                        <ErrorOutlineIcon
                            sx={theme => ({
                                fontSize: '24px',
                                color: theme.palette.icon.onlyWhite,
                            })}
                        />
                        <Stack direction="column" gap="4px">
                            <Typography
                                color={theme =>
                                    theme.palette.typography.primaryInvert
                                }
                                variant="subtitle1">
                                Внимание!
                            </Typography>
                            <Typography
                                color={theme =>
                                    theme.palette.typography.primaryInvert
                                }
                                variant="body3">
                                Мгновенное оформление заказа у поставщика
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button
                        variant="text"
                        size="small"
                        color="buttonPrimaryInvert"
                        sx={{ height: '24px' }}
                        onClick={onInfoClick}
                        data-testid={selectors.cartTotalInfoButton}>
                        подробнее
                    </Button>
                </Stack>
            </Conditional>
            <Stack
                flexWrap="wrap"
                direction="row"
                justifyContent="space-between"
                gap="8px">
                <Typography
                    variant="h3"
                    sx={theme => ({
                        color: theme.palette.typography.primaryInvert,
                    })}>
                    итого:
                </Typography>
                <Typography
                    variant="h3"
                    sx={theme => ({
                        color: theme.palette.typography.primaryInvert,
                    })}>
                    {formatCurrency(totalPrice)}
                </Typography>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
                gap="8px">
                <Conditional condition={Boolean(minOrderPrice)}>
                    <Stack
                        direction="row"
                        gap="4px"
                        color={theme =>
                            isError
                                ? theme.palette.typography.negative
                                : theme.palette.typography.primaryInvert
                        }>
                        <ErrorOutlineIcon sx={{ fontSize: '13px' }} />
                        <Typography variant="caption1">
                            Минимальная сумма заказа:{' '}
                            {formatSpaces(minOrderPrice)} ₽
                        </Typography>
                    </Stack>
                </Conditional>

                <Button
                    variant="contained"
                    disabled={isError}
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    color="buttonPrimaryInvert"
                    endIcon={<ArrowOutwardIcon />}
                    onClick={onSubmit}
                    data-testid={selectors.cartTotalSubmitButton}>
                    Оформить заказ
                </Button>
            </Stack>
        </Stack>
    );
};
