import { Helmet } from 'react-helmet-async';

import { FeatureToggle } from '@features/feature-toggle';
import { ENV } from '@shared/config/constants';

import { OAuthYandex, TYandexAuthResponse } from './oauth-yandex';

const CLIENT_ID = ENV.yandexClientId;
const ORIGIN = ENV.hostName;
const YANDEX_SUGGEST_SCRIPT =
    'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js';
const YANDEX_SUGGEST_TOKEN_SCRIPT =
    'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js';

type TProps = {
    paths: {
        authHomePath: string;
        authCompanyPath: string;
    };
    onSuccess: (data: TYandexAuthResponse) => void;
    onError: () => void;
};

const isLoginWithNews = FeatureToggle.hasFeatureFlag('newses');

export const OAuthYandexConnector = ({ paths, onSuccess, onError }: TProps) => {
    if (!ORIGIN || !CLIENT_ID) {
        return null;
    }
    return (
        <>
            <Helmet>
                <script src={YANDEX_SUGGEST_SCRIPT} />
                <script src={YANDEX_SUGGEST_TOKEN_SCRIPT} />
            </Helmet>
            <OAuthYandex
                clientId={CLIENT_ID}
                origin={ORIGIN}
                redirectUrl={`${ORIGIN}${isLoginWithNews ? paths.authCompanyPath : paths.authHomePath}`}
                onSuccess={onSuccess}
                onError={onError}
            />
        </>
    );
};
