import { TFeatureFlags, nameFlags } from '@features/feature-toggle';
import { featureToggleGuard } from '@features/feature-toggle/feature-toggle-guard';

import { TFeatureItem } from '../types';

export const mapFeatureToggleItems = (flags: TFeatureFlags) =>
    Object.entries(flags).reduce<TFeatureItem[]>(
        (result, [title, isActive]) => {
            if (featureToggleGuard(title)) {
                return [
                    ...result,
                    {
                        title: nameFlags[title],
                        value: title,
                        isActive,
                    },
                ];
            }

            return result;
        },
        [],
    );
