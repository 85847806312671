import { TProductTableHeaderField } from '@entities/products/types';

export const catalogTableHeaders: TProductTableHeaderField[] = [
    {
        label: 'Поставщик',
        alight: undefined,
        size: undefined,
        attribute: 'provider',
    },
    {
        label: 'Производитель',
        alight: undefined,
        size: undefined,
        attribute: '2',
    },
    {
        label: 'Артикул',
        alight: undefined,
        size: undefined,
        attribute: '3',
    },
    {
        label: 'Название товара',
        alight: undefined,
        size: 3,
        attribute: 'Alphabet',
        isSortable: true,
    },
    {
        label: 'Состояние',
        alight: undefined,
        size: undefined,
        attribute: '5',
    },
    {
        label: 'Тип получения',
        alight: undefined,
        size: undefined,
        attribute: '6',
    },
    {
        label: 'Срок',
        alight: 'right',
        size: undefined,
        attribute: 'DeliveryDate',
        isSortable: true,
    },
    {
        label: 'В наличии',
        alight: 'right' as const,
        size: undefined,
        attribute: '8',
    },
    {
        label: 'Стоимость',
        alight: 'right' as const,
        size: undefined,
        attribute: 'Price',
        isSortable: true,
    },
];
