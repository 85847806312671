import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line boundaries/element-types
import { logoutPaths } from '@pages/logout/paths';

export const useLogout = () => {
    const navigate = useNavigate();
    return useCallback(() => {
        navigate(logoutPaths.home.absolute);
    }, [navigate]);
};
