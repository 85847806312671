import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControl,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { FieldError } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { selectors } from '@shared/test/selectors';

type TValue = {
    label: string;
    id: string;
};

type TProps = {
    values: TValue[];
    selectedValues: TValue[];
    setSelectedValues: Dispatch<SetStateAction<TValue[]>>;
    placeholder: string;
    allLabel?: string;
    selectLabel?: string;
    error?: FieldError;
};

const ALL_ID = 'all';

export const CheckboxAutocomplete = ({
    values,
    allLabel,
    placeholder,
    selectLabel,
    selectedValues,
    error,
    setSelectedValues,
}: TProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const theme = useTheme();
    const initItems = useMemo(
        () =>
            allLabel
                ? [
                      {
                          id: ALL_ID,
                          label: allLabel,
                      },
                      ...values,
                  ]
                : values,
        [allLabel, values],
    );

    const handleChange = (value: TValue[]) => {
        if (value.find(option => option.id === ALL_ID)) {
            return setSelectedValues(
                selectedValues.length === values.length ? [] : values,
            );
        }
        setSelectedValues(value);
    };

    const handleDelete = useCallback(
        (value: string) => {
            setSelectedValues(selectedValues.filter(item => item.id !== value));
        },
        [selectedValues, setSelectedValues],
    );
    const isFilled = selectedValues.length > 0;
    const isAllSelected = selectedValues.length + 1 === initItems.length;
    return (
        <FormControl sx={{ width: '100%', gap: '15px' }}>
            <Autocomplete
                data-testid={selectors.checkboxAutocomplete}
                popupIcon={
                    <ArrowDropDownIcon
                        sx={theme => ({ color: theme.palette.icon.primary })}
                    />
                }
                filterOptions={(_, state) =>
                    initItems.filter(item =>
                        item.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()),
                    )
                }
                multiple
                disableCloseOnSelect
                noOptionsText="данные не найдены"
                clearIcon={false}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                options={values}
                value={selectedValues}
                onChange={(_, data) => handleChange(data)}
                renderInput={params => (
                    <TextField
                        data-testid={selectors.checkboxAutocompleteInput}
                        variant="standard"
                        label={selectLabel}
                        {...params}
                        helperText={error?.message || ' '}
                        InputLabelProps={{
                            filled: isFilled,
                        }}
                        InputProps={{
                            sx: {
                                borderColor: isOpen
                                    ? theme.palette.border.border1
                                    : theme.palette.border.border3,
                            },
                            ...params.InputProps,
                        }}
                        error={Boolean(error)}
                        placeholder={isFilled ? '' : placeholder}
                    />
                )}
                ListboxProps={{ onMouseLeave: () => setIsOpen(false) }}
                renderOption={(props, option, { selected, index }) => (
                    <li
                        data-testid={selectors.checkboxAutocompleteOption(
                            option.id,
                        )}
                        key={index}
                        value={option.id}
                        {...props}>
                        <Stack
                            direction="row"
                            gap="20px"
                            width="100%"
                            sx={theme => ({
                                '&:hover > h6': {
                                    borderBottom: `1px solid ${theme.palette.border.border1}`,
                                },
                            })}>
                            <Checkbox
                                data-testid={selectors.checkboxAutocompleteCheckbox(
                                    option.id,
                                )}
                                checked={
                                    option.id === ALL_ID
                                        ? isAllSelected
                                        : selected
                                }
                                disabled
                            />
                            <Typography
                                variant="subtitle1"
                                width="100%"
                                borderBottom={theme =>
                                    `1px solid ${theme.palette.border.border3}`
                                }
                                padding="20px 0">
                                {option.label}
                            </Typography>
                        </Stack>
                    </li>
                )}
                renderTags={(values, getTagProps) => {
                    const isAll =
                        values.length + 1 === initItems.length ||
                        values.find(item => item.id === ALL_ID);
                    return allLabel && isAll ? (
                        <Chip
                            data-testid={selectors.checkboxAutocompleteChip(
                                ALL_ID,
                            )}
                            key={ALL_ID}
                            color="buttonSecondaryEnabled"
                            label="Все"
                            onDelete={() => setSelectedValues([])}
                        />
                    ) : (
                        values.map((value, index) => (
                            <Chip
                                data-testid={selectors.checkboxAutocompleteChip(
                                    value.id,
                                )}
                                {...getTagProps}
                                key={index}
                                color="buttonSecondaryEnabled"
                                label={value.label}
                                onDelete={() => handleDelete(value.id)}
                            />
                        ))
                    );
                }}
            />
        </FormControl>
    );
};
