import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetNews, useNewsClickEcommerce } from '@entities/news/model';
import { NewsModal } from '@entities/news/ui/news-modal';
import { mapNews } from '@entities/news/utils/mappers';
import { mapEcommerceBannerPromo } from '@entities/news/utils/mappers/ecommerce-mappers';
import {
    useNavigateExternal,
    useYandexEcommerce,
    useYandexReachGoal,
} from '@shared/hooks';
import { PageTemplate } from '@shared/ui/templates/page-template';

import { DriverAuthPage } from './driver-auth-page';
import { Header } from './ui/header';
import { authPaths } from '../paths';

type TActiveBanner = {
    sectionId: string;
    bannerId: string;
};

export const DriverAuthPageConnector = () => {
    const navigateExt = useNavigateExternal();
    const navigate = useNavigate();
    const onNewsClick = useNewsClickEcommerce();
    const { promoViewAction } = useYandexEcommerce();
    const fireProductEvent = useYandexReachGoal();
    const [modalBanner, setModalBanner] = useState<TActiveBanner>();
    const { data, isLoading } = useGetNews({ body: { sectionName: 'driver' } });
    const newses = mapNews(data?.data);
    useEffect(() => {
        if (!data) {
            return;
        }
        promoViewAction(mapEcommerceBannerPromo(data.data.sections, 'driver'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const modalBannerInfo = useMemo(
        () =>
            newses
                .find(news => news.id === modalBanner?.sectionId)
                ?.banners.find(banner => banner.id === modalBanner?.bannerId),
        [modalBanner, newses],
    );

    const handleCloseModal = useCallback(() => setModalBanner(undefined), []);
    const handleBackClick = useCallback(
        () => navigate(authPaths.home.absolute),
        [navigate],
    );
    const onLogoClick = useCallback(
        () => navigate(authPaths.home.absolute),
        [navigate],
    );
    const handleOpenModal = useCallback(
        (sectionId: string, bannerId: string) => {
            setModalBanner({ sectionId, bannerId });
            if (!data) {
                return;
            }
            onNewsClick({
                targetBannerId: bannerId,
                targetSectionId: sectionId,
                sections: data.data.sections,
                positions: 'driver',
            });
        },
        [data, onNewsClick],
    );
    const handleClickRedirect = useCallback(() => {
        navigateExt(modalBannerInfo?.url || '');
        const targetSection = data?.data.sections.find(
            section => section.id === modalBanner?.sectionId,
        );
        if (!data || !modalBanner || !targetSection) {
            return;
        }
        fireProductEvent('openNews', {
            newsId: `news${targetSection.id}`,
            newsName: targetSection.sectionName,
            newsCreative: modalBanner.bannerId,
            newsPosition: 'drive',
        });
    }, [
        data,
        fireProductEvent,
        modalBanner,
        modalBannerInfo?.url,
        navigateExt,
    ]);

    return (
        <PageTemplate
            variant="medium"
            withHeadSpace
            Header={<Header onLogoClick={onLogoClick} />}>
            <DriverAuthPage
                isLoading={isLoading}
                newses={newses}
                onBannerClick={handleOpenModal}
                onActionClick={handleBackClick}
            />
            <NewsModal
                open={Boolean(modalBanner)}
                title={modalBannerInfo?.title || ''}
                imgUrl={modalBannerInfo?.pictureUrl || ''}
                text={modalBannerInfo?.fullText || ''}
                buttonText={modalBannerInfo?.buttonText || ''}
                onClick={handleClickRedirect}
                onClose={handleCloseModal}
            />
        </PageTemplate>
    );
};
