import { Controller, useFormContext } from 'react-hook-form';

import { Counter } from '@shared/ui/molecules/counter';
import { TCounterVariant } from '@shared/ui/molecules/counter/counter';

type TProps = {
    variant?: TCounterVariant;
    minValue?: number;
    maxValue?: number;
    isDisabled?: boolean;
    onSubmit: () => void;
};

export type TFormData = {
    counter: number;
};

export const FormCounter = ({
    variant,
    minValue,
    maxValue,
    isDisabled,
    onSubmit,
}: TProps) => {
    const { control } = useFormContext<TFormData>();

    return (
        <Controller
            name="counter"
            control={control}
            render={({ field }) => {
                return (
                    <Counter
                        minValue={minValue}
                        maxValue={maxValue}
                        isDisabled={isDisabled}
                        variant={variant}
                        inputRef={field.ref}
                        value={field.value}
                        fieldName={field.name}
                        onChange={field.onChange}
                        onBlur={() => {
                            field.onBlur();
                            onSubmit();
                        }}
                        onAdd={() => {
                            field.onChange(field.value + 1);
                            onSubmit();
                        }}
                        onSubtract={() => {
                            field.onChange(field.value - 1);
                            onSubmit();
                        }}
                    />
                );
            }}
        />
    );
};
