export const NotFoundIcon = () => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 1198 491"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 429.179V354.591L219.719 7.44039H281.918V113.634H243.991L96.0796 348.017V352.063H402.776V429.179H0ZM247.026 491.259V406.423L248.037 373.048V7.44039H336.531L336.483 491.259H247.026Z"
                fill="#1C1C1E"
            />
            <path
                d="M610.983 491.259C587.241 491.259 556.483 491.259 530.983 491.259C518.483 491.259 507.983 491.259 499.983 491.259C496.483 491.259 493.483 491.259 491.483 491.259C490.983 491.259 490.983 491.259 490.483 491.259C489.983 491.259 451.28 451.321 435.267 411.372C419.422 371.255 411.5 322.962 411.5 266.494C411.668 210.027 419.675 161.987 435.52 122.375C451.533 82.5947 474.373 52.2538 504.04 31.3523C533.875 10.4508 569.525 0 610.991 0C652.457 0 688.108 10.4508 717.943 31.3523C747.778 52.2538 770.618 82.5947 786.463 122.375C802.476 162.155 810.483 210.195 810.483 266.494C810.483 323.131 802.476 371.508 786.463 411.625C770.618 451.574 763.318 470.188 733.483 491.259C730.983 491.259 731.483 491.259 728.483 491.259C721.483 491.259 709.505 491.228 697.983 491.259C670.952 491.33 638.904 491.259 610.983 491.259ZM610.991 455.619C643.355 455.619 668.892 439.69 687.602 407.832C706.481 375.806 715.92 328.693 715.92 266.494C715.92 225.366 711.622 190.811 703.025 162.83C694.429 134.848 682.292 113.778 666.616 99.6193C650.94 85.2917 632.398 78.1278 610.991 78.1278C578.796 78.1278 553.343 94.1411 534.633 126.168C515.923 158.026 506.484 204.801 506.315 266.494C506.147 307.792 510.276 342.515 518.704 370.665C527.301 398.814 539.437 420.053 555.113 434.381C570.79 448.54 589.415 455.619 610.991 455.619Z"
                fill="#1C1C1E"
            />
            <path
                d="M820.942 429.179V354.591L1040.66 7.44039H1102.86V113.634H1064.93L917.021 348.017V352.063H1197.98V429.179H820.942ZM1067.97 491.259V406.423L1068.98 373.048V7.44039H1157.47V491.259H1067.97Z"
                fill="#1C1C1E"
            />
        </svg>
    );
};
