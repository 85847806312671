import { TFormDelivery, TProductFilters } from '@entities/products/types';
import { ProductInfo } from '@shared/api/axios-client';
import { dateUtils } from '@shared/lib/utils';

const dayFilterToDate = (filter?: TFormDelivery['id']) => {
    const finalDate = new Date();
    const numFilter = Number(filter);
    if (isNaN(numFilter)) {
        return new Date().getDate();
    }
    finalDate.setDate(finalDate.getDate() + numFilter);
    return finalDate;
};

const isProductInValid = (product: ProductInfo, filters: TProductFilters) => {
    const isInvalid = filters.find(filter => {
        if (filter.value === undefined) {
            return false;
        }
        switch (filter.attribute) {
            case 'DeliveryDate':
                if (filter.value === 'any') {
                    return false;
                }
                return !(
                    dateUtils.validateDate(
                        product.warehouse.shipmentDate || '',
                    ) < dayFilterToDate(filter.value)
                );
            case 'IsAvailable':
                if (filter.value) {
                    return Boolean(product.warehouse.shipmentDate);
                }
                return !product.warehouse.shipmentDate;
            case 'ProviderId':
                if (filter.value.length === 0) {
                    return false;
                }
                return !filter.value.includes(product.providers.id);
            case 'IsUsed':
                return product.warehouse.used !== filter.value;
            case 'MaxPrice':
                return product.warehouse.price > filter.value;
            case 'MinPrice':
                return product.warehouse.price < filter.value;
            default:
                return true;
        }
    });
    return isInvalid;
};

export const filterProducts = (
    filters: TProductFilters,
    products: ProductInfo[],
) => {
    return products.reduce<ProductInfo[]>((result, product) => {
        if (isProductInValid(product, filters)) {
            return result;
        }
        return [...result, product];
    }, []);
};
