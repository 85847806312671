import { useQueryClient } from '@tanstack/react-query';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '@entities/auth';
import {
    $session,
    $userInfo,
    resetAthDomainStores,
} from '@entities/auth/model/auth-domain';
import { resetCartDomainStores } from '@entities/cart/model/cart-domain';
import { resetUserDomainStores } from '@entities/user/model/user-domain';
import { useYandexReachGoal } from '@shared/hooks';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { LogoutPage } from './logout-page';

type TProps = {
    externalRoutes: {
        authHomePath: string;
    };
    headerPaths: THeaderPaths;
};

export const LogoutPageConnector = ({
    externalRoutes,
    headerPaths,
}: TProps) => {
    const session = useUnit($session);
    const userInfo = useUnit($userInfo);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const fireProductEvent = useYandexReachGoal();
    const logoutToken = session?.refreshToken || '';
    const authToken = userInfo?.yaToken || '';
    const { logout } = useLogout({
        onSettled: () => {
            resetAthDomainStores();
            resetUserDomainStores();
            resetCartDomainStores();
            fireProductEvent('logout');
            navigate(externalRoutes.authHomePath);
            queryClient.clear();
        },
    });
    useEffect(() => {
        logout({ authToken, logoutToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <InfoTemplate
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="secondary"
                    paths={headerPaths}
                />
            }
            title="один момент..."
            CenterSlot={<LogoutPage />}
        />
    );
};
