import {
    Container,
    Grid,
    Stack,
    Theme,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

import { Conditional } from '@shared/ui/atoms/conditional';
import { Logo } from '@shared/ui/atoms/logo';

export type THeaderVariants = 'primary' | 'secondary';

type TProps = {
    withLogo: boolean;
    backgroundColor?: string;
    RightSlot?: ReactNode;
    children?: ReactNode;
    variant: THeaderVariants;
    onLogoClick?: () => void;
};

const getLogoVariants = (theme: Theme, variant: THeaderVariants) => {
    switch (variant) {
        case 'secondary':
            return theme.palette.typography.primaryInvert;
        case 'primary':
        default:
            return theme.palette.typography.primary;
    }
};

export const HeaderWidget = ({
    withLogo,
    backgroundColor,
    RightSlot,
    children,
    variant,
    onLogoClick,
}: TProps) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    if (isDesktop) {
        return (
            <Grid
                container
                direction="row"
                sx={{ background: backgroundColor || 'transparent' }}>
                <Grid item xs={2}>
                    <Conditional condition={withLogo}>
                        <Logo
                            color={getLogoVariants(theme, variant)}
                            sx={{ paddingTop: '5px', paddingRight: '10px' }}
                            onClick={onLogoClick}
                        />
                    </Conditional>
                </Grid>
                <Grid item xs={6}>
                    {children}
                </Grid>
                <Grid item xs={4}>
                    <Stack alignItems="end">{RightSlot}</Stack>
                </Grid>
            </Grid>
        );
    }
    return (
        <Stack sx={{ background: backgroundColor || 'transparent' }}>
            <Stack
                direction="row-reverse"
                flex={1}
                justifyContent="space-between">
                <Stack>{RightSlot}</Stack>
                <Stack>
                    <Conditional condition={withLogo}>
                        <Logo
                            color={getLogoVariants(theme, variant)}
                            onClick={onLogoClick}
                        />
                    </Conditional>
                </Stack>
            </Stack>
            <Container maxWidth="md">{children}</Container>
        </Stack>
    );
};
