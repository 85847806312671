import { Grid, Skeleton } from '@mui/material';

export const FavoritePageSkeleton = () => {
    return (
        <Grid container direction="row" spacing="20px">
            {[0, 1, 2, 3, 4, 5].map(item => (
                <Grid item xs={12} sm={6} lg={4} xl={4} key={item}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="320px"
                        sx={{ borderRadius: '10px' }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
