export const ENV = {
    apiBasePath: process.env.REACT_APP_API_BASE_PATH, // path to mw, root API endpoint
    refreshPath: process.env.REACT_APP_API_BASE_PATH + '/v1/refresh',
    appEnv: process.env.REACT_APP_ENV || process.env.NODE_ENV,
    hostName: process.env.REACT_APP_HOST_NAME,
    yandexClientId: process.env.REACT_APP_YANDEX_CLIENT_ID,
    yandexPropositionForm: 'https://forms.yandex.ru/u/66167dde43f74f0b2bd87267',
    yandexSupportLink: 'https://t.me/yavitrina',
};

export const IS_DEV =
    ENV.appEnv === 'local' || ENV.appEnv === 'dev' || ENV.appEnv === 'develop';
