import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    updateLogin,
    updateSession,
    updateUserInfo,
} from '@entities/auth/model/auth-domain';
import { useLogin } from '@entities/auth/model/hooks/use-login';
import { useYandexReachGoal } from '@shared/hooks';
import {
    OAuthYandexConnector,
    TYandexAuthResponse,
} from '@widgets/oauth-yandex';

import { AuthPage } from './auth-page';
import { AuthControls } from './ui/auth-controls';
import { authPaths } from '../paths';

export const AuthPageConnector = () => {
    const fireProductEvent = useYandexReachGoal();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { login } = useLogin({
        onSuccess: ({ data }) => {
            updateUserInfo(data);
            updateSession(data);
            updateLogin(true);
        },
        onError: () => {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });

    const successHandler = (data: TYandexAuthResponse) => {
        if (data.access_token) {
            fireProductEvent('yandexOauth');
            updateUserInfo({ yaToken: data.access_token });
            login({ authToken: data.access_token });
        }
    };
    const handleError = useCallback(
        () =>
            enqueueSnackbar('Что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
        [enqueueSnackbar],
    );
    const handlePrimaryClick = useCallback(() => {
        navigate(authPaths.company.absolute);
    }, [navigate]);
    const handleSecondaryClick = useCallback(() => {
        navigate(authPaths.driver.absolute);
    }, [navigate]);

    return (
        <AuthPage
            OAuthWidget={
                <AuthControls
                    OAuthComponent={
                        <OAuthYandexConnector
                            onSuccess={successHandler}
                            onError={handleError}
                            paths={{
                                authCompanyPath: authPaths.company.absolute,
                                authHomePath: authPaths.home.absolute,
                            }}
                        />
                    }
                    onPrimaryButton={handlePrimaryClick}
                    onSecondaryButton={handleSecondaryClick}
                />
            }
        />
    );
};
