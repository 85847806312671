import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorPageTemplate } from '@shared/ui/templates/error-page-template';
import { HeaderWidgetConnector, THeaderPaths } from '@widgets/header';

import { NotFound } from './not-found';

type TProps = {
    externalRoutes: {
        catalogHomePath: string;
    };
    headerPaths: THeaderPaths;
};

export const NotFoundConnector = ({ externalRoutes, headerPaths }: TProps) => {
    const navigate = useNavigate();
    const onHomeHandler = useCallback(() => {
        navigate(externalRoutes.catalogHomePath, { replace: true });
    }, [externalRoutes.catalogHomePath, navigate]);
    return (
        <ErrorPageTemplate
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    paths={headerPaths}>
                    <Typography variant="h2">cтраница не найдена</Typography>
                </HeaderWidgetConnector>
            }>
            <NotFound onHome={onHomeHandler} />
        </ErrorPageTemplate>
    );
};
