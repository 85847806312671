import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack } from '@mui/material';

import { TNews } from '@entities/news/types';
import { NewsSection } from '@entities/news/ui/news-section';
import { NewsSkeleton } from '@entities/news/ui/news-skeleton';
import { selectors } from '@shared/test/selectors';

type TProps = {
    newses: TNews[];
    isLoading: boolean;
    onActionClick: () => void;
    onBannerClick: (sectionId: string, bannerId: string) => void;
};

export const DriverAuthPage = ({
    newses,
    isLoading,
    onActionClick,
    onBannerClick,
}: TProps) => {
    return (
        <Stack gap="100px">
            <Box>
                <Button
                    data-testid={selectors.authDriverBackButton}
                    variant="contained"
                    color="buttonSecondaryEnabled"
                    onClick={onActionClick}
                    startIcon={<ArrowBackIcon />}>
                    На главную страницу
                </Button>
            </Box>
            <Stack gap="50px">
                {isLoading ? (
                    <NewsSkeleton />
                ) : (
                    newses.map(news => (
                        <NewsSection
                            key={news.id}
                            news={news}
                            onBannerClick={bannerId =>
                                onBannerClick(news.id, bannerId)
                            }
                        />
                    ))
                )}
            </Stack>
        </Stack>
    );
};
