import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { TFormProvider } from '@entities/providers/types';

import { FormProviderSelect, TFormData } from './form-provider-select';

type TProps = {
    providers: TFormProvider[];
    selectedValues: TFormProvider[];
    isNoProviders: boolean;
    onChange: (data: TFormData) => void;
    setSelectedValues: Dispatch<SetStateAction<TFormProvider[]>>;
};

const schema = z.object({
    providers: z.array(z.string()).min(1, 'Вы не выбрали поставщиков'),
});

export const FormProviderSelectProvider = ({
    providers,
    selectedValues,
    isNoProviders,
    onChange,
    setSelectedValues,
}: TProps) => {
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            providers: [],
        },
    });
    const providersValue = methods.watch('providers');
    useEffect(() => {
        onChange({ providers: providersValue });
    }, [providersValue, onChange]);
    useEffect(() => {
        if (isNoProviders) {
            methods.setError('providers', {
                message: 'Вы не выбрали поставщиков',
            });
            return;
        }
        methods.clearErrors('providers');
    }, [isNoProviders, methods]);
    return (
        <FormProvider {...methods}>
            <FormProviderSelect
                providers={providers}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
            />
        </FormProvider>
    );
};
