import { Box, Fade, Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type TProps = {
    Header: ReactNode;
    title?: string;
    helpedText?: string;
    CenterSlot?: ReactNode;
};

export const InfoTemplate = ({
    Header,
    title,
    helpedText,
    CenterSlot,
}: TProps) => {
    return (
        <Stack
            direction="column"
            height="100dvh"
            sx={theme => ({ background: theme.palette.bg.primaryInvert })}>
            {Header}
            <Fade timeout={2000} in={Boolean(title)}>
                <Grid
                    direction="column"
                    container
                    textAlign={{ xs: 'center', md: 'start' }}
                    flex={1}
                    sx={{ wordBreak: 'break-word' }}>
                    <Grid item xs={0} md={2} />
                    <Grid item xs={1} paddingLeft={{ xs: 0, md: '15%' }}>
                        <Typography
                            variant="h2"
                            minHeight="40px"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} paddingLeft={{ xs: 0, md: '15%' }}>
                        <Typography
                            variant="body1"
                            minHeight="40px"
                            color={theme =>
                                theme.palette.typography.primaryInvert
                            }>
                            {helpedText}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Box
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            {CenterSlot}
                        </Box>
                    </Grid>
                </Grid>
            </Fade>
        </Stack>
    );
};
