export enum EDateFormat {
    /**
     * 2022-01-22
     */
    yyyyMMdd = 'yyyy-MM-dd',

    /**
     * 22.01.2022
     */
    ddMMyyyy = 'dd.MM.yyyy',

    /**
     * 12 января
     */
    ddMonth = 'dd MMMM',

    /**
     * 12 янв
     */
    ddMMM = 'dd MMM',

    /**
     * 12 января 1996
     */
    ddMonthyyyy = 'dd MMMM yyyy',

    /**
     * пн, вт, ср
     */
    d = 'EEEEEE',

    /**
     * 12, 31, 01
     */
    dd = 'dd',

    /**
     * 8:45
     */
    Hmm = 'H:mm',

    /**
     * 08:45
     */
    HHmm = 'HH:mm',

    /**
     * 23.11
     */
    dMM = 'd.MM',

    /**
     * 10 января, 10:00
     */
    ddMonthHHmm = 'dd MMMM, HH:mm',

    /**
     * 10 января 2022, 10:00
     */
    ddMonthyyyyHHmm = 'dd MMMM yyyy, HH:mm',

    /**
     * понедельник
     */
    eeee = 'EEEE',

    /**
     * январь 2022
     */
    MMMMyyyy = 'LLLL yyyy',
}
