/* eslint-disable boundaries/element-types */
//TODO (@OKA) refactor keys
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { productsKeys } from '@entities/products/model';
import { providersKeys } from '@entities/providers/model';
import { UnknownBusinessError, queryClient, userApi } from '@shared/api';
import { UserApiDeleteV1UserProvidersRequest } from '@shared/api/axios-client';

import { userKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseDeleteUserProvider = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useDeleteUserProvider = (props?: TUseDeleteUserProvider) => {
    const { mutateAsync, ...rest } = useMutation(
        userKeys.deleteProvider.queryKey,
        (
            userApiDeleteV1UserProvidersRequest: UserApiDeleteV1UserProvidersRequest,
        ) => {
            return userApi.deleteV1UserProviders(
                userApiDeleteV1UserProvidersRequest,
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(userKeys.providers.queryKey);
                queryClient.invalidateQueries(providersKeys.available.queryKey);
                queryClient.invalidateQueries(productsKeys.list._def);
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    const deleteUserProvider = useCallback(
        (
            userApiDeleteV1UserProvidersRequest: UserApiDeleteV1UserProvidersRequest,
        ) => {
            return mutateAsync(userApiDeleteV1UserProvidersRequest);
        },
        [mutateAsync],
    );

    return { ...rest, deleteUserProvider };
};
