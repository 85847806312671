import { Stack, Typography } from '@mui/material';

export const EmptyTableProducts = () => {
    return (
        <Stack
            direction="column"
            gap="10px"
            alignItems="center"
            paddingTop="60px">
            <Typography variant="h3">Ничего не нашлось</Typography>
            <Typography
                variant="body2"
                color={theme => theme.palette.typography.secondary}>
                Попробуйте изменить или очистить фильтры
            </Typography>
        </Stack>
    );
};
