import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

//TODO (@OKA) придумать как избежать
// eslint-disable-next-line boundaries/element-types
import { FeatureToggle } from '@features/feature-toggle';
import { UnknownBusinessError, productApi } from '@shared/api';
import { FavoritesListTotalQuantity } from '@shared/api/axios-client';

import { favoriteKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetFavorite = {
    onSuccess?: (data: AxiosResponse<FavoritesListTotalQuantity, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

const isFavorite = FeatureToggle.hasFeatureFlag('favorite');

export const useGetFavoriteCount = (props?: TUseGetFavorite) => {
    return useQuery(
        favoriteKeys.getFavoriteCount.queryKey,
        () =>
            productApi.getV1ProductFavoritesListTotalQuantity({
                headers: {
                    Authorization: 'Bearer',
                },
            }),
        {
            retry: 3,
            enabled: isFavorite,
            onSuccess: props?.onSuccess,
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
