import { Components, Theme } from '@mui/material';

export const MuiTabPanel: Components<Omit<Theme, 'components'>>['MuiTabPanel'] =
    {
        styleOverrides: {
            root: {
                padding: 0,
            },
        },
    };
