import { TFeatureFlags } from '@features/feature-toggle';
import { defaultFlags } from '@features/feature-toggle/default-flags';

import { TFeatureItem } from '../types';

export const mapTogglesToFlags = (flags: TFeatureItem[]) =>
    flags.reduce<TFeatureFlags>(
        (result, { value, isActive }) => ({
            ...result,
            [value]: isActive,
        }),
        defaultFlags,
    );
