import { TProductTable } from '@entities/products/types';
import { makeField } from '@entities/products/utils/make-product-table-field';
import { ProductInfo } from '@shared/api/axios-client';
import { dateUtils, numberUtils } from '@shared/lib/utils';

import { EmptyDate } from '../../ui/empty-date';
export const productMap = (
    products?: ProductInfo[] | null,
): TProductTable[] => {
    if (!products) {
        return [];
    }
    const bestPrice = products.reduce((acc, product) => {
        if (!product.warehouse) {
            return acc;
        }
        return product.warehouse.price < acc ? product.warehouse?.price : acc;
    }, Infinity);
    return products.map(product => {
        const isValidDate = Boolean(product.warehouse.shipmentDate);
        return {
            id: product.product?.id || '',
            providerId: product.providers?.id || '',
            warehouseId: product.warehouse?.id || '',
            brand: product.product.brand,
            fields: [
                makeField(product.providers?.name),
                makeField(product.product?.brand),
                makeField(product.product.vendorCode),
                makeField(product.product?.name, 'left', 3),
                makeField(product.warehouse?.used ? 'б/у' : 'новое'),
                makeField(isValidDate ? 'доставка' : 'самовывоз'), //TODO (SA) @OKA change logic
                isValidDate
                    ? makeField(
                          dateUtils.formatDate(
                              dateUtils.validateDate(
                                  product.warehouse?.shipmentDate || '',
                              ),
                              dateUtils.EDateFormat.ddMMyyyy,
                          ),
                          'right',
                      )
                    : {
                          Component: <EmptyDate />,
                      },
                makeField(
                    product.warehouse?.stock
                        ? String(product.warehouse.stock)
                        : '0',
                    'right',
                ),
                makeField(
                    numberUtils.formatCurrency(product.warehouse?.price || 0),
                    'right',
                    1,
                    true,
                    bestPrice === product.warehouse?.price
                        ? 'Лучшая цена'
                        : undefined,
                    '#FCFF51',
                ),
            ],
        };
    });
};
