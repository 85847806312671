import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ReactElement } from 'react';

import { TProfileInfo } from '@entities/user/types';
import { AccountCard } from '@entities/user/ui/account-card';
import { useStateModal } from '@shared/hooks';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

import { TMenuInfo } from './types';
import { SideMenu } from './ui';

type TProps = {
    profileInfo: TProfileInfo;
    menusInfo: TMenuInfo[];
    topbarComponents?: TTopBarComponent[];
    onCartClick: () => void;
    onLogout: () => void;
};

export type TTopBarComponent = {
    component: ReactElement;
    isVisible: boolean;
};

export const TopbarMenuWidget = ({
    profileInfo,
    menusInfo,
    topbarComponents,
    onLogout,
}: TProps) => {
    const theme = useTheme();
    const {
        isOpen: isDrawerOpen,
        close: closeDrawer,
        open: openDrawer,
    } = useStateModal();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const Topbar = isDesktop ? (
        <AccountCard
            firstName={profileInfo.firstName}
            middleName={profileInfo.middleName}
            email={profileInfo.email}
            avatarUrl={profileInfo.avatarUrl}
            onAvatarClick={openDrawer}
        />
    ) : (
        <IconButton
            onClick={openDrawer}
            data-testid={selectors.topbarMenuWidgetOpenDrawerButton}>
            <MenuIcon />
        </IconButton>
    );

    return (
        <>
            <Stack
                direction="row"
                gap={{ xs: '8px', md: '28px' }}
                paddingTop="5px"
                flexWrap="wrap">
                {topbarComponents?.map((item, index) => (
                    <Conditional condition={item.isVisible} key={index}>
                        {item.component}
                    </Conditional>
                ))}
                {Topbar}
            </Stack>
            <SideMenu
                profileInfo={profileInfo}
                menusInfo={menusInfo}
                isSideMenuOpen={isDrawerOpen}
                onCloseSideMenu={closeDrawer}
                onLogout={onLogout}
            />
        </>
    );
};
