import { Stack, Typography } from '@mui/material';

import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    title: string;
    description: string;
    counter?: number;
    withDescription: boolean;
};

export const ChangeProvidersPageHeader = ({
    title,
    description,
    counter,
    withDescription,
}: TProps) => {
    return (
        <Stack
            gap="15px"
            alignItems={{ xs: 'center', md: 'start' }}
            textAlign={{ xs: 'center', md: 'start' }}>
            <Stack direction="row" gap="10px">
                <Typography variant="h2">{title}</Typography>
                <Conditional condition={!withDescription && Boolean(counter)}>
                    <Typography
                        data-testid={selectors.accountChangeProvidersCounter}
                        variant="h3"
                        color={theme => theme.palette.typography.tertiary}>
                        {counter}
                    </Typography>
                </Conditional>
            </Stack>
            <Conditional condition={withDescription}>
                <Typography
                    variant="body2"
                    color={theme => theme.palette.typography.secondary}>
                    {description}
                </Typography>
            </Conditional>
        </Stack>
    );
};
