import { Skeleton, Stack } from '@mui/material';

export const CatalogPageSkeleton = () => {
    return (
        <Stack gap="10px">
            {[0, 1, 2, 3, 4, 5, 6, 7].map(item => (
                <Skeleton
                    key={item}
                    variant="rounded"
                    width="100%"
                    height="64px"
                />
            ))}
        </Stack>
    );
};
