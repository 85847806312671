import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, cartApi, queryClient } from '@shared/api';
import { V1CartProductRequest } from '@shared/api/axios-client';

import { cartKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseAddToCart = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useAddToCart = (props?: TUseAddToCart) => {
    const { mutateAsync, ...rest } = useMutation(
        cartKeys.addToCart.queryKey,
        ({
            v1CartProductRequest,
        }: {
            v1CartProductRequest: V1CartProductRequest;
        }) => {
            return cartApi.postV1CartProduct(
                {
                    v1CartProductRequest,
                },
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(cartKeys.getCart.queryKey);
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    const addToCart = useCallback(
        (v1CartProductRequest: V1CartProductRequest) => {
            return mutateAsync({
                v1CartProductRequest,
            });
        },
        [mutateAsync],
    );

    return { ...rest, addToCart };
};
