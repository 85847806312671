import { TProductTable } from '@entities/products/types';
import { makeField } from '@entities/products/utils/make-product-table-field';
import { PartTypeGroup } from '@shared/api/axios-client';

export const mapProduct = (
    productsGroups?: PartTypeGroup[],
): TProductTable[] => {
    if (!productsGroups) {
        return [];
    }
    return productsGroups.map((group, index) => {
        const currentNumber = index + 1;
        const stringNumber =
            currentNumber >= 10
                ? String(currentNumber)
                : `${0}${currentNumber}`;
        return {
            id: group.brand || '',
            fields: [
                makeField(stringNumber, 'left', 1, true),
                makeField(group.partTypeName, 'left', 5, true),
                makeField(group.brand, 'left', 2),
                makeField(group.vendorCode, 'left', 3),
            ],
        };
    });
};
