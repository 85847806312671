import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { UnknownBusinessError, userApi } from '@shared/api';
import { CartAmountResponse } from '@shared/api/axios-client';

import { cartKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetCart = {
    onSuccess?: (data: AxiosResponse<CartAmountResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useGetCartCount = (props?: TUseGetCart) => {
    return useQuery(
        cartKeys.getTotalCart.queryKey,
        () =>
            userApi.getV1UserCartAmount({
                headers: {
                    Authorization: 'Bearer',
                },
            }),
        {
            retry: 3,
            onSuccess: props?.onSuccess,
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
