import { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<
    Omit<Theme, 'components'>
>['MuiFormHelperText'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            fontFamily: theme.typography.caption2.fontFamily,
            fontSize: theme.typography.caption2.fontSize,
            fontWeight: theme.typography.caption2.fontWeight,
            lineHeight: theme.typography.caption2.lineHeight,
            color: theme.palette.typography.secondary,
            marginTop: '15px',
            '&.Mui-disabled': {
                color: theme.palette.text.disabled,
            },
            '&.Mui-error': {
                color: theme.palette.typography.negative,
            },
        }),
    },
};
