import { Components, Theme } from '@mui/material';

export const MuiAccordionSummary: Components<
    Omit<Theme, 'components'>
>['MuiAccordionSummary'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            padding: 0,
            '.MuiAccordionSummary-content': {
                padding: '20px 0',
                margin: 0,
            },
            '.Mui-expanded': {
                margin: '0 !important',
            },
        }),
    },
};
