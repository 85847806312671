import { useUnit } from 'effector-react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useAddClickEcommerce, useGetAdvert } from '@entities/adds/model';
import { mapAdds, mapEcommerceAddPromo } from '@entities/adds/utils/mappers';
import { $userInfo } from '@entities/auth/model/auth-domain';
import { useGetProducts, useGetProductsV2 } from '@entities/products/model';
import {
    $searchHistory,
    addToSearchHistory,
} from '@entities/user/model/user-domain';
import { FeatureToggle } from '@features/feature-toggle';
import { FormSearchHeaderProvider } from '@features/form-search-header';
import { ENV } from '@shared/config/constants';
import { useNavigateExternal, useYandexEcommerce } from '@shared/hooks';
import { CatalogPageTemplate } from '@shared/ui/templates/catalog-page-template';
import { HeaderWidgetConnector } from '@widgets/header';
import { THeaderPaths } from '@widgets/header';

import { BrandPage } from './brand-page';
import { BrandPageSkeleton } from './ui/catalog-page-skeleton';
import { mapProduct } from './utils/mappers';
import { catalogPaths } from '../paths';

type TProps = {
    externalRoutes: {
        accountProviderPath: string;
        providerHomePath: string;
    };
    headerPaths: THeaderPaths;
};

const IS_TEMP_V2_PRODUCT = FeatureToggle.hasFeatureFlag('tempV2Product');
const IS_PROVIDERS_ENABLED = FeatureToggle.hasFeatureFlag('providers');

export const BrandPageConnector = ({ externalRoutes, headerPaths }: TProps) => {
    const [params, setParams] = useSearchParams();
    const userInfo = useUnit($userInfo);
    const navigate = useNavigate();
    const navigateExt = useNavigateExternal();
    const { enqueueSnackbar } = useSnackbar();
    const { promoViewAction } = useYandexEcommerce();
    const onAddClick = useAddClickEcommerce();
    const searchHistory = useUnit($searchHistory);
    const initSearch = params.get('search');
    const getErrorMessage = () =>
        enqueueSnackbar('что-то пошло не так', {
            variant: 'error',
            description: 'обновите страницу или попробуйте позже',
        });
    const {
        data: dataV1,
        isLoading: isV1Loading,
        isRefetching: isV1Refetching,
    } = useGetProducts({
        body: {
            vendorCode: initSearch || '',
        },
        onError: getErrorMessage,
    });

    const {
        data: dataV2,
        isLoading: isV2Loading,
        isRefetching: isV2Refetching,
    } = useGetProductsV2({
        body: {
            searchProduct: {
                vendorCode: initSearch || '',
                limit: 999, //TODO (@OKA) - реализовать пагинацию
                page: 1,
            },
        },
        onError: getErrorMessage,
    });

    const { data, isLoading, isRefetching } = {
        data: IS_TEMP_V2_PRODUCT ? dataV2 : dataV1,
        isLoading: IS_TEMP_V2_PRODUCT ? isV2Loading : isV1Loading,
        isRefetching: IS_TEMP_V2_PRODUCT ? isV2Refetching : isV1Refetching,
    };

    const { data: addsData } = useGetAdvert({
        body: { pageType: 'searchResult' },
    });
    const adds = useMemo(
        () => mapAdds(addsData?.data.advertising_banners),
        [addsData],
    );

    useEffect(() => {
        if (addsData) {
            promoViewAction(
                mapEcommerceAddPromo(
                    addsData.data.advertising_banners,
                    String(userInfo?.clientId) || '',
                    'results',
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addsData, userInfo?.clientId]);

    useEffect(() => {
        if (
            data?.data.providersError?.find(error => error.errorCode === '500')
        ) {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже', //TODO @OKA (des) change error
            });
        }
    }, [data?.data.providersError, enqueueSnackbar]);

    const handleSubmit = useCallback(
        (search: string) => {
            addToSearchHistory(search);
            setParams({ search: search });
        },
        [setParams],
    );
    const handleSelect = useCallback(
        (brand: string) => {
            navigate(
                `${catalogPaths.catalog.absolute}?brand=${brand}&search=${initSearch}`,
            );
        },
        [initSearch, navigate],
    );

    const handleChangeProviderClick = useCallback(() => {
        navigate(externalRoutes.accountProviderPath);
    }, [externalRoutes.accountProviderPath, navigate]);

    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);

    const handleAddProvider = useCallback(() => {
        navigate(externalRoutes.providerHomePath);
    }, [externalRoutes.providerHomePath, navigate]);

    const handlePropositionProvider = useCallback(() => {
        navigateExt(ENV.yandexPropositionForm);
    }, [navigateExt]);

    const handleAddClick = useCallback(
        (id: string) => {
            if (!addsData) {
                return;
            }
            onAddClick({
                targetAddId: id,
                adds: addsData.data.advertising_banners,
                userId: String(userInfo?.clientId) || '',
                position: 'results',
            });
        },
        [addsData, onAddClick, userInfo?.clientId],
    );

    const products = mapProduct(data?.data.partTypeGroup);
    const providersError = useMemo(
        () =>
            data?.data.providersError
                ?.filter(error => error.errorCode === '401')
                .map(error => error.providerName) || [],
        [data?.data.providersError],
    );

    if (data?.data.partTypeGroup && data.data.partTypeGroup.length === 1) {
        return (
            <Navigate
                to={`${catalogPaths.catalog.absolute}?search=${initSearch}&brand=${data.data.partTypeGroup[0].brand}`}
                replace
            />
        );
    }

    if (isLoading || isRefetching) {
        return <BrandPageSkeleton />;
    }

    return (
        <CatalogPageTemplate
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    withProfile
                    paths={headerPaths}>
                    <FormSearchHeaderProvider
                        options={searchHistory}
                        initSearch={initSearch || ''}
                        onSubmit={data => handleSubmit(data.search)}
                    />
                </HeaderWidgetConnector>
            }>
            <BrandPage
                adds={adds}
                products={products}
                onAddClick={handleAddClick}
                onSelect={handleSelect}
                onHelpClick={handleSupportClick}
                providersError={providersError}
                onPropositionProvider={handlePropositionProvider}
                onChangeProvider={
                    IS_PROVIDERS_ENABLED ? handleChangeProviderClick : undefined
                }
                onAddProvider={
                    IS_PROVIDERS_ENABLED ? handleAddProvider : undefined
                }
            />
        </CatalogPageTemplate>
    );
};
