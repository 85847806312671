import { TAccountInfo, TUserInfo } from '@entities/auth/types';

const getAvatarUrl = (avatarId: string) =>
    `https://avatars.mds.yandex.net/get-yapic/${avatarId}/islands-200`;

export const mapUserInfo = (
    profile: TUserInfo | null,
    cartAmount: number,
): TAccountInfo => {
    return {
        firstName: profile?.firstName || '',
        middleName: profile?.lastName || '',
        login: profile?.login || '',
        email: profile?.email || '',
        avatarUrl: profile?.avatarId ? getAvatarUrl(profile.avatarId) : '',
        phone: profile?.phone || '',
        clientID: String(profile?.clientId) || '',
        cartAmount: cartAmount,
    };
};
