import { Container, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { TCredentialField } from '@entities/providers/types';
import { Conditional } from '@shared/ui/atoms/conditional';

import { ProviderInstantOrderInfo } from '../provider-instant-order-info';

type TProps = {
    title: string;
    fields: TCredentialField[];
    instantOrderAvailable?: boolean;
    FormComponent: ReactNode;
};

export const ProviderAddCredential = ({
    title,
    fields,
    instantOrderAvailable,
    FormComponent,
}: TProps) => {
    if (!fields.length) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <Stack direction="column" gap="40px" marginTop={{ md: '5%' }}>
                <Typography
                    variant="h2"
                    textAlign={{ xs: 'center', md: 'start' }}>
                    {title}
                </Typography>
                <Conditional condition={instantOrderAvailable}>
                    <ProviderInstantOrderInfo />
                </Conditional>
                {FormComponent}
            </Stack>
        </Container>
    );
};
