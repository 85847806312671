import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { FeatureToggle } from '@features/feature-toggle';

import { AppProviders } from './app-providers';
import { routes } from './routes';

const router = createBrowserRouter(routes);
FeatureToggle.applyTo(window);

export const App = () => {
    return (
        <AppProviders>
            <RouterProvider router={router}></RouterProvider>
        </AppProviders>
    );
};
