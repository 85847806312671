import { createDomain, restore } from 'effector';
import { persist as persistSession } from 'effector-storage/session';

export const userDomain = createDomain('user');
export const resetUserDomainStores = userDomain.createEvent();

userDomain.onCreateStore(store => {
    store.reset(resetUserDomainStores);
});

export const setSearchHistory = userDomain.createEvent<string[]>();
export const addToSearchHistory = userDomain.createEvent<string>();
export const $searchHistory = restore(setSearchHistory, []);

$searchHistory.on(addToSearchHistory, (state, payload) => {
    const searchHistory = state.filter(item => item !== payload);
    return [payload, ...searchHistory];
});

persistSession({
    store: $searchHistory,
    key: 'search-history',
});
