import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { FeatureToggle, TFlags } from '@features/feature-toggle';

type TProps = {
    children: ReactElement | null;
    featureFlag: TFlags;
    fallbackRoute: string;
};

export const FeatureToggleGuard = ({
    children,
    featureFlag,
    fallbackRoute,
}: TProps) => {
    const isEnabled = FeatureToggle.hasFeatureFlag(featureFlag);
    if (!isEnabled) {
        return <Navigate to={fallbackRoute} />;
    }

    return children;
};
