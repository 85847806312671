import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';

import { TAdd } from '@entities/adds/types';
import { FeatureToggle } from '@features/feature-toggle';
import { FormSearchSuggestProvider } from '@features/form-search-suggest';
import { Conditional } from '@shared/ui/atoms/conditional';

import { SearchAdds } from './ui/search-adds';

type TProps = {
    initSearch: string;
    suggests: string[];
    adds: TAdd[];
    onSubmit: (search: string) => void;
    onAddClick: (id: string) => void;
};

const isAddEnabled = FeatureToggle.hasFeatureFlag('adds');

export const SearchPage = ({
    initSearch,
    suggests,
    adds,
    onSubmit,
    onAddClick,
}: TProps) => {
    return (
        <Stack
            sx={{ paddingX: { xs: '8px', md: 0 } }}
            gap={{ xs: '16px', md: '128px' }}>
            <Stack direction="row" width="100%" position="relative">
                <SearchIcon
                    sx={theme => ({
                        marginTop: '30px',
                        color: theme.palette.icon.primary,
                        fontSize: '30px',
                        left: '-40px',
                        position: 'absolute',
                    })}
                />
                <FormSearchSuggestProvider
                    onSubmit={data => onSubmit(data.search)}
                    initSearch={initSearch}
                    options={suggests}
                />
            </Stack>
            <Conditional condition={isAddEnabled}>
                <SearchAdds adds={adds} onAddClick={onAddClick} />
            </Conditional>
        </Stack>
    );
};
