import { RouteObject } from 'react-router-dom';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggleGuard } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header';

import { ProviderPageConnector } from './home/provider-page-connector';
import { providerPaths } from './paths';

type TExternalPaths = {
    catalogHomePath: string;
    accountProviderPath: string;
    headerPaths: THeaderPaths;
};

export const providerRoutes = ({
    catalogHomePath,
    accountProviderPath,
    headerPaths,
}: TExternalPaths): RouteObject[] => [
    {
        path: providerPaths.home.absolute,
        element: (
            <FeatureToggleGuard
                featureFlag="providers"
                fallbackRoute={catalogHomePath}>
                <>
                    <MetaTitle>Поставщики</MetaTitle>
                    <ProviderPageConnector
                        headerPaths={headerPaths}
                        externalRoutes={{
                            catalogHomePath,
                            accountProviderPath,
                        }}
                    />
                </>
            </FeatureToggleGuard>
        ),
    },
];
