import { RouteObject } from 'react-router-dom';

import { THeaderPaths } from '@widgets/header/types';

import { NotFoundConnector } from './not-found';
import { errorPaths } from './paths';

type TExternalRoutes = {
    catalogHomePath: string;
    headerPaths: THeaderPaths;
};

export const errorRoutes = ({
    catalogHomePath,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: errorPaths.home.absolute,
        element: (
            <NotFoundConnector
                externalRoutes={{ catalogHomePath }}
                headerPaths={headerPaths}
            />
        ),
    },
    {
        path: '*',
        element: (
            <NotFoundConnector
                externalRoutes={{ catalogHomePath }}
                headerPaths={headerPaths}
            />
        ),
    },
];
