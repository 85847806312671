import { RouteObject } from 'react-router-dom';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggleGuard } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';
import { THeaderPaths } from '@widgets/header/types';

import { FavoritePageConnector } from './favorite-page-connector';
import { favoritePaths } from './paths';

type TExternalRoutes = {
    errorHomePath: string;
    catalogPaths: (vendorCode: string, brand: string) => string;
    headerPaths: THeaderPaths;
};

export const favoriteRoutes = ({
    errorHomePath,
    catalogPaths,
    headerPaths,
}: TExternalRoutes): RouteObject[] => [
    {
        path: favoritePaths.home.absolute,
        element: (
            <FeatureToggleGuard
                fallbackRoute={errorHomePath}
                featureFlag="favorite">
                <>
                    <MetaTitle>Избранное</MetaTitle>
                    <FavoritePageConnector
                        externalRoutes={{ catalogPaths }}
                        headerPaths={headerPaths}
                    />
                </>
            </FeatureToggleGuard>
        ),
    },
];
