import { Outlet, RouteObject } from 'react-router-dom';

// eslint-disable-next-line boundaries/element-types
import { FeatureToggleGuard, Public } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { CompanyAuthPageConnector } from './company-auth';
import { DriverAuthPageConnector } from './driver-auth';
import { AuthPageConnector } from './home';
import { authPaths } from './paths';

type TExternalRoutes = {
    clientIdHomePath: string;
    providerHomePath: string;
    errorHomePath: string;
};

export const authRoutes = ({
    clientIdHomePath,
    providerHomePath,
    errorHomePath,
}: TExternalRoutes): RouteObject[] => [
    {
        element: (
            <Public fallbackRoutes={{ clientIdHomePath, providerHomePath }}>
                <>
                    <MetaTitle>Авторизация</MetaTitle>
                    <Outlet />
                </>
            </Public>
        ),
        children: [
            {
                path: authPaths.home.absolute,
                element: <AuthPageConnector />,
            },
            {
                path: authPaths.company.absolute,
                element: (
                    <FeatureToggleGuard
                        fallbackRoute={errorHomePath}
                        featureFlag="newses">
                        <CompanyAuthPageConnector />
                    </FeatureToggleGuard>
                ),
            },
            {
                path: authPaths.driver.absolute,
                element: (
                    <FeatureToggleGuard
                        fallbackRoute={errorHomePath}
                        featureFlag="newses">
                        <DriverAuthPageConnector />
                    </FeatureToggleGuard>
                ),
            },
        ],
    },
];
