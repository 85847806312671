import { CircularProgress } from '@mui/material';

export const LogoutPage = () => {
    return (
        <CircularProgress
            sx={theme => ({ color: theme.palette.common.white })}
            size="80px"
        />
    );
};
