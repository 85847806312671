import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HistoryIcon from '@mui/icons-material/History';
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    Stack,
    TextField,
    Typography,
    createFilterOptions,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    options: string[];
    placeholder: string;
    helperText?: string;
    onAutocomplete: () => void;
};

export type TFormData = {
    search: string;
};

export const FormSearchSuggest = ({
    options,
    placeholder,
    helperText,
    onAutocomplete,
}: TProps) => {
    const {
        formState: { errors },
        control,
        watch,
        reset,
    } = useFormContext<TFormData>();
    const filterOptions = createFilterOptions<string>({
        matchFrom: 'start',
        limit: 5,
    });
    return (
        <Controller
            name="search"
            control={control}
            render={({ field }) => (
                <Autocomplete
                    data-testid={selectors.formSearchSuggestAutocomplete}
                    freeSolo
                    disablePortal
                    filterSelectedOptions
                    disableClearable
                    clearIcon={false}
                    sx={{
                        '.MuiAutocomplete-inputRoot': {
                            fontSize: 52,
                        },
                    }}
                    componentsProps={{
                        paper: {
                            sx: {
                                boxShadow: 'none',
                                '.MuiAutocomplete-option': {
                                    padding: '0 !important',
                                },
                            },
                        },
                    }}
                    {...field}
                    onChange={(_, data) => {
                        field.onChange(data);
                        onAutocomplete();
                    }}
                    filterOptions={filterOptions}
                    options={options}
                    renderOption={(props, option) => (
                        <li
                            {...props}
                            data-testid={selectors.formSearchSuggestAutocompleteOption(
                                option,
                            )}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap="20px"
                                width="100%"
                                color={theme => theme.palette.icon.secondary}
                                sx={theme => ({
                                    '&:hover > div': {
                                        borderColor:
                                            theme.palette.border.border1,
                                    },
                                    '&:hover > svg': {
                                        color: theme.palette.border.border1,
                                    },
                                })}>
                                <HistoryIcon fontSize="large" />
                                <Box
                                    width="100%"
                                    paddingY="20px"
                                    sx={theme => ({
                                        borderBottom: `1px solid ${theme.palette.border.border3}`,
                                    })}>
                                    <Typography variant="subtitle1">
                                        {option}
                                    </Typography>
                                </Box>
                            </Stack>
                        </li>
                    )}
                    renderInput={params => (
                        <TextField
                            data-testid={
                                selectors.formSearchSuggestAutocompleteInput
                            }
                            placeholder={placeholder}
                            error={Boolean(errors.search)}
                            helperText={errors.search?.message || helperText}
                            onChange={data => {
                                field.onChange(data);
                            }}
                            autoComplete="off"
                            variant="standard"
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                autoComplete: 'off',
                                fullWidth: true,
                                endAdornment: (
                                    <Stack
                                        direction="row"
                                        gap="20px"
                                        alignItems="center">
                                        <Conditional
                                            condition={Boolean(errors.search)}>
                                            <ErrorOutlineIcon fontSize="large" />
                                        </Conditional>
                                        <Conditional
                                            condition={
                                                watch('search').length > 0
                                            }>
                                            <IconButton
                                                data-testid={
                                                    selectors.formSearchSuggestAutocompleteClearButton
                                                }
                                                onClick={() => reset()}
                                                color="buttonSecondaryText">
                                                <CloseOutlinedIcon
                                                    sx={{ fontSize: '30px' }}
                                                />
                                            </IconButton>
                                            <Button
                                                data-testid={
                                                    selectors.formSearchSuggestAutocompleteSubmitButton
                                                }
                                                variant="contained"
                                                color="buttonPrimary"
                                                type="submit">
                                                найти
                                            </Button>
                                        </Conditional>
                                    </Stack>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    );
};
