import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

import { EDateFormat } from './constants';

/**
 * Форматирование даты
 *
 * @param date {String|Date} - 2021-12-17T18:50:40.939Z
 * @param pattern {EDateFormat} - EDateFormat.ddMMyyyy
 * @returns {String}
 */

export const formatDate = (
    date: string | Date,
    pattern: EDateFormat,
): string => {
    const parsedDate = new Date(date);

    const formattedDate = format(parsedDate, pattern, {
        locale: ru,
    });

    return formattedDate;
};
