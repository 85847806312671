import { Skeleton, Stack } from '@mui/material';

export const NewsSkeleton = () => {
    return (
        <Stack direction="column" gap="20px">
            <Skeleton variant="rounded" height="40px" width="300px" />
            <Stack direction="row" gap="20px" flexWrap="wrap" width="100%">
                {[0, 1, 2, 3].map(item => (
                    <Skeleton
                        key={item}
                        variant="rounded"
                        height="330px"
                        width="270px"
                        sx={{ borderRadius: '15px' }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
