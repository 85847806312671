import { IS_DEV } from '@shared/config/constants';

import {
    TChangeFn,
    TFeatureFlags,
    TFeatureToggle,
    TGetterFn,
    THasFn,
    TSetterFn,
} from './types';

export const FEATURE_TOGGLE_KEY = `${process.env.REACT_APP_NAME}-feature-toggle-${process.env.REACT_APP_VERSION}`;

export const makeFeatureToggle = (defaultFlags: TFeatureFlags) => {
    const setFeatureFlags: TSetterFn = flags => {
        localStorage.setItem(FEATURE_TOGGLE_KEY, JSON.stringify(flags));
    };

    const getFeatureFlags: TGetterFn = () => {
        const localStorageFlags = localStorage.getItem(FEATURE_TOGGLE_KEY);

        if (!localStorageFlags) {
            setFeatureFlags(defaultFlags);
            return defaultFlags;
        } else {
            try {
                return JSON.parse(localStorageFlags);
            } catch (error) {
                console.log(`JSON isn't valid - ${error}`);
                return defaultFlags;
            }
        }
    };

    const hasFeatureFlag: THasFn = flagName => {
        const flags = getFeatureFlags();
        return flags[flagName] ?? defaultFlags[flagName];
    };

    const changeFeatureFlag: TChangeFn = (flagName, flagValue) => {
        const currentFlags = getFeatureFlags();

        const newFlags = { ...currentFlags, [flagName]: flagValue };
        setFeatureFlags(newFlags);
    };

    const applyTo = <O extends TFeatureToggle>(object: O) => {
        if (IS_DEV) {
            object.setFeatureFlags = setFeatureFlags;
            object.getFeatureFlags = getFeatureFlags;
            object.hasFeatureFlag = hasFeatureFlag;
            object.changeFeatureFlag = changeFeatureFlag;
        }
    };

    return {
        setFeatureFlags,
        getFeatureFlags,
        hasFeatureFlag,
        changeFeatureFlag,
        applyTo,
    };
};
