import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogin } from '@entities/auth';
import {
    updateLogin,
    updateSession,
    updateUserInfo,
} from '@entities/auth/model/auth-domain';
import { useGetNews, useNewsClickEcommerce } from '@entities/news/model';
import { NewsModal } from '@entities/news/ui/news-modal';
import { mapNews } from '@entities/news/utils/mappers';
import { mapEcommerceBannerPromo } from '@entities/news/utils/mappers/ecommerce-mappers';
import {
    useNavigateExternal,
    useYandexEcommerce,
    useYandexReachGoal,
} from '@shared/hooks';
import { Logo } from '@shared/ui/atoms/logo';
import { PageTemplate } from '@shared/ui/templates/page-template';
import {
    OAuthYandexConnector,
    TYandexAuthResponse,
} from '@widgets/oauth-yandex';

import { CompanyAuthPage } from './company-auth-page';
import { authPaths } from '../paths';

type TActiveBanner = {
    sectionId: string;
    bannerId: string;
};

export const CompanyAuthPageConnector = () => {
    const navigateExt = useNavigateExternal();
    const navigate = useNavigate();
    const [modalBanner, setModalBanner] = useState<TActiveBanner>();
    const onNewsClick = useNewsClickEcommerce();
    const { promoViewAction } = useYandexEcommerce();
    const { data, isLoading } = useGetNews({
        body: { sectionName: 'commercial' },
    });

    useEffect(() => {
        if (!data) {
            return;
        }
        promoViewAction(mapEcommerceBannerPromo(data.data.sections, 'park'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onLogoClick = useCallback(
        () => navigate(authPaths.home.absolute),
        [navigate],
    );
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const { login } = useLogin({
        onSuccess: ({ data }) => {
            updateUserInfo(data);
            updateSession(data);
            updateLogin(true);
        },
        onError: () => {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });
    const successHandler = useCallback(
        (data: TYandexAuthResponse) => {
            if (data.access_token) {
                fireProductEvent('yandexOauth');
                updateUserInfo({ yaToken: data.access_token });
                login({ authToken: data.access_token });
            }
        },
        [fireProductEvent, login],
    );
    const newses = useMemo(() => mapNews(data?.data), [data?.data]);
    const modalBannerInfo = useMemo(
        () =>
            newses
                .find(news => news.id === modalBanner?.sectionId)
                ?.banners.find(banner => banner.id === modalBanner?.bannerId),
        [modalBanner, newses],
    );

    const handleCloseModal = useCallback(() => {
        setModalBanner(undefined);
    }, []);
    const handleOpenModal = useCallback(
        (sectionId: string, bannerId: string) => {
            setModalBanner({ sectionId, bannerId });
            if (!data) {
                return;
            }
            onNewsClick({
                targetBannerId: bannerId,
                targetSectionId: sectionId,
                sections: data.data.sections,
                positions: 'park',
            });
        },
        [data, onNewsClick],
    );
    const handleClickRedirect = useCallback(() => {
        navigateExt(modalBannerInfo?.url || '');
        const targetSection = data?.data.sections.find(
            section => section.id === modalBanner?.sectionId,
        );
        if (!data || !modalBanner || !targetSection) {
            return;
        }
        fireProductEvent('openNews', {
            newsId: `news${targetSection.id}`,
            newsName: targetSection.sectionName,
            newsCreative: modalBanner.bannerId,
            newsPosition: 'park',
        });
    }, [
        data,
        fireProductEvent,
        modalBanner,
        modalBannerInfo?.url,
        navigateExt,
    ]);
    return (
        <PageTemplate
            variant="medium"
            withHeadSpace
            Header={
                <Logo
                    color={theme => theme.palette.typography.primary}
                    sx={{ paddingTop: '5px', paddingRight: '10px' }}
                    onClick={onLogoClick}
                />
            }>
            <CompanyAuthPage
                onBannerClick={handleOpenModal}
                newses={newses}
                isLoading={isLoading}
                AuthControl={
                    <OAuthYandexConnector
                        onSuccess={successHandler}
                        onError={() => null}
                        paths={{
                            authHomePath: authPaths.home.absolute,
                            authCompanyPath: authPaths.company.absolute,
                        }}
                    />
                }
            />
            <NewsModal
                open={Boolean(modalBanner)}
                title={modalBannerInfo?.title || ''}
                imgUrl={modalBannerInfo?.pictureUrl || ''}
                buttonText={modalBannerInfo?.buttonText || ''}
                text={modalBannerInfo?.fullText || ''}
                onClick={handleClickRedirect}
                onClose={handleCloseModal}
            />
        </PageTemplate>
    );
};
