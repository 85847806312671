import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { UnknownBusinessError, cartApi, queryClient } from '@shared/api';
import {
    CartApiGetV1CartExportRequest,
    V1CartExportResponse,
} from '@shared/api/axios-client';

import { cartKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseExportCart = {
    onSuccess?: (res: AxiosResponse<V1CartExportResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useExportCart = (props?: TUseExportCart) => {
    const { mutateAsync, ...rest } = useMutation(
        cartKeys.exportCart.queryKey,
        (cartApiGetV1CartExportRequest: CartApiGetV1CartExportRequest) =>
            cartApi.getV1CartExport(cartApiGetV1CartExportRequest, {
                headers: {
                    Authorization: 'Bearer',
                },
            }),
        {
            onSuccess: data => {
                queryClient.invalidateQueries(cartKeys.getCart.queryKey);
                props?.onSuccess && props.onSuccess(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    return { ...rest, exportCart: mutateAsync };
};
