import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { ZodString, z } from 'zod';

import { TCredentialField } from '@entities/providers/types';

import { FormProviderCredential, TFormData } from './form-provider-credential';

type TProps = {
    fields: TCredentialField[];
    defaultValues?: TFormData;
    onSubmit: (data: TFormData) => void;
    onProviderRemove: () => void;
};

type TZodSchema = z.ZodPipeline<
    z.ZodEffects<ZodString, string, string>,
    ZodString
>;

export const FormProviderCredentialProvider = ({
    fields,
    defaultValues,
    onSubmit,
    onProviderRemove,
}: TProps) => {
    const fieldsNames = fields.reduce<{
        schema?: { [key: string]: TZodSchema };
        defaultValues?: { [key: string]: string };
    }>(
        (acc, item) => {
            const schema = {
                [item.code]: z
                    .string({ required_error: 'обязательно для заполнения' })
                    .transform(string => string.trim())
                    .pipe(
                        z
                            .string()
                            .min(1, 'обязательно для заполнения')
                            .max(100, 'максимум 100 символов'),
                    ),
            };
            const defaultValue = {
                [item.code]: defaultValues?.[item.code] || '',
            };
            return {
                schema: { ...acc.schema, ...schema },
                defaultValues: { ...acc.defaultValues, ...defaultValue },
            };
        },
        { schema: undefined, defaultValues: undefined },
    );

    const schema = fieldsNames.schema && z.object(fieldsNames.schema);
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: schema && zodResolver(schema),
        defaultValues: fieldsNames.defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormProviderCredential
                    fields={fields}
                    onProviderRemove={onProviderRemove}
                    isChange={Boolean(defaultValues)}
                />
            </form>
        </FormProvider>
    );
};
