import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, authApi } from '@shared/api';
import {
    ErrorModel,
    PostV2LoginRequest,
    PostV2LoginResponse,
} from '@shared/api/axios-client';

import { authKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError | ErrorModel;

type TUseLogin = {
    onSuccess?: (data: AxiosResponse<PostV2LoginResponse, any>) => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useLogin = (props?: TUseLogin) => {
    const { mutateAsync, ...rest } = useMutation(
        authKeys.login.queryKey,
        ({ authToken }: PostV2LoginRequest) => {
            return authApi.postV2Login({ postV2LoginRequest: { authToken } });
        },
        {
            onSuccess: data => {
                props?.onSuccess && props.onSuccess(data);
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code) {
                    props.onError(response.data);
                    return;
                }
                props.onError({
                    code: 'unknown_code',
                    message: 'что-то пошло не так',
                    response,
                });
            },
        },
    );

    const login = useCallback(
        ({ authToken }: PostV2LoginRequest) => {
            return mutateAsync({ authToken });
        },
        [mutateAsync],
    );

    return { ...rest, login };
};
