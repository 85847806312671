import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { TCredentialField } from '@entities/providers/types';
import { selectors } from '@shared/test/selectors';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    fields: TCredentialField[];
    isChange: boolean;
    onProviderRemove: () => void;
};

export type TFormData = {
    [key: string]: string;
};

export const FormProviderCredential = ({
    fields,
    isChange,
    onProviderRemove,
}: TProps) => {
    const { formState, control, setValue } = useFormContext<TFormData>();
    const theme = useTheme();
    return (
        <Stack direction="column" gap={{ md: '40px' }}>
            {fields.map(item => (
                <Controller
                    control={control}
                    key={item.code}
                    name={item.code}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            data-testid={selectors.formProviderCredentialInput}
                            label={item.label}
                            key={item.code}
                            variant="standard"
                            inputRef={ref}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <Conditional
                                            condition={Boolean(
                                                formState.errors[item.code],
                                            )}>
                                            <ErrorOutlineIcon fontSize="large" />
                                        </Conditional>
                                        <Conditional
                                            condition={Boolean(field.value)}>
                                            <IconButton
                                                data-testid={
                                                    selectors.formProviderCredentialClearButton
                                                }
                                                onClick={() =>
                                                    setValue(item.code, '')
                                                }
                                                color="buttonSecondaryText">
                                                <CancelIcon fontSize="large" />
                                            </IconButton>
                                        </Conditional>
                                    </>
                                ),
                            }}
                            InputLabelProps={{
                                style: {
                                    color: formState.errors[item.code]
                                        ? theme.palette.typography.negative
                                        : theme.palette.typography.secondary,
                                },
                            }}
                            error={Boolean(formState.errors[item.code])}
                            helperText={
                                formState.errors[item.code]?.message || ' '
                            }
                            {...field}
                        />
                    )}
                />
            ))}
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                gap="16px"
                marginTop={{ xs: '10px', md: 0 }}>
                <Button
                    data-testid={selectors.formProviderCredentialSubmitButton}
                    variant="contained"
                    color="buttonPrimary"
                    type="submit"
                    sx={{ minWidth: '150px' }}>
                    {isChange ? 'изменить' : 'добавить'}
                </Button>
                <Button
                    data-testid={selectors.formProviderCredentialRemoveButton}
                    variant="contained"
                    color="buttonSecondaryEnabled"
                    onClick={onProviderRemove}
                    sx={{ minWidth: '150px' }}>
                    удалить поставщика
                </Button>
            </Stack>
        </Stack>
    );
};
