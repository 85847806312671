import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormSearchSuggest, TFormData } from './form-search-suggest';

type TProps = {
    options: string[];
    initSearch: string;
    onSubmit: (data: TFormData) => void;
};

const schema = z.object({
    search: z
        .string()
        .transform(string => string.trim())
        .pipe(z.string().nonempty('Обязательное поле')),
});

export const FormSearchSuggestProvider = ({
    options,
    initSearch,
    onSubmit,
}: TProps) => {
    const methods = useForm<TFormData>({
        mode: 'onSubmit',
        resolver: zodResolver(schema),
        defaultValues: {
            search: initSearch,
        },
    });

    const handleAutocomplete = useCallback(() => {
        methods.handleSubmit(onSubmit)();
    }, [methods, onSubmit]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={methods.handleSubmit(onSubmit)}
                autoComplete="off"
                style={{ width: '100%' }}>
                <FormSearchSuggest
                    options={options}
                    placeholder="поиск запчастей"
                    helperText="введите артикул, например: A6510302501"
                    onAutocomplete={handleAutocomplete}
                />
            </form>
        </FormProvider>
    );
};
