import {
    Badge,
    BadgeOwnerState,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';

import { selectors } from '@shared/test/selectors';

type TProps = {
    icon: JSX.Element;
    title: string;
    badgeContent?: number;
    badgeColor?: BadgeOwnerState['color'];
    onClick: () => void;
};

export const TopbarMenuItem = ({
    icon,
    title,
    badgeContent,
    badgeColor,
    onClick,
}: TProps) => {
    return (
        //TODO (@OKA) fix button in button
        <IconButton
            data-testid={selectors.topbarMenuWidgetMenuItemFeatureLink(title)}
            onClick={onClick}>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Badge badgeContent={badgeContent} color={badgeColor}>
                    <Stack color={theme => theme.palette.icon.primary}>
                        <IconButton
                            data-testid={
                                selectors.topbarMenuWidgetMenuItemButton
                            }
                            color="buttonPrimary">
                            {icon}
                        </IconButton>
                    </Stack>
                </Badge>
                <Typography variant="caption1">{title}</Typography>
            </Stack>
        </IconButton>
    );
};
