import { Components, Theme } from '@mui/material';

export const MuiIconButton: Components<
    Omit<Theme, 'components'>
>['MuiIconButton'] = {
    variants: [
        {
            props: {
                color: 'buttonPrimary',
            },
            style: ({ theme }) => ({
                color: theme.palette.icon.primary,
                '&:hover': {
                    color: theme.palette.buttonPrimary.dark,
                },
                '&:focus': {
                    color: theme.palette.buttonPrimary.dark,
                },
                '&:disabled': {
                    color: theme.palette.button.buttonPrimaryState.disabled,
                },
            }),
        },
        {
            props: {
                color: 'buttonPrimaryInvert',
            },
            style: ({ theme }) => ({
                color: theme.palette.icon.primaryInvert,
                '&:hover': {
                    color: theme.palette.buttonPrimaryInvert.dark,
                },
                '&:focus': {
                    color: theme.palette.buttonPrimaryInvert.dark,
                },
                '&:disabled': {
                    color: theme.palette.button.buttonPrimaryState.disabled,
                },
            }),
        },
        {
            props: {
                color: 'buttonInvertSecondaryText',
            },
            style: ({ theme }) => ({
                color: theme.palette.icon.secondaryInvert,
                '&:hover': {
                    color: theme.palette.buttonSecondaryText.dark,
                },
                '&:focus': {
                    color: theme.palette.buttonSecondaryText.dark,
                },
                '&:disabled': {
                    color: theme.palette.button.buttonPrimaryState.disabled,
                },
            }),
        },
        {
            props: {
                color: 'buttonSecondaryText',
            },
            style: ({ theme }) => ({
                color: theme.palette.icon.secondary,
                '&:hover': {
                    color: theme.palette.buttonSecondaryText.dark,
                },
                '&:focus': {
                    color: theme.palette.buttonSecondaryText.dark,
                },
                '&:disabled': {
                    color: theme.palette.button.buttonPrimaryState.disabled,
                },
            }),
        },
    ],
    styleOverrides: {
        root: () => ({
            '&:hover': {
                background: 'transparent',
            },
            padding: 0,
        }),
    },
};
