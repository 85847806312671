export const accountPaths = {
    home: {
        absolute: '/account',
        relative: 'account',
    },
    providers: {
        absolute: '/account/providers',
        relative: 'account/providers',
    },
    help: {
        absolute: '/account/help',
        relative: 'account/help',
    },
    clientId: {
        absolute: '/account/clientId',
        relative: 'account/clientId',
    },
} as const;
