import { Fade, Grid, Stack, Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { TAdd } from '@entities/adds/types';
import { AddBlock } from '@entities/adds/ui/add-block';
import { TFavoriteItem } from '@entities/favorite/types';
import { FavoriteCardItem } from '@entities/favorite/ui/favorite-card-item';
import { FeatureToggle } from '@features/feature-toggle';
import { Conditional } from '@shared/ui/atoms/conditional';

import { FavoritePageSkeleton } from './ui/favorite-page-skeleton';

type TProps = {
    items: TFavoriteItem[];
    adds: TAdd[];
    count: number;
    isLoading: boolean;
    onNavigate: (vendorCode: string, brand: string) => void;
    onRemove: (id: string) => void;
    onAddClick: (id: string) => void;
};

const ITEM_BEFORE_ADD = 9;

const isAddEnabled = FeatureToggle.hasFeatureFlag('adds');

export const FavoritePage = ({
    count,
    items,
    adds,
    isLoading,
    onNavigate,
    onRemove,
    onAddClick,
}: TProps) => {
    const FavoriteItems = useMemo(
        () =>
            items.map(item => (
                <Fade in key={item.id}>
                    {/* https://stackoverflow.com/questions/74862197/typeerror-cannot-read-properties-of-null-reading-scrolltop-material-ui */}
                    <Grid item xs={12} sm={6} lg={4} xl={4}>
                        <FavoriteCardItem
                            title={item.productName}
                            subTitle={item.vendorCode}
                            subDescription={item.brand}
                            onAction={() =>
                                onNavigate(item.vendorCode, item.brand)
                            }
                            onRemove={() => onRemove(item.id)}
                        />
                    </Grid>
                </Fade>
            )),
        [items, onNavigate, onRemove],
    );
    const getWithAdds = useCallback(
        () =>
            FavoriteItems.reduce<ReactNode[]>((acc, item, index) => {
                if (index === 0) {
                    const add = adds[0];

                    return add
                        ? [
                              <Grid item>
                                  <AddBlock
                                      {...adds[0]}
                                      onClick={() => onAddClick(adds[0].id)}
                                  />
                              </Grid>,
                              item,
                          ]
                        : [item];
                }
                if (index % ITEM_BEFORE_ADD === 0) {
                    const add = adds[index / ITEM_BEFORE_ADD];
                    return add
                        ? [
                              ...acc,
                              <Grid item>
                                  <AddBlock
                                      {...adds[index / ITEM_BEFORE_ADD]}
                                      onClick={() =>
                                          onAddClick(
                                              adds[index / ITEM_BEFORE_ADD].id,
                                          )
                                      }
                                  />
                              </Grid>,
                              item,
                          ]
                        : [...acc, item];
                }
                return [...acc, item];
            }, []),
        [FavoriteItems, adds, onAddClick],
    );
    return (
        <Stack gap="56px" paddingTop="36px">
            <TransitionGroup
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                }}>
                {isLoading ? (
                    <FavoritePageSkeleton />
                ) : (
                    <Grid container direction="row" spacing="20px">
                        {isAddEnabled ? getWithAdds() : FavoriteItems}
                    </Grid>
                )}
                <Conditional condition={!Boolean(count) && !isLoading}>
                    <Typography
                        variant="h2"
                        color={theme => theme.palette.typography.secondary}>
                        В избранном пока ничего нет...
                    </Typography>
                    <Typography
                        variant="body2"
                        color={theme => theme.palette.typography.secondary}>
                        воспользуйтесь поиском, чтобы добавить товар
                    </Typography>
                    <Stack marginTop="80px">
                        <AddBlock
                            {...adds[0]}
                            onClick={() => onAddClick(adds[0].id)}
                        />
                    </Stack>
                </Conditional>
            </TransitionGroup>
        </Stack>
    );
};
