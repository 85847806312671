import { Box, Stack, styled } from '@mui/material';
import { ReactNode } from 'react';

import img from './assets/bg.png';

type TProps = {
    LeftSlot: ReactNode;
    RightSlot: ReactNode;
};

const ImageBox = styled(Stack)`
    min-height: 100dvh;
    background: linear-gradient(
            0deg,
            rgba(37, 40, 43, 0.9) 0%,
            rgba(37, 40, 43, 0.9) 100%
        ),
        url(${img}), #fff;
    background-blend-mode: normal, difference, normal;
    background-size: cover;
`;

export const AuthTemplate = ({ LeftSlot, RightSlot }: TProps) => {
    return (
        <Stack direction={{ xs: 'column-reverse', md: 'row' }}>
            <Box flex={2} paddingTop="20%" minHeight="100dvh">
                {LeftSlot}
            </Box>
            <ImageBox flex={1} paddingTop={{ md: '20%' }}>
                {RightSlot}
            </ImageBox>
        </Stack>
    );
};
