import { Components, Theme } from '@mui/material';

export const MuiTabs: Components<Omit<Theme, 'components'>>['MuiTabs'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiTabs-flexContainer': {
                gap: '30px',
            },
            '.MuiTab-root': {
                fontFamily: theme.typography.h3.fontFamily,
                fontSize: theme.typography.h3.fontSize,
                fontWeight: theme.typography.h3.fontWeight,
                lineHeight: theme.typography.h3.lineHeight,
                textTransform: 'none',
                padding: 0,
            },
            '.Mui-selected': {
                color: `${theme.palette.typography.primary} !important`,
            },
            '.MuiTabs-indicator': {
                display: 'none',
            },
        }),
    },
};
