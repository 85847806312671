import { Components, Theme } from '@mui/material';

export const MuiSelect: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
    defaultProps: {
        sx: {
            p: '0 0 1px 0',
        },
    },
    styleOverrides: {
        root: ({ theme }) => ({
            '.MuiChip:hover': {
                background: theme.palette.buttonPrimary.main,
            },
        }),
        select: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
            minHeight: 50,
            display: 'flex',
            alignItems: 'center',
        },
    },
};
