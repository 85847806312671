import { TProvider } from '@entities/providers/types';
import { Provider } from '@shared/api/axios-client';

const YANDEX_PROVIDER_ID = 'yandex';

export const mapProvider = (providers: Provider[]): TProvider[] => {
    return providers.map(provider => ({
        id: provider.id,
        title: provider.name,
        instantOrderAvailable: provider.instantOrderAvailable,
        isCredentialCorrect: provider.isCredentialCorrect,
        credentialFields:
            provider.connectAttribute?.map(attribute => ({
                code: attribute.attribute,
                label: attribute.label,
            })) || [],
        isEditable: provider.id !== YANDEX_PROVIDER_ID,
    }));
};
