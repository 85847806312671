import { Box, Skeleton, Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
    TProductTable,
    TProductTableHeaderField,
    TSortableField,
} from '@entities/products/types';
import { Conditional } from '@shared/ui/atoms/conditional';

import { ProductItem } from '../product-item';
import { ProductTableHeader } from '../product-table-header';

type TProps = {
    sortableField?: TSortableField;
    tableHeader: TProductTableHeaderField[];
    products: TProductTable[];
    page?: number;
    pageLimit?: number;
    priorProducts: TProductTable[];
    adds: ReactNode[];
    EmptyComponent?: ReactNode;
    renderControl: (item: TRenderItem) => ReactNode;
    onItemClick?: (productId: string) => void;
    onHeaderClick?: (attribute: TSortableField['sortBy']) => void;
};

const ADD_PER_PRODUCT = 4;

type TRenderItem = {
    position: number;
    providerId?: string;
    productId?: string;
    warehouseId?: string;
    brand?: string;
};

export const ProductTable = ({
    sortableField,
    tableHeader,
    products,
    page,
    pageLimit,
    priorProducts,
    adds,
    EmptyComponent,
    renderControl,
    onItemClick,
    onHeaderClick,
}: TProps) => {
    const [scrollPage, setScrollPage] = useState(page);
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const productsLimits = (pageLimit || 0) * (scrollPage || 0);
    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                setScrollPage(prev => prev && prev + 1);
            }, 2000);
        }
    }, [inView]);
    const Rows = products.map((product, index) => (
        <Conditional
            condition={productsLimits ? index + 1 < productsLimits : true}>
            <ProductItem
                key={index}
                fields={product.fields}
                Control={renderControl({
                    position: priorProducts.length + index + 1,
                    providerId: product.providerId,
                    productId: product.id,
                    warehouseId: product.warehouseId,
                    brand: product.brand,
                })}
                onClick={() => onItemClick?.(product.id)}
            />
        </Conditional>
    ));
    const PriorRows = priorProducts.map((product, index) => (
        <ProductItem
            key={index}
            isHighlighted
            fields={product.fields}
            Control={renderControl({
                position: index + 1,
                providerId: product.providerId,
                productId: product.id,
                warehouseId: product.warehouseId,
                brand: product.brand,
            })}
            onClick={() => onItemClick?.(product.id)}
        />
    ));
    const rowsWithAdd = Rows.reduce<ReactNode[]>((acc, item, index) => {
        if (
            (index + 1) % ADD_PER_PRODUCT !== 0 ||
            (productsLimits ? index + 1 > productsLimits : false)
        ) {
            return [...acc, item];
        }
        const addPageNumber = (index + 1) / ADD_PER_PRODUCT;
        const addPageIndex = addPageNumber - 1;
        const add = adds[addPageIndex];
        if (add) {
            return [...acc, item, add];
        }
        return [...acc, item];
    }, []);

    return (
        <Stack direction="column" width="100%">
            <ProductTableHeader
                fields={tableHeader}
                onClick={onHeaderClick}
                sortableField={sortableField}
            />
            <Conditional
                condition={products.length < 1 && priorProducts.length < 1}>
                {EmptyComponent}
            </Conditional>
            <Stack overflow="auto" maxHeight="80dvh">
                <Box
                    width="100%"
                    sx={theme => ({
                        '.MuiGrid-container:last-of-type': {
                            borderBottom: `2px solid ${theme.palette.border.border1}`,
                        },
                        '.MuiGrid-container:first-of-type': {
                            borderTop: `2px solid ${theme.palette.border.border1}`,
                        },
                        '.MuiGrid-container:not(:last-child)': {
                            borderBottom: `1px solid ${theme.palette.border.border1}`,
                        },
                    })}>
                    {PriorRows}
                </Box>
                {rowsWithAdd}
                <Conditional
                    condition={
                        Boolean(productsLimits) &&
                        products.length > productsLimits
                    }>
                    <Stack direction="column" ref={ref} gap="10px">
                        <Skeleton
                            variant="rounded"
                            width="100%"
                            height="64px"
                        />
                        <Skeleton
                            variant="rounded"
                            width="100%"
                            height="64px"
                        />
                    </Stack>
                </Conditional>
            </Stack>
        </Stack>
    );
};
